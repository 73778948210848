import React from "react";
import { Outlet } from "react-router-dom";
import "./Layouts.css";

export const BlankLayout = () => {
  return (
    <div className="blank-layout">
        <Outlet />
    </div>
  );
};
