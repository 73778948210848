import React from "react";
import "./BriefStatCard.css";
import { ICONS, Icon } from "../../Icons/Icon";

export const BriefStatCard = ({ value, label, changeValue }) => {
    return (
        <div className="stat-card">
            <p className="left-section">
                <span className="primary-value">{value} </span>
                <span className="primary-label">{label}</span>
            </p>
            <div className={`changing-section ${changeValue < 0 ? "negative" : "positive" }`}>
                <div className="change-value">{changeValue}%</div>
                {changeValue < 0 ? 
                    <Icon iconType={ICONS.DoubleCaretDown} width="22" height="22" /> :
                    <Icon iconType={ICONS.DoubleCaretUp} width="22" height="22" />
                }
            </div>
        </div>
    );
};