import { useContext } from "react";
import { OverlayContext } from "../contexts/OverlayContext";
import { AuthContext } from "../contexts/AuthContext";

export const useQueryErrorHandler = () => {
    const { DisplayAlertCallback } = useContext(OverlayContext);
    const { SignOut } = useContext(AuthContext);

    const queryErrorHandler = async (error) => {
        let response = {};
        if (error.response) {
            if (error.response.status === 401) {
                SignOut();
                return;
            }
            response = await error.response.json();
        }
        switch (response?.detail?.sigmund_code) {
            case "signup:4:01": {
                DisplayAlertCallback("Forbidden user role");
                break;
            }
            case "signup:4:02": {
                DisplayAlertCallback("The email is already taken");
                break;
            }
            case "signup:4:03": {
                DisplayAlertCallback("The username is already taken");
                break;
            }
            case "signup:3:04": {
                DisplayAlertCallback("The username contains prohibited characters");
                break;
            }
            case "claim_artist:3:00": {
                DisplayAlertCallback("Invalid artist URL");
                break;
            }
            case "claim_artist:1:01": {
                DisplayAlertCallback("Artist URL not found");
                break;
            }
            case "claim_artist:4:02": {
                DisplayAlertCallback("All artists have already been claimed");
                break;
            }
            case "claim_artist:4:03": {
                DisplayAlertCallback("The artist is already claimed");
                break;
            }
            case "genres_pick:4:01": {
                DisplayAlertCallback("The genres have already been picked");
                break;
            }
            case "genres_pick:4:00": {
                DisplayAlertCallback("A maximum of 3 genres can be picked");
                break;
            }
            case "curator_request:3:00": {
                DisplayAlertCallback("Invalid request template");
                break;
            }
            case "curator_request:4:01": {
                DisplayAlertCallback("Monthly limit of requests has been exhausted");
                break;
            }
            case "curator_request:4:02": {
                DisplayAlertCallback("Genre picking is incomplete");
                break;
            }
            case "curator_request:4:03": {
                DisplayAlertCallback("Curators contacts have been exhausted");
                break;
            }
            case "curator_request:4:04": {
                DisplayAlertCallback("The monthly limit of requests to curators has been exhausted");
                break;
            }
            case "curator_request:4:05": {
                DisplayAlertCallback("Insufficient tokens on the balance");
                break;
            }
            case "sigmund_service:0:00": {
                DisplayAlertCallback("SFScoop service unavailable. Retry later");
                break;
            }
            default:
                DisplayAlertCallback("The service is not available. Retry later");
        }
    };

    return queryErrorHandler;
};