import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { ICONS, Icon } from "../../components/Icons/Icon";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { useSpotifyConnectQuery } from "../../hooks/QueryHooks/useSpotifyConnectQuery";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import "./StreamingConnect.css";
import { SpotifyConfirmEmailMutation, SpotifyInitialConfirmEmailMutation } from "../../queries/sigmundApi";
import { AnimatePresence, motion } from "framer-motion";

export const StreamingConnect = ({ state }) => {
    const { standardBlockRef } = useContext(ResponsiveContext);
    const { connectMutation, SpotifyConnectCallback } = useSpotifyConnectQuery();
    const [initialConfirmationEmail, setInitialConfirmationEmail] = useState(null);
    const [confirmationEmail, setConfirmationEmail] = useState(null);
    const initialConfirmEmailMutation = SpotifyInitialConfirmEmailMutation();
    const confirmEmailMutation = SpotifyConfirmEmailMutation();
    const [invalidInput, setInvalidInput] = useState(false);

    useEffect(() => {
        if (state === 'pending') {
            initialConfirmEmailMutation.mutate({}, {
                onSuccess: async (data) => {
                    const response = await data.json();
                    setInitialConfirmationEmail(response.email);
                    setConfirmationEmail(response.email);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleConfirmation = (event) => {
        event.preventDefault();
        if (confirmationEmail) {
            confirmEmailMutation.mutate(confirmationEmail, {
                onSuccess: () => {
                    setInitialConfirmationEmail(null);
                },
                onError: () => {
                    setInvalidInput(true);
                }
            })
        } else {
            setInvalidInput(true);
        }
    };

    const handleConnect = () => {
        SpotifyConnectCallback();
    };

    return (
        <>
            {state === "connect" &&
                <div ref={standardBlockRef} className="streaming-connect">
                    <Icon iconType={ICONS.Plugs} height="80" width="80" />
                    <div className="warning">
                        <div className="title">You&#39;re not connected</div>
                        <div className="subtitle">Grant spotify permissions to Sigmund to continue</div>
                    </div>
                    <PrimaryButton
                        icon={ICONS.Plug}
                        onClick={handleConnect}
                        disabled={connectMutation.isPending || connectMutation.isSuccess}>
                        Connect
                    </PrimaryButton>
                </div>
            }
            {state === "pending" &&
                <div ref={standardBlockRef} className="streaming-connect">
                    <Icon iconType={ICONS.ApproveCircleFilled} height="80" width="80" />
                    <div className="warning">
                        <div className="title">Verification</div>
                        <div className="subtitle">Sigmund is verifying your account</div>
                        <div className="note">Usually the verification takes no more than one business day<br />We will notify you when the verification is complete</div>
                    </div>
                    <AnimatePresence>
                        {initialConfirmationEmail && 
                            <motion.div
                                key="email-confirmation"
                                initial={{ height: 0 }}
                                animate={{ height: "auto" }}
                                exit={{ height: 0 }}
                                transition={{ duration: 0.45 }}
                                className="spotify-email-confirmation-box"
                                style={{ overflow: "hidden" }}
                            >
                                <form className="spotify-email-confirmation-box" onSubmit={handleConfirmation}>
                                    <div className="spotify-email-box">
                                        <label htmlFor="email" className="input-label">Your Spotify sign-in email</label>
                                        <input
                                            id="email"
                                            type="email"
                                            value={confirmationEmail}
                                            onChange={(e) => setConfirmationEmail(e.target.value)}
                                            className={`text-box-wrapper ${invalidInput ? "invalid" : ""}`}
                                            placeholder="Spotify email"
                                            autoComplete="off" />
                                    </div>
                                    <PrimaryButton 
                                        type="submit" 
                                        disabled={confirmEmailMutation.isPending}
                                    >
                                        Confirm Email
                                    </PrimaryButton>
                                </form>
                            </motion.div>
                        }
                    </AnimatePresence>
                </div>
            }
            {state === "failure" &&
                <div ref={standardBlockRef} className="streaming-connect">
                    <Icon iconType={ICONS.Prohibit} height="80" width="80" />
                    <div className="warning">
                        <div className="title">Failure</div>
                        <div className="subtitle">Something went wrong during the granting process<br />Try connecting again</div>
                    </div>
                    <PrimaryButton icon={ICONS.Plug}
                        onClick={handleConnect}
                        disabled={connectMutation.isPending || connectMutation.isSuccess}>
                        Reconnect
                    </PrimaryButton>
                </div>
            }
            {state === "forbidden" &&
                <div ref={standardBlockRef} className="streaming-connect">
                    <Icon iconType={ICONS.Prohibit} height="80" width="80" />
                    <div className="warning">
                        <div className="title">Forbidden</div>
                        <div className="subtitle">Looks like your spotify profile is already connected to<br />another SFScoop account</div>
                        <div className="note">Sign in to another Spotify profile and reconnect</div>
                    </div>
                    <PrimaryButton icon={ICONS.Plug}
                        onClick={handleConnect}
                        disabled={connectMutation.isPending || connectMutation.isSuccess}>
                        Reconnect
                    </PrimaryButton>
                </div>
            }
        </>
    );
};

StreamingConnect.propTypes = {
    state: PropTypes.oneOf(["connect", "pending", "failure", "forbidden"]),
};