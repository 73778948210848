import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { SigmundContext } from "../contexts/SigmundContext";

export const useManageTracks = () => {
    const { CurrentProfileManageTrackSubmition } = useContext(SigmundContext);
    const queryClient = useQueryClient();  

    const submitTrack = (artistId, trackInfo) => {
        CurrentProfileManageTrackSubmition("submit", trackInfo.duration);
        queryClient.setQueryData(["artistTracks", "idle", artistId], (data) => ({
            pages: data.pages.map((page) => ({
                ...page, 
                tracks: page.tracks.filter((val) => val.track_id !== trackInfo.track_id)
            })),
            pageParams: data.pageParams,
        }));
    
        queryClient.setQueryData(["artistTracks", "in_promotion", artistId], (data) => {
            const trackInfoChanged = {
                ...trackInfo,
                submitted: true,
                scheduled: "scheduled",
            }
            return {
                pages: [{...data.pages[0], tracks: [trackInfoChanged, ...data.pages[0].tracks]}, ...data.pages.slice(1)],
                pageParams: data.pageParams,
            };
        });
    };

    const updateSubmitStatus = (artistId, trackInfo, type, submitValue) => {
        queryClient.setQueryData(["artistTracks", type, artistId], (data) => ({
            pages: data.pages.map((page) => ({
                ...page, 
                tracks: page.tracks.map((val) => val.track_id === trackInfo.track_id ? {
                    ...val,
                    submitted: submitValue
                } : val)
            })),
            pageParams: data.pageParams,
        }));
    };

    const revokeTrack = (artistId, trackInfo) => {
        CurrentProfileManageTrackSubmition("revokeScheduled", trackInfo.duration);
        queryClient.setQueryData(["artistTracks", "in_promotion", artistId], (data) => ({
            pages: data.pages.map((page) => ({
                ...page, 
                tracks: page.tracks.filter((val) => val.track_id !== trackInfo.track_id)
            })),
            pageParams: data.pageParams,
        }));
    
        queryClient.setQueryData(["artistTracks", "idle", artistId], (data) => {
            const trackInfoChanged = {
                ...trackInfo,
                submitted: false,
                scheduled: null,
            }
            return {
                pages: [{...data.pages[0], tracks: [trackInfoChanged, ...data.pages[0].tracks]}, ...data.pages.slice(1)],
                pageParams: data.pageParams,
            };
        });
    };
    
    return { submitTrack, revokeTrack, updateSubmitStatus }
};