import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { SigmundMeMutation } from "../queries/sigmundApi";
import { useNavigate } from "react-router-dom";
import { absolutePaths } from "../routes";
import { useQueryErrorHandler } from "../hooks/useQueryErrorHandler";
import DefaultUserImg from "../assets/Images/Anonymus.png";

export const SigmundContext = createContext(null);

export const SigmundProvider = ({ children }) => {
    const [profileInfo, setProfileInfo] = useState({});
    const [currentProfile, setCurrentProfile] = useState({});
    const [sigmundStatus, setSigmundStatus] = useState(null);
    const { isAuth } = useContext(AuthContext);
    const errorHandler = useQueryErrorHandler();
    const navigate = useNavigate();
    const sigmundMeMutation = SigmundMeMutation();

    useEffect(() => {
        if (!isAuth) {
            setProfileInfo({});
            setCurrentProfile({});
            setSigmundStatus(null);
        }
    }, [isAuth])

    const SwitchCurrentProfile = (profileId, newProfileInfo = null) => {
        let profiles = profileInfo;
        if (newProfileInfo) {
            profiles = newProfileInfo;
        }

        let lastProfileId = profileId; 
        const profileIdx = profiles.claimed_artists.findIndex(item => item["artist_id"] === profileId);
        
        if (profileIdx !== -1) {
            setCurrentProfile(profiles.claimed_artists[profileIdx]);
        } else {
            const profile = profiles.claimed_artists[0];
            lastProfileId = profile.artist_id;
            setCurrentProfile(profile);
            if (profiles.user_role === "admin") {
                navigate(absolutePaths.adminConsole, { replace: true });    
            } else {
                navigate(absolutePaths.dashboard, { replace: true });
            }
        }

        localStorage.setItem("lastProfileId", lastProfileId);
    };

    const SigmundStatusCallback = () => {
        if (isAuth) {
            sigmundMeMutation.mutate({}, {
                onSuccess: async (response) => {
                    let data = await response.json();
                    if (Array.isArray(data.claimed_artists) && data.claimed_artists.length) {
                        const artists = data.claimed_artists.map((artist) => {
                            if (!artist.artist_image) {
                                return {
                                    ...artist,
                                    artist_image: DefaultUserImg,
                                };
                            };
                            return artist;
                        });
                        data = {
                            ...data,
                            claimed_artists: artists,
                        };
                    } else {
                        data.claimed_artists.push({
                            artist_id: data.user_token,
                            artist_image: DefaultUserImg
                        });
                    }
                    setProfileInfo(data);
                    const lastProfileId = localStorage.getItem("lastProfileId");
                    SwitchCurrentProfile(lastProfileId, data);
                    
                    setSigmundStatus(data.sigmund_status);
                    if (data.sigmund_status === "initial_artist_claim") {
                        navigate(absolutePaths.claimArtist, { replace: true });
                    } else if (data.sigmund_status === "email_confirmation") {
                        navigate(absolutePaths.confirmEmail, { replace: true });
                    }
                },
                onError: (error) => {
                    errorHandler(error);
                }
            });
        }
    };

    const CurrentProfileManageTrackSubmition = (action, duration) => {
        const artist_id = currentProfile.artist_id;
        let on_air_window = profileInfo["artist_promotions"][artist_id]["on_air_window"];
        let scheduled_window = profileInfo["artist_promotions"][artist_id]["scheduled_window"];
        let revoked_window = profileInfo["artist_promotions"][artist_id]["revoked_window"];
        let free_window = profileInfo["artist_promotions"][artist_id]["free_window"];

        if (action === "submit") {
            scheduled_window = scheduled_window + duration;
            free_window = free_window - duration
        } else if (action === "revokeScheduled") {
            scheduled_window = scheduled_window - duration;
            free_window = free_window + duration;
        } else if (action === "revokePromoted") {
            on_air_window = on_air_window - duration;
            revoked_window = revoked_window + duration;
            free_window = free_window + duration;
        } else if (action === "reSubmit") {
            on_air_window = on_air_window + duration;
            revoked_window = revoked_window - duration;
            free_window = free_window - duration;
        }
        setProfileInfo({
            ...profileInfo,
            "artist_promotions": {
                ...profileInfo.artist_promotions,
                [artist_id]: {
                    ...profileInfo.artist_promotions[artist_id],
                    on_air_window,
                    scheduled_window,
                    revoked_window,
                    free_window,
                }
            }
        });
    };

    return (
        <SigmundContext.Provider
            value={{
                sigmundStatus,
                SigmundStatusCallback,
                profileInfo,
                currentProfile,
                SwitchCurrentProfile,
                CurrentProfileManageTrackSubmition,
            }}>
            {children}
        </SigmundContext.Provider>
    );
};