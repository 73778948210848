import React from "react";
import { ReactComponent as AnnonceCover} from "../../../assets/Images/Default Announce Cover.svg"
import { OptionLine } from "../../Icons/OptionLine";
import "./AnnouncementCard.css";

export const AnnouncementCard = ({ cardInfo, showCover=true }) => {
    return (
        <div className="announcement-card">
            <div className="left-section">
                <OptionLine />
                {showCover && (cardInfo.image ? (
                    <img src={cardInfo.image}
                        className="track-image"
                        alt="Announcement" />
                ) : (
                    <AnnonceCover width="72" height="72" />
                ))}
                <div className="main-info">
                    <div className="right-section">
                        <div className="brief">{cardInfo.brief}</div>
                    </div>
                    <div className="frame">
                        <div className="title-text">{cardInfo.title}</div>
                        <div className="description-text">{cardInfo.sub_title}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
