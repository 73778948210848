import PropTypes from "prop-types";
import React from "react";
import "./PopUpMenuButton.css";
import { Icon, ICONS } from "../../Icons/Icon";

export const PopUpMenuButton = ({ onClick, width, height }) => {
    return (
        <button onClick={onClick} style={{ width: width + "px", height: height + "px" }} className="popup-menu-button">
            <Icon iconType={ICONS.DotsThreeOutline} width={width} height={height} />
        </button>
    );
};

PopUpMenuButton.propTypes = {
    onClick: PropTypes.func,
    width: PropTypes.string,
    height: PropTypes.string,
};

PopUpMenuButton.defaultProps = {
    onClick: () => {},
    width: "32",
    height: "32",
}

