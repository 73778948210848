import { useContext } from "react";
import { SigmundContext } from "../../contexts/SigmundContext";
import { UserVerificationBox } from "../../blocks/UserVerificationBox/UserVerificationBox";
import "./AdminConsole.css";

export const AdminConsole = () => {
    const { profileInfo } = useContext(SigmundContext);

    return (
        <>
            {profileInfo.user_role === "admin" && 
                <div className="console-section">
                    <UserVerificationBox/>
                </div>
            }
        </>
    );
};