import { useEffect, useState } from "react";
import { AnnouncementsMutation } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";

export const useAnnouncements = () => {
    const queryErrorHandler = useQueryErrorHandler();
    const [announcements, setAnnouncements] = useState([]);
    const queryMutation = AnnouncementsMutation();
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        setFetched(false);
        queryMutation.mutate({}, {
            onSuccess: async (response) => {
                const data = await response.json();
                setAnnouncements(data);
                setFetched(true);
            },
            onError: (error) => {
                queryErrorHandler(error);
                setFetched(true);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { announcements, fetched };
};