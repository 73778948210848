import PropTypes from "prop-types";
import React, { useContext } from "react";
import { SideMenuButton, VARIANTS } from "../../components/Buttons/SideMenuButton/SideMenuButton";
import { absolutePaths } from "../../routes";
import { SigmundContext } from "../../contexts/SigmundContext";
import { SideMenuCompositeButton } from "../../components/Buttons/SideMenuCompositeButton/SideMenuCompositeButton";
import "./SettingsMenu.css";

export const SettingsMenu = ({ onSelect }) => {
    const { profileInfo } = useContext(SigmundContext);

    return (
        <div className="settings-menu">
            {(profileInfo.user_role === "manager") &&
                <>
                    <SideMenuCompositeButton variant={VARIANTS.myArtists}>
                        <SideMenuButton disabled={!profileInfo.artist_claim_available} to={absolutePaths.claimArtist} variant={VARIANTS.claimArtist} onClick={onSelect} style={{ height: 32 }} />
                    </SideMenuCompositeButton>
                </>
            }
            <SideMenuCompositeButton variant={VARIANTS.profile}>
                <SideMenuButton to={absolutePaths.updateUsername} variant={VARIANTS.updateUsername} onClick={onSelect} style={{ height: 32 }} />
            </SideMenuCompositeButton>
        </div>
    );
};

SettingsMenu.propTypes = {
    onSelect: PropTypes.func,
};

SettingsMenu.defaultProps = {
    onSelect: () => { },
};