import React from "react";
import PropTypes from "prop-types";
import useMeasure from "react-use-measure";
import { motion } from "framer-motion";
import "./ProgressBar.css";

export const ProgressBar = ({ progress }) => {
    const [ref, bounds] = useMeasure();

    return (
        <div ref={ref} className="progress-bar">
            <motion.div
                animate={{ width: (bounds.width * progress.toFixed(2)) }}
                transition={{ duration: 0.6}}
                className="progress"
            />
        </div>
    );
};

ProgressBar.defaultProps = {
    progress: 0,
};

ProgressBar.propTypes = {
    progress: PropTypes.number,
};