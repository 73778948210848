import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SigmundContext } from "../../contexts/SigmundContext";
import { useContext } from "react";

export const useMessengerQuery = () => {
    const queryClient = useQueryClient();
    const { currentProfile } = useContext(SigmundContext);

    const chatsQuery = useQuery({
        queryKey: ["chats", currentProfile.artist_id],
        queryFn: () => {
            return queryClient.getQueryData(["chats", currentProfile.artist_id]);
        },
        initialData: () => {
            const oldChats = queryClient.getQueryData(["chats", currentProfile.artist_id]);
            if (!oldChats) {
                return null;
            }
            return oldChats;
        },
        enabled: false,
    });

    const messagesQuery = useQuery({
        queryKey: ["chatMessages"],
        queryFn: () => {
            return queryClient.getQueryData(['chatMessages']);
        },
        initialData: () => {
            const oldMessages = queryClient.getQueryData(['chatMessages']);
            if (!oldMessages) {
                return [];
            }
            return oldMessages;
        },
        enabled: false,
    });

    return { chatsQuery, messagesQuery };
};