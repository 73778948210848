import PropTypes from "prop-types";
import React from "react";
import { Icon, ICONS } from "../../Icons/Icon";
import "./CircleArrowButton.css";

export const CircleArrowButton = ({ onClick, disabled, active, type }) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className="circle-arrow-button">
            {type === "right" && 
                <Icon iconType={active ? ICONS.ArrowCircleRightFilled : ICONS.ArrowCircleRight} width="32" height="32" />
            }
            {type === "left" && 
                <Icon iconType={active ? ICONS.ArrowCircleLeftFilled : ICONS.ArrowCircleLeft} width="32" height="32" />
            }
        </button>
    );
};

CircleArrowButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    type: PropTypes.oneOf(["right", "left"]),
};

CircleArrowButton.defaultProps = {
    onClick: () => { },
    disabled: false,
    active: false,
    type: "right",
};