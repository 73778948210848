import React, { useContext, useState } from "react";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { absolutePaths } from "../../routes";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { AnimatePresence, motion } from "framer-motion";
import { ResetPasswordMutation } from "../../queries/sigmundApi";
import { Alert } from "../Alert/Alert";
import "./PasswordResetBox.css";
import { ICONS } from "../../components/Icons/Icon";


const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;

export const PasswordResetBox = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validationAlert, setValidationAlert] = useState(null);
    const navigate = useNavigate();
    const [status, setStatus] = useState("idle");
    const [invalidInput, setInvalidInput] = useState({});
    const recoveryMutation = ResetPasswordMutation();
    const [searchParams,] = useSearchParams();
    const recoveryToken = searchParams.get("resetToken");
    const { isExtremeSmallMobile } = useContext(ResponsiveContext);

    const validatePassword = (passwordValue) => {
        if (!passwordRegex.test(passwordValue)) {
            setInvalidInput({ ...invalidInput, password: true });
            setValidationAlert("Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one digit, and one special character");
        } else if (invalidInput.password) {
            setInvalidInput({ ...invalidInput, password: false });
            setValidationAlert("");
        }
    };

    const validateMatching = () => {
        if (password !== confirmPassword) {
            setInvalidInput({ ...invalidInput, confirmPassword: true });
            setValidationAlert("The passwords don't match");
        } else if (invalidInput.confirmPassword) {
            setInvalidInput({ ...invalidInput, confirmPassword: false });
            setValidationAlert("");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        validatePassword(password);
        validateMatching();
        if (!password) {
            setInvalidInput({ ...invalidInput, password: true });
        } else if (!confirmPassword) {
            setInvalidInput({ ...invalidInput, confirmPassword: true });
        } else if (Object.values(invalidInput).every(item => item === false) && !validationAlert && !recoveryMutation.isPending) {
            recoveryMutation.mutate({ recoveryToken, password }, {
                onSuccess: () => {
                    setStatus("success");
                },
                onError: () => {
                    setStatus("failure");
                },
            });
        }
    }

    return (
        <>
            {status === "success" &&
                <Alert
                    title="Password Successfully Reset"
                    subtitle="Sign in to your account with a new password"
                    buttonText="Sign In"
                    onClick={() => navigate(absolutePaths.singIn)}
                    iconType={ICONS.ApproveCircle}
                    backgroundColor={false}
                />
            }
            {status === "failure" &&
                <Alert
                    title="Password Reset Failed"
                    subtitle="The reset URL is outdated or corrupted. Try to reset the password again"
                    buttonText="Reset Again"
                    onClick={() => navigate(absolutePaths.forgotPassword)}
                    iconType={ICONS.Prohibit}
                    backgroundColor={false}
                />
            }
            {status === "idle" &&
                <>
                    <div className="reset-password-frame">
                        <div className="reset-password-section">
                            <header className="header">
                                <div className="title">Password Reset</div>
                            </header>
                            <form className="password-reset-box" onSubmit={handleSubmit}>
                                <div className="form">
                                    <div className="div">
                                        <label htmlFor="password" className="input-label">Password</label>
                                        <input
                                            id="password"
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onBlur={() => validatePassword(password)}
                                            className={`text-box-wrapper ${invalidInput.password ? "invalid" : ""}`}
                                            placeholder="Enter your password"
                                            autoComplete="off" />
                                    </div>
                                    <div className="div">
                                        <label htmlFor="confirm-password" className="input-label">Confirm password</label>
                                        <input
                                            id="confirm-password"
                                            type="password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            onBlur={() => validateMatching(confirmPassword)}
                                            className={`text-box-wrapper ${invalidInput.confirmPassword ? "invalid" : ""}`}
                                            placeholder="Enter the password again"
                                            autoComplete="off" />
                                    </div>
                                </div>
                                <PrimaryButton type="submit" disabled={recoveryMutation.isPending ? true : false}>Submit</PrimaryButton>
                                <div className="alternative">
                                    <AnimatePresence>
                                        {validationAlert &&
                                            <motion.div
                                                style={{ overflow: "hidden" }}
                                                initial={{ height: 0, opacity: 0 }}
                                                animate={{ height: "auto", opacity: 1 }}
                                                transition={{ duration: 0.3 }}
                                                exit={{ height: 0, opacity: 0 }}
                                                className="alert"
                                            >
                                                {validationAlert}
                                            </motion.div>
                                        }
                                    </AnimatePresence>
                                    <div className="default">
                                        Remember the password?{isExtremeSmallMobile ? <br /> : " "}<Link to={absolutePaths.singIn} className="link">Sign in here</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            }
        </>
    );
};