import PropTypes from "prop-types";
import React from "react";
import "./CloseButton.css";
import { Icon, ICONS } from "../../Icons/Icon";

export const CloseButton = ({ onClick, width, height }) => {
    return (
        <button onClick={onClick} style={{ width: width + "px", height: height + "px" }} className="close-button">
            <Icon iconType={ICONS.Close} width={width} height={height} />
        </button>
    );
};

CloseButton.propTypes = {
    onClick: PropTypes.func,
    width: PropTypes.string,
    height: PropTypes.string,
};

CloseButton.defaultProps = {
    onClick: () => {},
    width: "32",
    height: "32",
}

