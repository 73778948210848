import React, { useContext } from "react";
import "./MessengerTopSection.css";
import { ClipboardButton } from "../../components/Buttons/ClipboardButton/ClipboardButton";
import DefaultUserImg from "../../assets/Images/Anonymus.png";
import { BackButton } from "../../components/Buttons/BackButton/BackButton";
import { useNavigate } from "react-router-dom";
import { absolutePaths } from "../../routes";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { ListButton } from "../../components/Buttons/ListButton/ListButton";

export const MessengerTopSection = ({ chatName, chatType, chatImage, actions = false }) => {
    const navigate = useNavigate();
    const { isDesktop } = useContext(ResponsiveContext);

    const handleBack = () => {
        navigate(absolutePaths.messenger, { replace: true });
    };

    return (
        <div className="messenger-top-section">
            <div className="user-info">
                {!isDesktop &&
                    <BackButton onClick={handleBack} />
                }
                <img
                    src={chatImage ? chatImage : DefaultUserImg}
                    className="chat-icon"
                    draggable="false"
                    alt="Chat Icon"
                />
                <div className="text-wrapper">{chatName}</div>
                {(chatType && isDesktop) &&
                    <div className="role">
                        <div className="div">{chatType}</div>
                    </div>
                }
            </div>
            <div className="actions">
                <ListButton />
                {actions &&
                    <ClipboardButton />
                }
            </div>
        </div>
    );
};