import React from "react";
import { useContext } from "react";
import { SigmundContext } from "../../contexts/SigmundContext";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { ArtistTracksInfiniteQuery } from "../../queries/sigmundApi";
import { ICONS, Icon } from "../../components/Icons/Icon";
import { ArtistPromotionTrackCard } from "../../components/Cards/ArtistPromotionTrackCard/ArtistPromotionTrackCard";
import { AnimatePresence, motion } from "framer-motion";
import "./ArtistPromotionTracksBox.css";

export const ArtistPromotionTracksBox = () => {
    const { currentProfile, profileInfo } = useContext(SigmundContext);
    const promotionInfo = profileInfo.artist_promotions[currentProfile.artist_id];
    const infiniteTrackQuery = ArtistTracksInfiniteQuery("in_promotion", currentProfile.artist_id);

    return (
        <div className="artist-promotion-tracks-box">
            <div className="box-top-section">
                <div className="title">In Promotion</div>
            </div>
            <div className="cards-section">
                <hr className="separator-line" />
                <div className="section">
                    <div className="track-box">
                        {infiniteTrackQuery.status === 'pending' ? (
                            <div className="empty">
                                <LoaderBox backgroundColor={false} />
                            </div>
                        ) : ((infiniteTrackQuery?.data?.pages[0].tracks.length > 0) ? (
                            infiniteTrackQuery.data.pages.map((group, i) => (
                                <React.Fragment key={i}>
                                    <AnimatePresence>
                                        {group.tracks.map((item) =>
                                            <ArtistPromotionTrackCard
                                                key={item.track_id}
                                                trackInfo={item}
                                                artistId={currentProfile.artist_id}
                                                prohibit_submit={item.duration > promotionInfo.free_window}
                                            />
                                        )}
                                    </AnimatePresence>
                                </React.Fragment>
                            ))
                        ) : (
                            <motion.div
                                initial={{ opacity: 0 }} 
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                className="empty"
                            >
                                <Icon iconType={ICONS.Mute} width="80" height="80" />
                                <div className="subtitle">No tracks in promotion</div>
                            </motion.div>
                        ))}

                        <div className="end-card">
                            {!infiniteTrackQuery.isFetchingNextPage &&
                                !infiniteTrackQuery.isFetching &&
                                infiniteTrackQuery.hasNextPage &&
                                <PrimaryButton onClick={infiniteTrackQuery.fetchNextPage}>Load More</PrimaryButton>
                            }

                            {infiniteTrackQuery.isFetchingNextPage &&
                                <LoaderBox backgroundColor={false} box={false} width="32" height="32" />
                            }
                        </div>
                    </div>
                </div>
                <hr className="separator-line" />
            </div>
        </div>
    );
};