import { useContext } from "react";
import { BriefStats } from "../../blocks/BriefStats/BriefStats";
import { BroadcastingBox } from "../../blocks/BroadcastingBox/BroadcastingBox";
import { CuratorRequestBox } from "../../blocks/CuratorRequestBox/CuratorRequestBox";
import { OnAirPlalists } from "../../blocks/OnAirPlaylists/OnAirPlaylists";
import { SigmundRateBox } from "../../blocks/SigmundRateBox/SigmundRateBox";
import "./Dashboard.css";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { SigmundContext } from "../../contexts/SigmundContext";
import { AnnouncementBox } from "../../blocks/AnnouncementBox/AnnouncementBox";

export const Dashboard = () => {
    const { profileInfo } = useContext(SigmundContext);
    const { isDesktop, standardBlockRef } = useContext(ResponsiveContext);

    return (
        <>
            {isDesktop ? (
                <>
                    <div className="dashboard-section">
                        <OnAirPlalists />
                        {(profileInfo.user_role === "artist" || profileInfo.user_role === "manager") && 
                            <SigmundRateBox />
                        }
                        {profileInfo.user_role === 'fan' && 
                            <BroadcastingBox />
                        }
                    </div>
                    <div ref={standardBlockRef} className="dashboard-section">
                        {(profileInfo.user_role === "artist" || profileInfo.user_role === "manager") &&
                            <>
                                <BriefStats />
                                <BroadcastingBox />
                                <CuratorRequestBox />
                            </>
                        }
                        {profileInfo.user_role === 'fan' && 
                            <AnnouncementBox />
                        }
                    </div>
                </>
            ) : (
                <>
                    <div ref={standardBlockRef} className="dashboard-section">
                        {(profileInfo.user_role === "artist" || profileInfo.user_role === "manager") &&
                            <>
                                <BriefStats />
                                <CuratorRequestBox />
                                <OnAirPlalists />
                                <BroadcastingBox />
                                <SigmundRateBox />
                            </>
                        }

                        {profileInfo.user_role === 'fan' &&
                            <>
                                <OnAirPlalists />
                                <BroadcastingBox />
                                <AnnouncementBox />
                            </>
                        }
                    </div>
                </>
            )}
        </>
    );
};