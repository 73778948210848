import { UpdateUsernameMutation } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";

export const useUpdateUsername = () => {
    const queryErrorHandler = useQueryErrorHandler();
    const queryMutation = UpdateUsernameMutation();

    const updateUsername = (username, onComplete) => {
        queryMutation.mutate(username , {
            onSuccess: () => {
                onComplete();
            },
            onError: (error) => {
                queryErrorHandler(error);
            }
        })
    };

    return { updateUsername, queryMutation };
};