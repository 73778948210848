import { useEffect, useState } from "react";

import { ApprovePendingUserMutation, PendingUsersQuery } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";

export const usePendingUsers = () => {
    const [users, setUsers] = useState(null);
    const queryErrorHandler = useQueryErrorHandler();
    const pendingUsersQuery = PendingUsersQuery();
    const approvePendingUserMutation = ApprovePendingUserMutation();

    useEffect(() => {   
        if (pendingUsersQuery.isFetching) {
            setUsers(null);
        } else if (pendingUsersQuery.isSuccess) {
            setUsers(pendingUsersQuery.data);
        } else if (pendingUsersQuery.isError) {
            queryErrorHandler(pendingUsersQuery.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendingUsersQuery.isSuccess, pendingUsersQuery.isError]);

    const ApproveUser = (userToken) => {
        approvePendingUserMutation.mutate(userToken, {
            onSuccess: () => {
                pendingUsersQuery.refetch();
            },
            onError: (error) => {
                queryErrorHandler(error);
            }
        })
    }; 

    return { users, ApproveUser, approvePendingUserMutation };
};