import React, { useContext, useState } from "react";
import { SigmundContext } from "../../contexts/SigmundContext";
import { SignOutButton } from "../../components/Buttons/SignOutButton/SignOutButton";
import { ArtistSelectionOverlay } from "../../components/Overlays/ArtistSelectionOverlay/ArtistSelectionOverlay";
import { useOutsideClickDetector } from "../../hooks/useOutsideClickDetector";
import { AnimatePresence, motion } from "framer-motion"
import { NotificationsOverlay } from "../../components/Overlays/NotificationsOverlay/NotificationsOverlay";
import { NotificationsButton } from "../../components/Buttons/NotificationsButton/NotificationsButton";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { ListButton } from "../../components/Buttons/ListButton/ListButton";
import { SideMenu } from "../SideMenu/SideMenu";
import { useSignOut } from "../../hooks/QueryHooks/useSignOut";
import "./SideSecondaryMenu.css";
import { SettingsMenu } from "../SettingsMenu/SettingsMenu";
import { SettingsButton } from "../../components/Buttons/SettingsButton/SettingsButton";

const verticalMotion = {
    hidden: { top: "-100vh" },
    visible: { top: 0 },
    exit: { top: "-100vh" },
};

const fade = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0},
}; 

export const SideSecondaryMenu = () => {
    const signOut = useSignOut();

    const { isMobile, isSmallMobile } = useContext(ResponsiveContext);
    const { profileInfo, currentProfile } = useContext(SigmundContext);
    const [openState, setOpenState] = useState({
        artistSelection: false,
        notifications: false,
        menu: false,
        settings: false,
    });

    const toggleOverlay = (overlay) => {
        setOpenState({ ...openState, [overlay]: !openState[overlay] })
    };

    const closeOverlay = (overlay) => {
        setOpenState({ ...openState, [overlay]: false });
    };

    const artistSelectionClickDetector = useOutsideClickDetector(() => closeOverlay("artistSelection"));
    const notificationsClickDetector = useOutsideClickDetector(() => closeOverlay("notifications"));
    const menuClickDetector = useOutsideClickDetector(() => closeOverlay("menu"));
    const settingsClickDetector = useOutsideClickDetector(() => closeOverlay("settings"));

    return (
        <div className={`side-secondary-menu ${isSmallMobile ? "mobile" : "desktop"}`}>
            <AnimatePresence>
                {openState.artistSelection &&
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.45 }}
                        variants={isSmallMobile ? fade : verticalMotion}
                        className="selection-overlay">
                        <div ref={artistSelectionClickDetector}>
                            <ArtistSelectionOverlay type="desktop" onSelect={() => closeOverlay("artistSelection")} />
                        </div>
                    </motion.div>
                }
                {openState.notifications &&
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.45 }}
                        variants={isSmallMobile ? fade : verticalMotion}
                        className="selection-overlay">
                        <div ref={notificationsClickDetector}>
                            <NotificationsOverlay onClose={() => closeOverlay("notifications")} />
                        </div>
                    </motion.div>
                }
                {openState.menu &&
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.45 }}
                        variants={isSmallMobile ? fade : verticalMotion}
                        className="selection-overlay">
                        <div ref={menuClickDetector}>
                            <SideMenu onSelect={() => closeOverlay("menu")}/>
                        </div>
                    </motion.div>
                }
                {openState.settings &&
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.45 }}
                        variants={isSmallMobile ? fade : verticalMotion}
                        className="selection-overlay">
                        <div ref={settingsClickDetector}>
                            <SettingsMenu onSelect={() => closeOverlay("settings")}/>
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
            <img
                src={currentProfile.artist_image}
                draggable="false"
                alt="Artist Icon"
                className={`profile-icon ${openState.artistSelection ? "disable" : ""}`}
                onClick={() => toggleOverlay("artistSelection")}
            />
            {isSmallMobile ? (
                <div className="center-section">
                    <NotificationsButton onClick={() => toggleOverlay("notifications")} disabled={openState.notifications} />
                    <SettingsButton onClick={() => toggleOverlay("settings")} disabled={openState.settings} />
                </div>
            ) : (
                <>
                    <NotificationsButton onClick={() => toggleOverlay("notifications")} disabled={openState.notifications} />
                    <SettingsButton onClick={() => toggleOverlay("settings")} disabled={openState.settings} />
                </>
            )}
            {(isMobile || isSmallMobile) ?
                <ListButton onClick={() => toggleOverlay("menu")} disabled={openState.menu} /> :
                <SignOutButton onClick={signOut} />
            }
        </div>
    );
};