import React, { useContext, useState } from "react";
import "./SignInBox.css";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { absolutePaths } from "../../routes";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { AnimatePresence, motion } from "framer-motion";

export const SignInBox = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [invalidInput, setInvalidInput] = useState({});
    const { status, SignInCallback } = useContext(AuthContext);
    const { isExtremeSmallMobile } = useContext(ResponsiveContext);

    const handleEmail = (item) => {
        setInvalidInput({ ...invalidInput, email: false });
        setEmail(item.target.value);
    };

    const handlePassword = (item) => {
        setInvalidInput({ ...invalidInput, password: false });
        setPassword(item.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!email) {
            setInvalidInput({ ...invalidInput, email: true });
        } else if (!password) {
            setInvalidInput({ ...invalidInput, password: true });
        } else {
            SignInCallback(email, password);
        }
    }

    return (
        <>
            <form className="sign-in-box" onSubmit={handleSubmit}>
                <div className="form">
                    <div className="div">
                        <label htmlFor="email" className="input-label">Email</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={handleEmail}
                            className={`text-box-wrapper ${invalidInput.email ? "invalid" : ""}`}
                            placeholder="Enter your email address" />
                    </div>
                    <div className="div">
                        <label htmlFor="password" className="input-label">Password</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={handlePassword}
                            className={`text-box-wrapper ${invalidInput.password ? "invalid" : ""}`}
                            placeholder="Enter your password" />
                    </div>
                </div>
                <PrimaryButton type="submit" disabled={status === "fetching" ? true : false}>Sign In</PrimaryButton>
                <div className="alternative">
                    <AnimatePresence>
                        {status === "failure" &&
                            <motion.div
                                style={{ overflow: "hidden" }}
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: "auto", opacity: 1 }}
                                transition={{ duration: 0.3 }}
                                exit={{ height: 0, opacity: 0 }}
                                className="alert"
                            >
                                Oops. Invalid e-mail or password
                            </motion.div>
                        }
                    </AnimatePresence>
                    <div className="default">
                        Don&#39;t have an account?{isExtremeSmallMobile ? <br /> : " "}<Link to={absolutePaths.singUp} className="link">Sign up here</Link>
                    </div>
                    <div className="default">
                        <Link to={absolutePaths.forgotPassword} className="link">Forgot your password?</Link>
                    </div>
                </div>
            </form>
        </>
    );
};