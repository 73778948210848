import React, { useState } from "react";
import "./ArtistTrackStatCard.css";
import { changeFormatter } from "../../../tools";
import { ICONS, Icon } from "../../Icons/Icon";

export const ArtistTrackStatCard = ({ trackInfo, type = "streams", disableHover = false, hideStatus = false, hideChange = false }) => {
    const [hover, setHover] = useState(false);
    const statValue = type === "streams" ? trackInfo.streams.toLocaleString() : trackInfo.hours.toFixed(2).toLocaleString()
    const statsChange = type === "streams" ? changeFormatter(trackInfo.streams_change) : changeFormatter(trackInfo.hours_change)

    let iconType = null;
    if (trackInfo.track_status === "promoted") {
        iconType = ICONS.AirplaneTakeoff;
    } else if (trackInfo.track_status === "scheduled") {
        iconType = ICONS.CalendarCheck;
    } else if (trackInfo.track_status === "revoked") {
        iconType = ICONS.Armchair;
    }

    return (
        <div
            className={`artist-track-stat-card ${hover ? "hover" : ""}`}
            onMouseEnter={() => disableHover ? () => { } : setHover(true)}
            onMouseLeave={() => disableHover ? () => { } : setHover(false)}
        >
            <div className="card-left-section">
                <img className="track-image" alt="Track" src={trackInfo.track_image} />
                <div className="main-info">
                    <div className="track-name">{trackInfo.track_name}</div>
                    <div className="album-name">{trackInfo.album_name}</div>
                </div>
            </div>
            <div className="card-right-section">
                <div className="stat-section">
                    <div className="value-wrapper">{statValue}</div>
                    {!hideChange &&
                        <div className={`change-section ${statsChange < 0 ? "negative" : "positive"}`}>
                            {statsChange < 0 ?
                                <Icon iconType={ICONS.DoubleCaretDown} width="22" height="22" /> :
                                <Icon iconType={ICONS.DoubleCaretUp} width="22" height="22" />
                            }
                            <div className="change-value">{statsChange}%</div>
                        </div>
                    }
                </div>
            </div>
            {(iconType && !hideStatus) && (
                <Icon iconType={iconType} width="32" height="32" />
            )}
        </div>
    );
};