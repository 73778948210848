import { useEffect, useState } from "react";
import { SideMenuPlugButton } from "../SideMenuButton/SideMenuPlugButton";
import { useLocation } from "react-router-dom";
import { absolutePaths } from "../../../routes";
import { AnimatePresence, motion } from "framer-motion";

export const SideMenuCompositeButton = ({ variant, children }) => {
    const [active, setActive] = useState(false);
    const [activePage, setActivePage] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === absolutePaths.trackStats || location.pathname === absolutePaths.generalStats) {
            setActivePage(true);
        } else {
            setActivePage(false);
            setActive(false);
        }
    }, [location]);

    return (
        <>
            <div className="stats-menu">
                <AnimatePresence>
                    <SideMenuPlugButton isActive={activePage} variant={variant} onClick={() => setActive(prev => !prev)} />
                    {active &&
                        <motion.div
                            key="sub-menu"
                            style={{overflow: "hidden"}}
                            initial={{height: 0}}
                            animate={{height: "auto"}}
                            exit={{height: 0}}
                        >
                            {children}
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
        </>
    );
}