import React, { useState } from "react";
import "./StatTab.css";

export const StatTab = ({ label, value, active = false, onClick = () => { } }) => {
    const [hover, setHover] = useState(false);

    return (
        <div 
            onClick={onClick} 
            className={`stat-tab ${active ? "active" : (hover ? "hover" : "")}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div className="label">{label}</div>
            <div className="value-text">{value}</div>
        </div>
    );
};