import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { absolutePaths } from "../../routes";
import { SigmundContext } from "../../contexts/SigmundContext";
import { ClaimBox } from "../../blocks/ClaimBox/ClaimBox";
import { AuthContext } from "../../contexts/AuthContext";
import "./ClaimArtist.css";

export const ClaimArtist = () => {
    const { profileInfo, sigmundStatus } = useContext(SigmundContext);
    const { SignOut } = useContext(AuthContext);
    const navigate = useNavigate();

    const LogOut = () => {
        SignOut();
        navigate(absolutePaths.singIn, { replace: true });
    };

    const GoBack = () => {
        navigate(absolutePaths.dashboard, { replace: true });
    }

    return (
        <>
            {profileInfo.artist_claim_available ? (
                <div className="claim-artist-frame">
                    <div className="claim-artist-section">
                        <div className="header">
                            <div className="title">Claim an artist profile</div>
                        </div>
                        <ClaimBox onBack={sigmundStatus === "initial_artist_claim" ? LogOut : GoBack} />
                    </div>
                </div>
            ) : (<Navigate to={absolutePaths.dashboard} />)}
        </>
    );
};