import { useContext, useEffect, useState } from "react";
import { FansInsightsQuery } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";
import { SigmundContext } from "../../contexts/SigmundContext";

export const useFansInsightsQuery = () => {
    const [insights, setInsights] = useState(null);
    const { currentProfile } = useContext(SigmundContext);
    const queryErrorHandler = useQueryErrorHandler();
    const insightsQuery = FansInsightsQuery(currentProfile.artist_id);

    useEffect(() => {   
        if (insightsQuery.isFetching) {
            setInsights(null);
        } else if (insightsQuery.isSuccess) {
            setInsights(insightsQuery.data);
        } else if (insightsQuery.isError) {
            queryErrorHandler(insightsQuery.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insightsQuery.isSuccess, insightsQuery.isError, currentProfile.artist_id]);

    return { insights };
};