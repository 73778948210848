import { useState } from "react";
import { ClaimStatusMutation } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";

export const useClaimStatusQuery = () => {
    const queryErrorHandler = useQueryErrorHandler();
    const [artistInfo, setArtistInfo] = useState({});
    const queryMutation = ClaimStatusMutation();

    const claimStatusQuery = (artistUrl) => {
        queryMutation.mutate({ artistUrl }, {
            onSuccess: async (response) => {
                const data = await response.json();
                setArtistInfo(data);
            },
            onError: (error) => {
                if (error.status === 400 || error.status === 404) {
                    setArtistInfo({});
                } else {
                    queryErrorHandler(error);
                }
            }
        })
    };

    return { artistInfo, claimStatusQuery, queryMutation };
};