import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { CloseButton } from "../../Buttons/CloseButton/CloseButton";
import { ICONS, Icon } from "../../Icons/Icon";
import "./NotificationsOverlay.css";
import { ResponsiveContext } from "../../../contexts/ResponsiveContext";
import { NotificationCard } from "../../Cards/NotificationCard/NotificationCard";
import { NotificationsQuery, ViewNotificationsMutation } from "../../../queries/sigmundApi";
import { LoaderBox } from "../../../blocks/LoaderBox/LoaderBox";

export const NotificationsOverlay = ({ onClose }) => {
    const { notificationWidth } = useContext(ResponsiveContext);
    const query = NotificationsQuery();
    const viewNotificationsMutation = ViewNotificationsMutation();

    useEffect(() => {
        if (query.data?.unread) {
            viewNotificationsMutation.mutate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="notification-overlay" style={{ width: notificationWidth }}>
            <div className="top-section">
                <div className="label-text-wrapper">Notifications</div>
                <CloseButton onClick={onClose} />
            </div>
            {query.isPending ? (
                <LoaderBox backgroundColor="false" />
            ) : (
                query.data.notifications?.length ? (
                    <div className="notifications">
                        {query.data.notifications.map(item => <NotificationCard key={item.title + item.date} notification={item} />)}
                    </div>
                ) : (
                    <div className="empty-box">
                        <Icon iconType={ICONS.BellSlash} width="80" height="80" />
                        <p className="text-wrapper">You don&rsquo;t have any notifications</p>
                    </div>
                )
            )}
        </div>
    );
};

NotificationsOverlay.defaultProps = {
    onClose: () => { },
};

NotificationsOverlay.propTypes = {
    onClose: PropTypes.func,
};
