import { useContext, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { absolutePaths } from "../routes";

export const RequireLogout = () => {
    const { isAuth, CheckAuthCallback } = useContext(AuthContext);
    const location = useLocation();
    const from = location.state?.from?.pathname || absolutePaths.dashboard;

    useEffect(() => {
        CheckAuthCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!isAuth ?
                <Outlet /> :
                <Navigate to={from} />}
        </>
    );
};