import PropTypes from "prop-types";
import React from "react";
import "./SettingsButton.css";
import { Icon, ICONS } from "../../Icons/Icon";

export const SettingsButton = ({ onClick, disabled }) => {
    return (
        <button 
            onClick={onClick} 
            disabled={disabled} 
            className={`settings-button ${disabled ? "disable" : ""}`}
        >
            <Icon iconType={ICONS.Gear} width="32" height="32"/>
        </button>
    );
};

SettingsButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

SettingsButton.defaultProps = {
    onClick: () => {},
    disabled: false,
}

