import React, { useState } from "react";
import { motion } from "framer-motion";
import "./Switch.css";

export const Switch = ({ options, defaultOption, style = null }) => {
    const [activeOption, setActiveOption] = useState(defaultOption);

    const handleSwitch = (option) => {
        setActiveOption(option.name);
        option.onClick();
    };

    return (
        <div className="switch-component" style={style}>
            {options.map((option) => 
                <motion.div
                    animate={{ opacit: 1 }}
                    key={option.name} 
                    className={`option ${activeOption === option.name ? "active" : ""}`}
                    onClick={() => handleSwitch(option)}
                >
                    <div className="text-wrapper">{option.name}</div>
                </motion.div>
            )}
        </div>
    );
};