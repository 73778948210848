import "./ChatsBox.css";
import React, { useEffect, useState } from "react";
import { ChatCard } from "../../components/Cards/ChatCard/ChatCard";
import { ICONS, Icon } from "../../components/Icons/Icon";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { useContactsQuery } from "../../hooks/QueryHooks/useContacts";
import { ChatContactCard } from "../../components/Cards/ChatContactCard/ChatContactCard";
import { useNavigate, useParams } from "react-router-dom";
import { absolutePaths } from "../../routes";
import { useMessenger } from "../../hooks/useMessenger";
import { NewChatButton } from "../../components/Buttons/NewChatButton/NewChatButton";

export const ChatsBox = () => {
    const [currentChat, setCurrentChat] = useState(null);
    const [search, setSearch] = useState(false);
    const { chatsQuery, createPrivateChat, isCreating } = useMessenger();
    const { contacts, contactsQuery } = useContactsQuery();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.chatToken) {
            setCurrentChat(params.chatToken);
        }
    }, [params.chatToken]);

    const handleContact = (contactToken) => {
        const chats = chatsQuery.data ? chatsQuery.data : [];
        const chatIdx = chats?.length > 0 ?
            chats.findIndex(item => item.chat_members.findIndex(member => member["contact_token"] === contactToken) !== -1) : -1;
        if (chatIdx === -1) {
            createPrivateChat(contactToken);
        } else {
            const chatToken = chats[chatIdx].chat_token;
            setCurrentChat(chatToken);
            navigate(`${absolutePaths.messenger}/${chatToken}`, { replace: true });
        }
        setSearch(false);
    };

    const switchChat = (chatToken) => {
        navigate(`${absolutePaths.messenger}/${chatToken}`, { replace: true });
    };

    return (
        <div className="chats-box">
            <div className="top-section">
                {/* <SearchBar /> */}
            </div>
            {(!chatsQuery.data || isCreating) ? (
                <div className="empty-box">
                    <LoaderBox backgroundColor={false} />
                </div>
            ) : (
                <>
                    {search ? (
                        <>
                            {contacts?.length > 0 ? (
                                <div className="chats">
                                    {contacts.map((contact) => (
                                        <ChatContactCard
                                            onClick={() => handleContact(contact.contact_token)}
                                            key={contact.contact_token}
                                            contactInfo={contact}
                                        />
                                    ))}
                                </div>
                            ) : (
                                contactsQuery.isPending ? (
                                    <div className="empty-box">
                                        <LoaderBox backgroundColor={false} />
                                    </div>
                                ) : (
                                    <div className="empty-box">
                                        <Icon iconType={ICONS.Users} width="80" height="80" />
                                        <div className="text-wrapper">No contacts</div>
                                    </div>
                                )
                            )}
                            <NewChatButton onClick={() => setSearch(false)} buttonType="back" />
                        </>
                    ) : (
                        <>
                            {chatsQuery.data?.length > 0 ? (
                                <div className="chats">
                                    {chatsQuery.data.map((chat) => (
                                        <ChatCard
                                            key={chat.chat_token}
                                            chatInfo={chat}
                                            active={chat.chat_token === currentChat}
                                            onClick={() => switchChat(chat.chat_token)}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div className="empty-box">
                                    <Icon iconType={ICONS.Users} width="80" height="80" />
                                    <div className="text-wrapper">No chats</div>
                                </div>
                            )}
                            <NewChatButton onClick={() => setSearch(true)} />
                        </>
                    )}
                </>

            )}
        </div>
    );
};
