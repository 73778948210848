import "./UpdateUsernameBox.css";
import { useContext, useState } from "react";
import { ValidateUsernameMutation } from "../../queries/sigmundApi";
import { absolutePaths } from "../../routes";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { motion, AnimatePresence } from "framer-motion";
import { usernameRegex } from "../../tools";
import { useUpdateUsername } from "../../hooks/QueryHooks/useUpdateUsername";
import { SigmundContext } from "../../contexts/SigmundContext";
import { Link, useNavigate } from "react-router-dom";
import { LoaderBox } from "../LoaderBox/LoaderBox";


export const UpdateUsernameBox = () => {
    const { profileInfo, SigmundStatusCallback } = useContext(SigmundContext);
    const [username, setUsername] = useState("");
    const [invalidInput, setInvalidInput] = useState(null);
    const [validationAlert, setValidationAlert] = useState(null);
    const validateUsernameMutation = ValidateUsernameMutation();
    const { updateUsername, queryMutation } = useUpdateUsername();
    const navigate = useNavigate();

    const validateUsername = (username) => {
        if (username) {
            if (!usernameRegex.test(username)) {
                setValidationAlert("The username can only contain upper and lower case latin characters, numbers, _ and -");
                setInvalidInput(true);
                return;
            } else {
                setValidationAlert(null);
                setInvalidInput(false);
            }

            validateUsernameMutation.mutate(username, {
                onSuccess: async (data) => {
                    const response = await data.json();
                    if (response === "claimed") {
                        setValidationAlert("This username is already taken");
                        setInvalidInput(true);
                    } else if (response === "available") {
                        setValidationAlert(null);
                        setInvalidInput(false);
                    }
                }
            });
        }
    };

    const handleChange = (item) => {
        setInvalidInput(false);
        setUsername(item.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!username) {
            setInvalidInput(true);
        } else if (!validationAlert && !validateUsernameMutation.isPending) {
            updateUsername(username, () => {
                SigmundStatusCallback();
                navigate(absolutePaths.dashboard);
            });
        }
    }

    return (
        <form className="update-username-box" onSubmit={handleSubmit}>
            <div className="form">
                <div className="div">
                    <label htmlFor="username" className="input-label">New username</label>
                    <input
                        id="username"
                        type="text"
                        value={username}
                        onChange={handleChange}
                        onBlur={() => validateUsername(username)}
                        className={`text-box-wrapper ${invalidInput ? "invalid" : ""}`}
                        placeholder={profileInfo.username} />
                </div>
            </div>
            <PrimaryButton type="submit" 
                disabled={queryMutation.isPending}
                disableStyle="outline"    
            >
                {queryMutation.isPending ? 
                <LoaderBox backgroundColor={false} box={false} height="24" width="24" type="spinner" /> 
                : "Change"}
            </PrimaryButton>
            <div className="alternative">
                <AnimatePresence>
                    {validationAlert &&
                        <motion.div
                            style={{ overflow: "hidden" }}
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            exit={{ height: 0, opacity: 0 }}
                            className="alert"
                        >
                            {validationAlert}
                        </motion.div>
                    }
                </AnimatePresence>
                <div className="default">
                    <Link to={absolutePaths.dashboard} className="link">Go back</Link>
                </div>
            </div>
        </form>
    )
};
