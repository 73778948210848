import React, { useContext, useState } from "react";
import { FirstStage } from "./FirstStage";
import { SecondStage } from "./SecondStage";
import "./SignUpBox.css";
import { AuthContext } from "../../contexts/AuthContext";

export const SignUpBox = () => {
    const [formData, setFormData] = useState({
        username: "",
        role: "",
        email: "",
        password: "",
        referralId: ""
    });
    const [stage, setStage] = useState(1);
    const { SignInCallback } = useContext(AuthContext);

    const handleSubmit = () => {
        SignInCallback(formData.email, formData.password);
    };

    return (
        <>
            {stage === 1 &&
                <FirstStage
                    formData={formData}
                    setFormData={setFormData}
                    onComplete={() => setStage(2)} />
            }
            {stage === 2 &&
                <SecondStage
                    formData={formData}
                    setFormData={setFormData}
                    onComplete={handleSubmit}
                    onBack={() => setStage(1)} />
            }
        </>
    );
};