import React from "react";
import "./SignIn.css";
import { SignInBox } from "../../blocks/SignInBox/SignInBox";

export const SignIn = () => {
    return (
        <>
            <div className="sign-in-frame">
                <div className="sign-in-section">
                    <header className="header">
                        <div className="title">Welcome back</div>
                    </header>
                    <SignInBox />
                </div>
            </div>
        </>
    );
};