import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as ApproveCircle } from "../../assets/Icons/ApproveCircle.svg";
import { ReactComponent as ApproveCircleFilled } from "../../assets/Icons/ApproveCircleFilled.svg";
import { ReactComponent as WarningCircle } from "../../assets/Icons/WarningCircle.svg";
import { ReactComponent as WarningCircleFilled } from "../../assets/Icons/WarningCircleFilled.svg";
import { ReactComponent as PlusCircle } from "../../assets/Icons/PlusCircle.svg";
import { ReactComponent as MinusCircle } from "../../assets/Icons/MinusCircle.svg";
import { ReactComponent as CaretDown } from "../../assets/Icons/CaretDown.svg";
import { ReactComponent as CaretUp } from "../../assets/Icons/CaretUp.svg";
import { ReactComponent as CaretBoldUp } from "../../assets/Icons/CaretBoldUp.svg";
import { ReactComponent as CaretBoldDown } from "../../assets/Icons/CaretBoldDown.svg";
import { ReactComponent as Close } from "../../assets/Icons/CloseIcon.svg";
import { ReactComponent as House } from "../../assets/Icons/House.svg";
import { ReactComponent as HouseFilled } from "../../assets/Icons/HouseFilled.svg";
import { ReactComponent as SpotifyLogo } from "../../assets/Icons/SpotifyLogo.svg";
import { ReactComponent as SpotifyLogoFilled } from "../../assets/Icons/SpotifyLogoFilled.svg";
import { ReactComponent as UsersThree } from "../../assets/Icons/UsersThree.svg";
import { ReactComponent as UsersThreeFilled } from "../../assets/Icons/UsersThreeFilled.svg";
import { ReactComponent as ChartBar } from "../../assets/Icons/ChartBar.svg";
import { ReactComponent as ChartBarFilled } from "../../assets/Icons/ChartBarFilled.svg";
import { ReactComponent as ChatCircle } from "../../assets/Icons/ChatCircle.svg";
import { ReactComponent as ChatCircleFilled } from "../../assets/Icons/ChatCircleFilled.svg";
import { ReactComponent as MusicNotes } from "../../assets/Icons/MusicNotes.svg";
import { ReactComponent as MusicNotesFilled } from "../../assets/Icons/MusicNotesFilled.svg";
import { ReactComponent as Users } from "../../assets/Icons/Users.svg";
import { ReactComponent as UsersFilled } from "../../assets/Icons/UsersFilled.svg";
import { ReactComponent as Bell } from "../../assets/Icons/Bell.svg";
import { ReactComponent as BellRinging } from "../../assets/Icons/BellRinging.svg";
import { ReactComponent as BellSlash } from "../../assets/Icons/BellSlash.svg";
import { ReactComponent as Gear } from "../../assets/Icons/Gear.svg";
import { ReactComponent as SignOut } from "../../assets/Icons/SignOut.svg";
import { ReactComponent as List } from "../../assets/Icons/List.svg";
import { ReactComponent as Plug } from "../../assets/Icons/Plug.svg";
import { ReactComponent as Plugs } from "../../assets/Icons/Plugs.svg";
import { ReactComponent as Prohibit } from "../../assets/Icons/Prohibit.svg";
import { ReactComponent as DoubleCaretUp } from "../../assets/Icons/DoubleCaretUp.svg";
import { ReactComponent as DoubleCaretDown } from "../../assets/Icons/DoubleCaretDown.svg";
import { ReactComponent as AirplaneTakeoff } from "../../assets/Icons/AirplaneTakeoff.svg";
import { ReactComponent as AirplaneLanding } from "../../assets/Icons/AirplaneLanding.svg";
import { ReactComponent as Question } from "../../assets/Icons/Question.svg";
import { ReactComponent as Square } from "../../assets/Icons/Square.svg";
import { ReactComponent as CheckSquare } from "../../assets/Icons/CheckSquare.svg";
import { ReactComponent as PlusSquare } from "../../assets/Icons/PlusSquare.svg";
import { ReactComponent as PlusSquareFilled } from "../../assets/Icons/PlusSquareFilled.svg";
import { ReactComponent as Armchair } from "../../assets/Icons/Armchair.svg";
import { ReactComponent as ArrowLeft } from "../../assets/Icons/ArrowLeft.svg";
import { ReactComponent as ArrowCircleLeft } from "../../assets/Icons/ArrowCircleLeft.svg";
import { ReactComponent as ArrowCircleLeftFilled } from "../../assets/Icons/ArrowCircleLeftFilled.svg";
import { ReactComponent as ArrowCircleRight } from "../../assets/Icons/ArrowCircleRight.svg";
import { ReactComponent as ArrowCircleRightFilled } from "../../assets/Icons/ArrowCircleRightFilled.svg";
import { ReactComponent as MagnifyingGlass } from "../../assets/Icons/MagnifyingGlass.svg";
import { ReactComponent as MagnifyingGlassFilled } from "../../assets/Icons/MagnifyingGlassFilled.svg";
import { ReactComponent as Mute } from "../../assets/Icons/Mute.svg";
import { ReactComponent as Dot } from "../../assets/Icons/Dot.svg";
import { ReactComponent as DotFilled } from "../../assets/Icons/DotFilled.svg";
import { ReactComponent as VinylRecord } from "../../assets/Icons/VinylRecord.svg";
import { ReactComponent as CalendarCheck } from "../../assets/Icons/CalendarCheck.svg";
import { ReactComponent as CalendarCheckFilled } from "../../assets/Icons/CalendarCheckFilled.svg";
import { ReactComponent as DotsThreeOutline } from "../../assets/Icons/DotsThreeOutline.svg";
import { ReactComponent as Clipboard } from "../../assets/Icons/Clipboard.svg";
import { ReactComponent as ClipboardFilled } from "../../assets/Icons/ClipboardFilled.svg";
import { ReactComponent as Trash } from "../../assets/Icons/Trash.svg";
import { ReactComponent as ShareFat } from "../../assets/Icons/ShareFat.svg";
import { ReactComponent as PencilSimple } from "../../assets/Icons/PencilSimple.svg";
import { ReactComponent as PaperPlaneRight } from "../../assets/Icons/PaperPlaneRight.svg";
import { ReactComponent as Check } from "../../assets/Icons/Check.svg";
import { ReactComponent as CheckDouble } from "../../assets/Icons/CheckDouble.svg";
import { ReactComponent as ArrowBendDownRight } from "../../assets/Icons/ArrowBendDownRight.svg";
import { ReactComponent as ArrowBendDownLeft } from "../../assets/Icons/ArrowBendDownLeft.svg";
import { ReactComponent as Circle } from "../../assets/Icons/Circle.svg";


export const ICONS = {
    Circle,
    ArrowLeft,
    ArrowBendDownLeft,
    ArrowBendDownRight,
    Check,
    CheckDouble,
    PaperPlaneRight,
    PencilSimple,
    ShareFat,
    Trash,
    ApproveCircle,
    ApproveCircleFilled,
    WarningCircle,
    WarningCircleFilled,
    PlusCircle,
    MinusCircle,
    CaretDown,
    CaretUp,
    CaretBoldUp,
    CaretBoldDown,
    Close,
    House,
    HouseFilled,
    SpotifyLogo,
    SpotifyLogoFilled,
    UsersThree,
    UsersThreeFilled,
    ChartBar,
    ChartBarFilled,
    ChatCircle,
    ChatCircleFilled,
    MusicNotes,
    MusicNotesFilled,
    Users,
    UsersFilled,
    Bell,
    BellSlash,
    BellRinging,
    Gear,
    SignOut,
    List,
    Plug,
    Plugs,
    Prohibit,
    DoubleCaretUp,
    DoubleCaretDown,
    AirplaneLanding,
    AirplaneTakeoff,
    Question,
    Square,
    CheckSquare,
    PlusSquare,
    PlusSquareFilled,
    Armchair,
    ArrowCircleLeft,
    ArrowCircleRight,
    ArrowCircleLeftFilled,
    ArrowCircleRightFilled,
    MagnifyingGlass,
    MagnifyingGlassFilled,
    Mute,
    VinylRecord,
    CalendarCheck,
    CalendarCheckFilled,
    Dot,
    DotFilled,
    DotsThreeOutline,
    Clipboard,
    ClipboardFilled,
};

export const Icon = ({ iconType, color, width, height }) => {
    return React.createElement(iconType, {
        width,
        height,
        style: { "color": color }
    });
};

Icon.defaultProps = {
    color: "white",
    width: "32",
    height: "32"
};

Icon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
};