import PropTypes from "prop-types";
import React from "react";
import "./ArtistSelectionCard.css";

export const ArtistSelectionCard = ({ active, artistName, artistImage, onClick }) => {
  return (
    <div onClick={onClick} className={`artist-menu-card ${active ? "active" : ""}`}>
      <div className="text-wrapper">{artistName}</div>
      <img src={artistImage} alt="Artist Icon" draggable={false} className="profile-icon" />
    </div>
  );
};

ArtistSelectionCard.defaultProps = {
  active: false,
};

ArtistSelectionCard.propTypes = {
  active: PropTypes.bool,
  artistName: PropTypes.string,
  artistImage: PropTypes.string,
  onClick: PropTypes.func,
};
