import { useContext, useState } from "react";
import { SearchTracksMutation, UpdateCatalogMutation } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";
import { SigmundContext } from "../../contexts/SigmundContext";

export const useSeacrhTracksQuery = () => {
    const [tracks, setTracks] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const { currentProfile } = useContext(SigmundContext);
    const queryErrorHandler = useQueryErrorHandler();
    const queryMutation = SearchTracksMutation();
    const updateCatalogMutation = UpdateCatalogMutation();

    const updateCatalog = () => {
        setIsFetching(true);
        if (currentProfile.artist_id) {
            updateCatalogMutation.mutate({ artistId: currentProfile.artist_id }, {
                onSuccess: async (response) => {
                    const data = await response.json();
                    setTracks(data);
                    setIsFetching(false);
                },
                onError: (error) => {
                    queryErrorHandler(error);
                    setIsFetching(false);
                }
            })
        }
    }

    const search = (trackName) => {
        setIsFetching(true);
        if (currentProfile.artist_id) {
            queryMutation.mutate({ artistId: currentProfile.artist_id, trackName }, {
                onSuccess: async (response) => {
                    const data = await response.json();
                    setTracks(data);
                    setIsFetching(false);
                },
                onError: (error) => {
                    queryErrorHandler(error);
                    setIsFetching(false);
                }
            })
        }
    };

    return {search, updateCatalog, tracks, isFetching};
};