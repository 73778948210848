import PropTypes from "prop-types";
import { useState } from "react";
import { ICONS, Icon } from "../Icons/Icon";
import { CloseButton } from "../Buttons/CloseButton/CloseButton";
import "./SearchBar.css";

export const SearchBar = ({ onSearch, inputRef = null }) => {
    const [focus, setFocus] = useState(false);
    const [search, setSearch] = useState("");

    const handleChange = (e) => {
        setSearch(e.target.value);
        onSearch(e.target.value);
    };

    const clearChange = () => {
        setSearch("");
        onSearch("");
    };

    return (
        <div className={`search-bar ${focus ? "focus" : ""}`}>
            <div className="search-section">
                {focus ? (
                    <Icon iconType={ICONS.MagnifyingGlassFilled} width="16" height="16" />
                ) : (
                    <Icon iconType={ICONS.MagnifyingGlass} width="16" height="16" />
                )}
                <input
                    id="search"
                    type="text"
                    value={search}
                    onChange={handleChange}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    autoComplete="off"
                    maxLength="100"
                    ref={inputRef}
                    className="text-box-wrapper"
                    placeholder="Search" />
            </div>
            <CloseButton width="16" height="16" onClick={clearChange}/>
        </div>
    );
};

SearchBar.propTypes = {
    onSearch: PropTypes.func,
};

SearchBar.defaultProps = {
    onSearch: () => {},
}