import React, { useContext } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { absolutePaths } from "../../routes";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

export const Footer = () => {
    const { isMobile, isSmallMobile } = useContext(ResponsiveContext);
    return (
        <div className={`footer-component ${isMobile || isSmallMobile ? "mobile" : "desktop"}`}>
            <div className="left-section">
                <div className="text-wrapper">SFScoop © 2024</div>
            </div>
            <div className="right-section">
                <Link to={absolutePaths.policy} className="link">Privacy</Link>
                <Link to={absolutePaths.terms} className="link">Terms</Link>
                <Link to={absolutePaths.contactUs} className="link">Contact Us</Link>
            </div>
        </div>
    );
};