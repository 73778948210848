import React from "react";
import { Icon, ICONS } from "../../Icons/Icon";
import { motion } from "framer-motion";
import "./ClaimArtistCard.css";

export const ClaimArtistCard = ({ aritstImage, artistName, approved, disable_approve_icon = false }) => {
    return (
        <motion.div
            key={artistName}
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.45 }}
            style={{ overflow: "hidden" }}
            className="claim-artist-card-component"
        >
            <div className="card-section">
                <img className="artist-image" alt="Artist" src={aritstImage} />
                <div className="artist-name">{artistName}</div>
            </div>
            {!disable_approve_icon && (
                approved ? <Icon iconType={ICONS.ApproveCircle} height="32" width="32" color="#75D35F" />
                : <Icon iconType={ICONS.WarningCircle} height="32" width="32" color="#FFAA33" />)
            }
        </motion.div>
    );
};


