import "./Messenger.css";
import { ChatsBox } from "../../blocks/ChatsBox/ChatsBox";
import { MessengerBox } from "../../blocks/MessengerBox/MessengerBox";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { absolutePaths } from "../../routes";
import { SigmundContext } from "../../contexts/SigmundContext";
import { useMessenger } from "../../hooks/useMessenger";

export const Messenger = () => {
    const { isDesktop } = useContext(ResponsiveContext);
    const params = useParams();
    const navigate = useNavigate();
    const { currentProfile } = useContext(SigmundContext);
    useMessenger();

    useEffect(() => {
        if (params.chatToken) {
            navigate(absolutePaths.messenger, { replace: true });
        }
    }, [currentProfile.artist_id]);

    return (
        <>
            <div className="messenger-section">
                {isDesktop && 
                    <>
                        <ChatsBox/>
                        <MessengerBox />
                    </>
                }
                {!isDesktop && 
                    <>
                        {params.chatToken ? (
                            <MessengerBox />
                        ) : (
                            <ChatsBox/>
                        )}
                    </>
                }
            </div>
        </>
    );
};