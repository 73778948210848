import { useContext, useEffect, useState } from "react";
import { GeneralInsightsMutation, GeneralInsightsQuery } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";
import { SigmundContext } from "../../contexts/SigmundContext";

export const useGeneralInsightsQuery = ({ period, dimension }) => {
    const [insights, setInsights] = useState(null);
    const { currentProfile } = useContext(SigmundContext);
    const queryErrorHandler = useQueryErrorHandler();
    const query = GeneralInsightsQuery({ artistId: currentProfile.artist_id, period, dimension });
    const queryMutation = GeneralInsightsMutation();

    useEffect(() => {
        if (query.isFetching) {
            setInsights(null);
        } else if (query.isSuccess) {
            setInsights(query.data);
        } else if (query.isError) {
            queryErrorHandler(query.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.data, query.error, currentProfile.artist_id]);

    const getInsights = (period, dimension) => {
        if (currentProfile.artist_id) {
            queryMutation.mutate({
                artistId: currentProfile.artist_id,
                period: period,
                dimension: dimension,
            }, {
                onSuccess: (data) => {
                    setInsights(data);
                },
                onError: (error) => {
                    queryErrorHandler(error);
                }
            })
        }
    };

    return { insights, getInsights };
};