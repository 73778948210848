import "./DropDown.css";
import { Icon, ICONS } from "../Icons/Icon";
import { OptionLine } from "../Icons/OptionLine";
import { useState } from 'react';
import { useOutsideClickDetector } from '../../hooks/useOutsideClickDetector';
import PropTypes from "prop-types";
import useMeasure from "react-use-measure";

export const DropDown = ({ options, placeHolder, onSelect, defaultOption, invalid }) => {
    const [activeOption, setActiveOption] = useState(defaultOption);
    const [open, setOpen] = useState(false);
    const clickDetector = useOutsideClickDetector(() => setOpen(false));
    const [ref, { width }] = useMeasure();

    const handleControlClick = () => {
        setOpen(prev => !prev);
    };

    const handleOptionClick = (option) => {
        setActiveOption(option);
        onSelect(option);
        setOpen(false);
    };

    return (
        <div ref={clickDetector} className="dropdown-box">
            <div ref={ref} onClick={handleControlClick} className={`${open ? "active-control" : "control"} ${invalid && !open ? "invalid-control": ""} text-wrapper`}>
                {activeOption ? activeOption : placeHolder}
                {open ? <Icon iconType={ICONS.CaretDown} height="32" width="32" /> : <Icon iconType={ICONS.CaretUp} height="32" width="32"/>}
            </div>
            {open &&
                <div style={{ width }} className="menu">
                    {options.map((option) =>
                        <div
                            key={option}
                            onClick={() => handleOptionClick(option)}
                            className={`option${option === activeOption ? " active " : " "}text-wrapper`}>
                            {option === activeOption && <OptionLine />}
                            {option}
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

DropDown.defaultProps = {
    options: [],
    placeHolder: "",
    onSelect: () => { },
    defaultOption: null,
    invalid: false
};

DropDown.propTypes = {
    options: PropTypes.array,
    placeHolder: PropTypes.string,
    onSelect: PropTypes.func,
    defaultOption: PropTypes.string,
    invalid: PropTypes.bool
};