import React from "react";
import { SigmundBucketCard } from "../SigmundBucketCard/SigmundBucketCard";
import "./SigmundRateCardBucket.css";

export const SigmundRateCardBucket = ({ rate, promotionInfo }) => {
  return (
    <div className="sigmund-rate-card-bucket">
      <div className="rate-section">
        <div className="rate-text">{rate}%</div>
        <div className="label">Sigmund rate</div>
      </div>
      <SigmundBucketCard promotionInfo={promotionInfo} />
    </div>
  );
};