import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { SigmundContext } from "../contexts/SigmundContext";
import { LoaderBox } from "../blocks/LoaderBox/LoaderBox";
import { NotificationsQuery } from "../queries/sigmundApi";

export const RequireSigmund = ({ loaderBackground = true }) => {
    const { SigmundStatusCallback, profileInfo } = useContext(SigmundContext);
    NotificationsQuery();

    useEffect(() => {
        if (!profileInfo.username) {
            SigmundStatusCallback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {profileInfo.username ? <Outlet  /> : <LoaderBox backgroundColor={loaderBackground} />}
        </>
    );
};