import React, { useContext } from "react";
import { BriefStatCard } from "../../components/Cards/BriefStatCard/BriefStatCard"
import { useBriefInsightsQuery } from "../../hooks/QueryHooks/useBriefInsights";

import "./BriefStats.css";
import { changeFormatter, nFormatter, hourFormatter, pluralize } from "../../tools";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";

export const BriefStats = () => {
    const { insights } = useBriefInsightsQuery();
    const { isExtremeSmallMobile } = useContext(ResponsiveContext);
    const now = new Date();
    const prev = new Date();
    prev.setDate(0);
    const dateString = ` • ${prev.toLocaleString('en', { month: 'short' })} - ${now.toLocaleString('en', { month: 'short' })}`

    return (
        <div className="brief-stats-box">
            <div className="top-section">
                <p className="title">{isExtremeSmallMobile ? "SFScoop brief" : "You got on the SFSCOOP"}</p>
                <p className="subtitle">change since last month{isExtremeSmallMobile ? null : dateString}</p>
            </div>
            <div className="stats-frame">
                <div className="stats">
                    {insights ? (
                        <>
                            <BriefStatCard label={pluralize(insights.streams, "stream")} value={nFormatter(insights.streams, 1)} changeValue={changeFormatter(insights.streams_change)} />
                            <BriefStatCard label={insights.hours < 1 ? pluralize(Math.round(insights.hours * 60), "minute") : pluralize(Math.round(insights.hours), "hour")} value={hourFormatter(insights.hours)} changeValue={changeFormatter(insights.hours_change)} />
                            <BriefStatCard label={pluralize(insights.fans, "fan")} value={nFormatter(insights.fans)} changeValue={changeFormatter(insights.fans_change)} />
                        </>
                    ) : (
                        <LoaderBox backgroundColor={false} />
                    )}
                </div>
            </div>
        </div>
    );
};