import { ArtistTracksBox } from "../../blocks/ArtistTracksBox/ArtistTracksBox";
import { SigmundRateBox } from "../../blocks/SigmundRateBox/SigmundRateBox";
import { ArtistPromotionTracksBox } from "../../blocks/ArtistPromotionTracksBox/ArtistPromotionTracksBox";
import { useContext } from "react";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { ArtistTracksBoxComposite } from "../../blocks/ArtistTracksBox/ArtistTracksBoxComposite";
import "./TrackManagement.css";


export const TrackManagement = () => {
    const { isDesktop } = useContext(ResponsiveContext);

    return (
        <>
            {isDesktop ? (
                <>
                    <div className="management-section">
                        <ArtistTracksBox />
                    </div>
                    <div className="management-section">
                        <SigmundRateBox manageButton={false} />
                        <ArtistPromotionTracksBox />
                    </div>
                </>
            ) : (
                <div className="management-section">
                    <SigmundRateBox manageButton={false} />
                    <ArtistTracksBoxComposite />
                </div>
            )}
        </>
    );
};