import { useContext, useEffect, useState } from "react";
import { CuratorRequestStatusMutation, CuratorRequestStatusQuery } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";
import { SigmundContext } from "../../contexts/SigmundContext";

export const useCuratorRequestStatusQuery = () => {
    const queryErrorHandler = useQueryErrorHandler();
    const { currentProfile } = useContext(SigmundContext);
    const [requestInfo, setRequestInfo] = useState(null);
    const queryMutation = CuratorRequestStatusMutation(currentProfile.artist_id);
    const query = CuratorRequestStatusQuery(currentProfile.artist_id);

    useEffect(() => {
        if (query.isFetching) {
            setRequestInfo(null);
        } if (query.isSuccess) {
            setRequestInfo(query.data);
        } else if (query.isError) {
            queryErrorHandler(query.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isSuccess, query.isError, currentProfile.artist_id]);

    const requestStatusQuery = () => {
        queryMutation.mutate({}, {
            onSuccess: (data) => {
                setRequestInfo(data);
            },
            onError: (error) => {
                queryErrorHandler(error);
            }
        })
    };

    return { requestInfo, setRequestInfo, requestStatusQuery };
};