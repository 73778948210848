import { CloseButton } from "../../Buttons/CloseButton/CloseButton";
import { motion } from "framer-motion";
import "./GenreCard.css";

export const GenreCard = ({ genreName, onClose }) => {
    return (
        <motion.div
            key={"genreCard" + genreName}
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0, opacity: 0 }}
        >
            <div className="genre-card">
                <div className="text-section">
                    <div className="card-text-wrapper">{genreName}</div>
                </div>
                <div className="close-section">
                    <CloseButton onClick={onClose} width="16" height="16" />
                </div>
            </div>
        </motion.div>
    );
};