export const usernameRegex = /^[a-zA-Z0-9-_]+$/;

export const changeFormatter = (value) => {
    if (-1 < value && value < 1 && value !== 0) {
        return value?.toFixed(1);
    } else {
        return Math.round(value);
    }
};

export const nFormatter = (num, digits) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    const item = lookup.findLast(item => num >= item.value);
    return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
};

export const hourFormatter = (value) => {
    if (value < 1) {
        return Math.round(value * 60);
    } else {
        return nFormatter(value, 1);
    }
}

export const pluralize = (count, noun, suffix = 's') => {
    return `${noun}${count !== 1 ? suffix : ''}`;
}

export const formatDuration = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
}

export const formatHours = (hours, short = false) => {
    const minute_label = short ? "min" : pluralize(Math.round(hours * 60), "minute");
    const hour_label = short ? "hr" : pluralize(Math.round(hours), "hour");
    return `${hourFormatter(hours)} ${hours < 1 ? minute_label : hour_label}`;
}

export const formatStatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

export const formatMessageTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    });
}

export const handleTextURLs = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return message.split(urlRegex)
        .map(part => {
            if (part.match(urlRegex)) {
                return <a href={part}>{part}</a>;
            }
            return part;
        });
}