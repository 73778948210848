import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { SideMenuButton, VARIANTS } from "../../components/Buttons/SideMenuButton/SideMenuButton";
import { absolutePaths, relativePaths } from "../../routes";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { SigmundContext } from "../../contexts/SigmundContext";
import "./SideMenu.css";
import { useSignOut } from "../../hooks/QueryHooks/useSignOut";
import { SideMenuCompositeButton } from "../../components/Buttons/SideMenuCompositeButton/SideMenuCompositeButton";
import { useLocation } from "react-router-dom";

export const SideMenu = ({ onSelect, slimStyle }) => {
    const [slim, setSlim] = useState(slimStyle);
    const { isDesktop, isMobile, isSmallMobile } = useContext(ResponsiveContext);
    const signOut = useSignOut();
    const { profileInfo } = useContext(SigmundContext);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes(relativePaths.messenger) && isDesktop) {
            setSlim(true);
        } else {
            setSlim(false);
        }
    }, [location]);

    return (
        <div className="side-primary-menu">
            {(profileInfo.user_role === "artist" || profileInfo.user_role === "manager") &&
                <>
                    <SideMenuButton slim={slim} to={absolutePaths.dashboard} variant={VARIANTS.home} active={true} onClick={onSelect} />
                    <SideMenuButton slim={slim} to={absolutePaths.tracks} variant={VARIANTS.tracks} onClick={onSelect} />
                    <SideMenuButton slim={slim} to={absolutePaths.fans} variant={VARIANTS.fans} onClick={onSelect} />
                    {slim ? (
                        <SideMenuButton slim={slim} to={absolutePaths.generalStats} variant={VARIANTS.statistics} onClick={onSelect} />
                    ) : (
                        <SideMenuCompositeButton variant={VARIANTS.statistics}>
                            <SideMenuButton to={absolutePaths.generalStats} variant={VARIANTS.generalStatsSubButton} onClick={onSelect} style={{height: 32}} />
                            <SideMenuButton to={absolutePaths.trackStats} variant={VARIANTS.trackStatsSubButton} onClick={onSelect} style={{height: 32}} />
                        </SideMenuCompositeButton>
                    )}
                    <SideMenuButton slim={slim} to={absolutePaths.messenger} variant={VARIANTS.messages} onClick={onSelect} />
                    {(isMobile || isSmallMobile) && 
                        <SideMenuButton slim={slim} to={absolutePaths.singIn} variant={VARIANTS.signOut} onClick={() => {
                            onSelect();
                            signOut();
                        }} />
                    }
                </>
            }
            {profileInfo.user_role === "fan" &&
                <>
                    <SideMenuButton slim={slim} to={absolutePaths.dashboard} variant={VARIANTS.home} onClick={onSelect} />
                    <SideMenuButton slim={slim} to={absolutePaths.music} variant={VARIANTS.music} onClick={onSelect} />
                    <SideMenuButton slim={slim} to={absolutePaths.generalStats} variant={VARIANTS.statistics} onClick={onSelect} />
                    <SideMenuButton slim={slim} to={absolutePaths.friends} variant={VARIANTS.friends} onClick={onSelect} />
                    <SideMenuButton slim={slim} to={absolutePaths.messenger} variant={VARIANTS.messages} onClick={onSelect} />
                    {(isMobile || isSmallMobile) && 
                        <SideMenuButton slim={slim} to={absolutePaths.singIn} variant={VARIANTS.signOut} onClick={() => {
                            onSelect();
                            signOut();
                        }} />
                    }
                </>
            }
            {profileInfo.user_role === "admin" &&
                <>
                    <SideMenuButton slim={slim} to={absolutePaths.adminConsole} variant={VARIANTS.console} onClick={onSelect} />
                    {(isMobile || isSmallMobile) && 
                        <SideMenuButton slim={slim} to={absolutePaths.singIn} variant={VARIANTS.signOut} onClick={() => {
                            onSelect();
                            signOut();
                        }} />
                    }
                </>
            }
        </div>
    );
};

SideMenu.propTypes = {
    onSelect: PropTypes.func,
    slimStyle: PropTypes.bool,
};

SideMenu.defaultProps = {
    onSelect: () => {},
    slimStyle: false,
};