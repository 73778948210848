import React from "react";
import "./SignUp.css";
import { Link } from "react-router-dom";
import { SignUpBox } from "../../blocks/SignUpBox/SignUpBox";
import { absolutePaths } from "../../routes";

export const SignUp = () => {
    return (
        <>
            <div className="sign-up-frame">
                <div className="sign-up-section">
                    <header className="header">
                        <div className="title">Sign up to start</div>
                    </header>
                    <SignUpBox/>
                </div>
                <div className="terms">
                    <span>By signing up you agree to the </span>
                    <Link to={absolutePaths.terms} className="link">Terms of Service</Link>
                    <span> and </span>
                    <Link to={absolutePaths.policy} className="link">Privacy Policy</Link>
                </div>
            </div>
        </>
    );
};