import { SpotifyConnectMutation } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";

export const useSpotifyConnectQuery = () => {
    const errorHandler = useQueryErrorHandler();
    const connectMutation = SpotifyConnectMutation();

    const SpotifyConnectCallback = () => {
        connectMutation.mutate({}, {
            onSuccess: async (response) => {
                const data = await response.json();
                if (data.url) {
                    window.location.href = data.url;
                }
            },
            onError: (error) => {
                errorHandler(error);
            },
        })
    };

    return { connectMutation, SpotifyConnectCallback };
};