import { createContext, useContext, useEffect, useState } from "react";
import { useQueryErrorHandler } from "../hooks/useQueryErrorHandler";
import { StreamingConnectionStatusMutation } from "../queries/sigmundApi";
import { AuthContext } from "./AuthContext";

export const StreamingContext = createContext(null);

export const StreamingProvider = ({ children }) => {
    const [connectionStatus, setConnectionStatus] = useState(null);
    const errorHandler = useQueryErrorHandler();
    const connectionStatusMutation = StreamingConnectionStatusMutation();
    const { isAuth } = useContext(AuthContext);

    useEffect(() => {
        if (!isAuth) {
            setConnectionStatus(null);
        }
    }, [isAuth]);

    const CheckConnectionCallback = () => {
        setConnectionStatus("fetching");
        connectionStatusMutation.mutate({}, {
            onSuccess: async (response) => {
                const data = await response.json();
                setConnectionStatus(data.status)
            },
            onError: (error) => {
                errorHandler(error);
            }
        })
    };

    return (
        <StreamingContext.Provider
            value={{
                connectionStatus,
                setConnectionStatus,
                CheckConnectionCallback,
            }}>
            {children}
        </StreamingContext.Provider>
    );
};