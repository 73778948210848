import { FansInfoBox } from "../../blocks/FansInfoBox/FansInfoBox";
import { ReferralBox } from "../../blocks/ReferralBox/ReferralBox";
import { TopFansBox } from "../../blocks/TopFansBox/TopFansBox";
import "./Fans.css";

export const Fans = () => {
    return (
        <div className="fans-section">
            <FansInfoBox />
            <TopFansBox />
            <ReferralBox />
        </div>
    );
};