import PropTypes from "prop-types";
import React from "react";
import "./NewChatButton.css";
import { Icon, ICONS } from "../../Icons/Icon";
import { LoaderBox } from "../../../blocks/LoaderBox/LoaderBox";

export const NewChatButton = ({ onClick, loading, buttonType }) => {
    return (
        <button onClick={onClick} className="new-chat-button">
            {loading ? (
                <LoaderBox backgroundColor={false} box={false}  height="32" width="32" type="spinner"/>
            ) : (
                <>
                    {buttonType === "newChat" && <Icon iconType={ICONS.PencilSimple} width="32" height="32"/>}
                    {buttonType === "back" && <Icon iconType={ICONS.ArrowBendDownLeft} width="32" height="32"/>}
                </>
            )}
        </button>
    );
};

NewChatButton.propTypes = {
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    buttonType: PropTypes.string,
};

NewChatButton.defaultProps = {
    onClick: () => {},
    loading: false,
    buttonType: "newChat",
}

