import PropTypes from "prop-types";
import React from "react";
import "./ListButton.css";
import { Icon, ICONS } from "../../Icons/Icon";

export const ListButton = ({ onClick, disabled }) => {
    return (
        <button onClick={onClick} disabled={disabled} className={`list-button ${disabled ? "disable" : ""}`}>
            <Icon iconType={ICONS.List} width="32" height="32"/>
        </button>
    );
};

ListButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

ListButton.defaultProps = {
    onClick: () => {},
    disabled: false,
}

