import { Outlet } from "react-router-dom";
import { useContext, useEffect } from "react";
import { StreamingConnect } from "../blocks/StreamingConnect/StreamingConnect";
import { LoaderBox } from "../blocks/LoaderBox/LoaderBox";
import { StreamingContext } from "../contexts/StreamingContext";

export const RequireStreamingConnect = () => {
    const { connectionStatus, CheckConnectionCallback, setConnectionStatus } = useContext(StreamingContext);

    useEffect(() => {
        if (!connectionStatus) {
            const streamingStatus = localStorage.getItem("streamingStatus");
            if (streamingStatus) {
                setConnectionStatus(streamingStatus);
                localStorage.removeItem("streamingStatus");
            } else {
                CheckConnectionCallback();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {connectionStatus === "connected" && <Outlet />}
            {connectionStatus === "disconnected" && <StreamingConnect state="connect" />}
            {connectionStatus === "pending" && <StreamingConnect state="pending" />}
            {connectionStatus === "failure" && <StreamingConnect state="failure" />}
            {connectionStatus === "forbidden" && <StreamingConnect state="forbidden" />}
            {connectionStatus === "fetching" && <LoaderBox />}
        </>
    );
};