import { useState } from "react";
import { ValidateEmailMutation } from "../../queries/sigmundApi";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import "./SignUpBox.css";
import { useSignUpQuery } from "../../hooks/QueryHooks/useSignUpQuery";
import { AnimatePresence, motion } from "framer-motion";


const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;

export const SecondStage = ({ formData, setFormData, onComplete, onBack }) => {
    const [invalidInput, setInvalidInput] = useState({});
    const [validationAlert, setValidationAlert] = useState(null);
    const validateEmailMutation = ValidateEmailMutation();
    const { status, signUpQuery } = useSignUpQuery();

    const validateEmail = (email) => {
        if (email) {
            validateEmailMutation.mutate(email, {
                onSuccess: async (data) => {
                    const response = await data.json();
                    if (response === "claimed") {
                        setValidationAlert("This email is already linked");
                        setInvalidInput({ ...invalidInput, "email": true });
                    } else if (response === "available") {
                        setValidationAlert(null);
                        setInvalidInput({ ...invalidInput, "email": false });
                    }
                }
            })
        }
    };

    const validatePassword = (passwordValue) => {
        if (!passwordRegex.test(passwordValue)) {
            setInvalidInput({ ...invalidInput, password: true });
            setValidationAlert("Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one digit, and one special character");
        } else if (invalidInput.password) {
            setInvalidInput({ ...invalidInput, password: false });
            setValidationAlert("");
        }
    };

    const handleChange = (item) => {
        setFormData({
            ...formData,
            [item.target.id]: item.target.value
        })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        validatePassword(formData.password);
        if (!formData.email) {
            setInvalidInput({ ...invalidInput, email: true });
        } else if (!formData.password) {
            setInvalidInput({ ...invalidInput, password: true });
        } else if (Object.values(invalidInput).every(item => item === false) && !validationAlert && !validateEmailMutation.isPending) {
            signUpQuery(formData, onComplete);
        }
    }

    return (
        <form className="sign-up-box" onSubmit={handleSubmit}>
            <div className="form">
                <div className="div">
                    <label htmlFor="email" className="input-label">Email</label>
                    <input
                        id="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={() => validateEmail(formData.email)}
                        className={`text-box-wrapper ${invalidInput.email ? "invalid" : ""}`}
                        placeholder="Enter your email address"
                        autoComplete="off" />
                </div>
                <div className="div">
                    <label htmlFor="password" className="input-label">Password</label>
                    <input
                        id="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        onBlur={() => validatePassword(formData.password)}
                        className={`text-box-wrapper ${invalidInput.password ? "invalid" : ""}`}
                        placeholder="Enter your password"
                        autoComplete="off" />
                </div>
            </div>
            <PrimaryButton type="submit" disabled={status === "fetching" || status === "success"}>Sign Up</PrimaryButton>
            <div className="alternative">
                <AnimatePresence>
                    {validationAlert &&
                        <motion.div
                            style={{ overflow: "hidden" }}
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            exit={{ height: 0, opacity: 0 }}
                            className="alert"
                        >
                            {validationAlert}
                        </motion.div>
                    }
                </AnimatePresence>
                <div className="default" onClick={() => {
                    if (invalidInput.email) {
                        setFormData({ ...formData, email: "" });
                    }
                    if (invalidInput.password) {
                        setFormData({ ...formData, password: "" });
                    }
                    onBack();
                }}>
                    <span className="previous">Back to previous step</span>
                </div>
            </div>
        </form>
    );
};