import PropTypes from "prop-types";
import React from "react";
import { Icon, ICONS } from "../../Icons/Icon";
import { motion } from "framer-motion";
import "./MinusButton.css";

export const MinusButton = ({ onClick, disabled, type }) => {
    return (
        <motion.div
            key="minus-button"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            <button
                onClick={onClick}
                disabled={disabled}
                className={`minus-button ${disabled ? "disabled" : ""}`}
            >
                {type === "circle" && <Icon iconType={ICONS.MinusCircle} width="32" height="32" />}
            </button>
        </motion.div>
    );
};

MinusButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(["circle"]),
};

MinusButton.defaultProps = {
    onClick: () => { },
    disabled: false,
    type: "circle",
}

