import { useContext, useEffect, useState } from "react";
import { SigmundContext } from "../contexts/SigmundContext";
import { endpoints } from "../queries/endpoins";
import useWebSocket from 'react-use-websocket';
import { useQueryClient } from "@tanstack/react-query";
import { useMessengerQuery } from "./QueryHooks/useMessengerQuery";
import { useNavigate } from "react-router-dom";
import { absolutePaths } from "../routes";

export const useMessenger = () => {
    const queryClient = useQueryClient();
    const [isSending, setIsSending] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const { currentProfile } = useContext(SigmundContext);
    const { chatsQuery, messagesQuery } = useMessengerQuery();
    const [isUpdated, setIsUpdated] = useState(false);
    const navigate = useNavigate();

    const actionsSocketUrl = `${endpoints.wsMessengerActions}`;
    const params = {
        profile_token: currentProfile.artist_id,
    };

    const { sendJsonMessage, lastMessage } = useWebSocket(actionsSocketUrl, {
        queryParams: params,
        retryOnError: true,
        heartbeat: {
            message: '{"action_type": "ping"}',
            timeout: 180000,
            interval: 90000,
        },
        shouldReconnect: (closeEvent) => true,
        reconnectAttempts: 10,
        reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
        share: true,
    });

    const readNewMessages = (chatToken, tokens) => {
        const messagesIdx = messagesQuery.data.findIndex(item => item.chat_token === chatToken);

        let chatMessages = messagesQuery.data[messagesIdx];
        if (chatMessages.messages) {
            const updatedMessages = chatMessages.messages.map(msg => (msg.new ? { ...msg, new: false } : msg));
            chatMessages.messages = updatedMessages;

            let oldChatMessages = [...messagesQuery.data];
            oldChatMessages[messagesIdx] = chatMessages;

            const chats = [...chatsQuery.data];
            const chatIdx = chats.findIndex(item => item.chat_token === chatToken);
            chats[chatIdx].unread_count -= tokens.length;

            queryClient.setQueryData(["chatMessages"], () => oldChatMessages);
            queryClient.setQueryData(["chats", currentProfile.artist_id], () => chats);

            chatsQuery.refetch();
            messagesQuery.refetch();
            setIsUpdated(prev => !prev);

            sendJsonMessage({
                action_type: "read_new_messages",
                content: {
                    chat_token: chatToken,
                    messages: tokens,
                },
            });
        }
    };

    const createPrivateChat = (contactToken) => {
        setIsCreating(true);
        sendJsonMessage({
            action_type: "create_private_chat",
            content: contactToken,
        });
    }

    const sendNewMessage = (chatToken, content) => {
        setIsSending(true);
        sendJsonMessage({
            action_type: "send_new_message",
            content: {
                chat_token: chatToken,
                content,
            }
        });
    };

    const loadMessageHistory = (chatToken) => {
        const data = messagesQuery.data;
        const chatIdx = data.findIndex(item => item.chat_token === chatToken);
        if (chatIdx !== -1) {
            const chat = data[chatIdx];
            if (chat.history_message_id) {
                setIsLoading(true);
                sendJsonMessage({
                    action_type: "history",
                    content: {
                        chat_token: chatToken,
                        message_id: chat.history_message_id,
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (lastMessage?.data) {
            const data = JSON.parse(lastMessage.data);
            if (data?.action_type === "messages") {
                let oldChatMessages = messagesQuery.data;
                const chats = [...chatsQuery.data];
                data?.content?.forEach((chat) => {
                    const chatIdx = oldChatMessages.findIndex(item => item.chat_token === chat.chat_token);
                    const last_message_id = Number(chat.last_message_id.split("-")[0]);
                    let newMessages = null;

                    if (chatIdx === -1) {
                        oldChatMessages = [
                            ...oldChatMessages,
                            {
                                chat_token: chat.chat_token,
                                history_message_id: chat.history_message_id,
                                last_message_id: last_message_id,
                                messages: chat.messages,
                            }
                        ];
                    } else {
                        const oldChat = oldChatMessages[chatIdx];
                        const oldMessageTokens = oldChat.messages.map(msg => msg.message_token);
                        newMessages = chat.messages.filter(msg => !oldMessageTokens.includes(msg.message_token));

                        if (newMessages) {
                            if (oldChat.last_message_id < last_message_id) {
                                const updatedChat = {
                                    ...oldChat,
                                    last_message_id,
                                    messages: [...oldChat.messages, ...newMessages],
                                };
                                oldChatMessages[chatIdx] = updatedChat;
                            }
                        }
                    }

                    if (newMessages) {
                        const idx = chats.findIndex(item => item.chat_token === chat.chat_token);
                        if (idx !== -1 && newMessages[newMessages.length - 1]) {
                            chats[idx].last_message = newMessages[newMessages.length - 1].content;
                            const externalMessages = newMessages.filter(msg => msg.profile_token !== currentProfile.artist_id && msg.new);
                            chats[idx].unread_count += externalMessages.length;
                            const chatItem = chats.splice(idx, 1)[0];
                            chats.unshift(chatItem);
                        }
                    }
                });
                queryClient.setQueryData(["chatMessages"], () => oldChatMessages);
                queryClient.setQueryData(["chats", currentProfile.artist_id], () => chats);
                chatsQuery.refetch();
                messagesQuery.refetch();
                setIsUpdated(prev => !prev);
            } else if (data?.action_type === "messagesHistory") {
                let oldChatMessages = messagesQuery.data;
                data?.content?.forEach((chat) => {
                    const chatIdx = oldChatMessages.findIndex(item => item.chat_token === chat.chat_token);
                    if (chatIdx !== -1) {
                        const oldChat = oldChatMessages[chatIdx];
                        const oldMessageTokens = oldChat.messages.map(msg => msg.message_token);
                        const newMessages = chat.messages.filter(msg => !oldMessageTokens.includes(msg.message_token));

                        if (newMessages) {
                            const updatedChat = {
                                ...oldChat,
                                history_message_id: chat.history_message_id,
                                messages: [...newMessages, ...oldChat.messages],
                            };
                            oldChatMessages[chatIdx] = updatedChat;
                        }
                    }
                });
                queryClient.setQueryData(["chatMessages"], () => oldChatMessages);
                messagesQuery.refetch();
                setIsLoading(false);
                setIsUpdated(prev => !prev);
            } else if (data?.action_type === "chats") {
                let oldChats = chatsQuery.data;
                if (!oldChats || oldChats.length === 0) {
                    oldChats = [];
                }
                const existingTokens = new Set(oldChats?.map(chat => chat.chat_token));
                const filteredNewChats = data.content.filter(chat => !existingTokens.has(chat.chat_token));

                if (filteredNewChats.length >= 0) {
                    queryClient.setQueryData(["chats", currentProfile.artist_id], () => ([
                        ...filteredNewChats,
                        ...oldChats,
                    ]));
                    chatsQuery.refetch();
                    setIsUpdated(prev => !prev);
                }
            } else if (data?.action_type === "read_messages") {
                data.content.forEach(readItem => {
                    const chatIdx = messagesQuery.data.findIndex(item => item.chat_token === readItem.chat_token);
                    let chatMessages = messagesQuery.data[chatIdx];
                    if (chatMessages.messages) {
                        const updatedMessages = chatMessages.messages.map(msg => (
                            readItem.messages.includes(msg.message_token) ? { ...msg, read: true } : msg));
                        chatMessages.messages = updatedMessages;

                        let oldChatMessages = [...messagesQuery.data];
                        oldChatMessages[chatIdx] = chatMessages;

                        queryClient.setQueryData(["chatMessages"], () => oldChatMessages);
                        messagesQuery.refetch();
                        setIsUpdated(prev => !prev);
                    }

                });
            } else if (data?.status === "delivered") {
                setIsSending(false);
            } else if (data?.status === "created") {
                const newChatToken = data.marker;
                navigate(`${absolutePaths.messenger}/${newChatToken}`, { replace: true });
                setIsCreating(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage]);

    return {
        messagesQuery,
        chatsQuery,
        sendNewMessage,
        createPrivateChat,
        isSending,
        isUpdated,
        isCreating,
        isLoading,
        readNewMessages,
        loadMessageHistory,
    };
};

