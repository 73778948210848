import "./MessengerBox.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MessengerTopSection } from "../MessengerTopSection/MessengerTopSection";
import { SendButton } from "../../components/Buttons/SendButton/SendButton";
import { CheckBox } from "../../components/CheckBox/CheckBox";
import { useParams } from "react-router-dom";
import { SigmundContext } from "../../contexts/SigmundContext";
import { useMessenger } from "../../hooks/useMessenger";
import { MessageCard } from "../../components/Cards/MessageCard/MessageCard";
import { LoaderBox } from "../LoaderBox/LoaderBox";

export const MessengerBox = () => {
    const params = useParams();
    const { currentProfile } = useContext(SigmundContext);
    const [currentChat, setCurrentChat] = useState(null);
    const [messages, setMessages] = useState(null);
    const [message, setMessage] = useState("");
    const [actionMode,] = useState(false);
    const [, setSeletion] = useState([]);
    const {
        sendNewMessage, 
        isSending, 
        isUpdated, 
        isLoading,
        messagesQuery, 
        chatsQuery, 
        readNewMessages,
        loadMessageHistory } = useMessenger();

    const messangerScrollRef = useRef(null);
    const messagesEndRef = useRef(null);
    const newMessagesRefs = useRef([]);
    const textInputRef = useRef(null);

    const [isSent, setIsSent] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (messages?.length > 0 && !isLoaded) {
            const targetMessages = newMessagesRefs.current.filter(msg => msg.messageRef);
            if (targetMessages.length > 0) {
                scrollTo({ current: targetMessages[targetMessages.length - 1].messageRef }, "instant");
            } else {
                if (messangerScrollRef.current) {
                    messangerScrollRef.current.scrollTop = 0;
                }
            }
            setIsLoaded(true);
        }
        if (messangerScrollRef?.current) {
            const scrollTop = messangerScrollRef.current.scrollTop;
            if (-scrollTop <= 1000) {
                scrollTo(messagesEndRef);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);

    useEffect(() => {
        const handleScroll = () => {
            const container = messangerScrollRef.current;

            if (!isLoading) {
                const scrollTop = messangerScrollRef.current.scrollTop;
                const scrollHeight = messangerScrollRef.current.scrollHeight - messangerScrollRef.current.clientHeight;
                const currentScrollPercentage = (scrollTop / scrollHeight) * -100;
                if ([70, 99].includes(Math.round(currentScrollPercentage))) {
                    loadMessageHistory(currentChat.chat_token);
                }
            }

            const { top, bottom } = container.getBoundingClientRect();

            const readMessages = [];
            newMessagesRefs.current.forEach((message) => {
                if (message.messageRef) {
                    const messageTop = message.messageRef.getBoundingClientRect().top;
                    const isVisible = messageTop >= top && messageTop <= bottom;
                    if (isVisible) {
                        readMessages.push(message.messageToken);
                    }
                }
            });

            if (readMessages.length > 0) {
                newMessagesRefs.current = newMessagesRefs.current.filter(msg => !readMessages.includes(msg.messageToken));
                readNewMessages(currentChat.chat_token, readMessages);
            }
        };

        const msr = messangerScrollRef?.current; 
        if (msr) {
            handleScroll();
            msr.addEventListener('scroll', handleScroll);
            return () => {
                msr.removeEventListener('scroll', handleScroll);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages, isLoading, currentChat]);

    useEffect(() => {
        if (currentChat?.chat_token) {
            const chatIdx = messagesQuery.data.findIndex(item => item.chat_token === currentChat.chat_token);
            const newMessages = messagesQuery.data[chatIdx]?.messages;
            if (newMessages) {
                setMessages([...newMessages].reverse());
            } else {
                setMessages([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated, currentChat]);

    useEffect(() => {
        if (chatsQuery.data && chatsQuery.data.length > 0) {
            const chatIdx = chatsQuery.data.findIndex(item => item.chat_token === params.chatToken);
            if (chatIdx !== -1) {
                setCurrentChat(chatsQuery.data[chatIdx]);
                newMessagesRefs.current = [];
            }
            setIsLoaded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.chatToken]);

    useEffect(() => {
        if (!isSending && isSent) {
            scrollTo(messagesEndRef);
            localStorage.removeItem(`draft_message_${currentChat.chat_token}`);
            setIsSent(false);
            if (textInputRef) {
                textInputRef.current.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSending]);

    const handleTyping = (e) => {
        setMessage(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'Enter') {
            e.preventDefault();
            setMessage(prev => prev + "\n");
        } else if (e.key === "Enter") {
            e.preventDefault();
            handleSend();
        }
    };

    const scrollTo = (targetRef, behavior = "smooth") => {
        targetRef.current?.scrollIntoView({
            behavior,
        });
    };

    const handleSelection = (check, messageToken) => {
        if (check) {
            setSeletion(prev => [...prev, messageToken]);
        } else {
            setSeletion(prev => prev.filter((item) => item !== messageToken))
        }
    };

    const handleSend = () => {
        if (message.length > 0) {
            localStorage.setItem(`draft_message_${currentChat.chat_token}`, message);
            setMessage("");
            sendNewMessage(currentChat.chat_token, message);
            setIsSent(true);
            if (textInputRef) {
                textInputRef.current.style.height = 'inherit';
            }
        }
    };

    const autoGrow = (event) => {
        event.target.style.height = 'inherit';
        event.target.style.height = `${event.target.scrollHeight}px`;
    };

    return (
        <>
            {params.chatToken &&
                <div className="messenger-box">
                    <MessengerTopSection
                        chatName={currentChat?.chat_name}
                        chatImage={currentChat?.chat_image}
                        chatType={currentChat?.chat_members[1].contact_type}
                        actions={actionMode}
                    />
                    {messages ? (
                        messages?.length > 0 ? (
                            <div className="message-box" ref={messangerScrollRef}>
                                <div ref={messagesEndRef} />
                                {messages.map((message, idx, arr) =>
                                    <div
                                        ref={el => (message.new && newMessagesRefs.current.findIndex(item =>
                                            item.messageToken === message.message_token) === -1) ? newMessagesRefs.current.push({
                                                messageToken: message.message_token,
                                                messageRef: el,
                                            }) : null}
                                        key={message.message_token}
                                        className={`message-section ${arr[idx + 1]?.profile_token === message.profile_token ? "stacked" : ""}`}
                                    >
                                        {actionMode &&
                                            <div className="selection">
                                                <CheckBox onCheck={(check) => handleSelection(check, message.message_token)} styleType="circle" />
                                            </div>
                                        }
                                        <div className={`message-card-wrapper ${message.profile_token === currentProfile.artist_id ? "right" : "left"}`}>
                                            <MessageCard
                                                messageInfo={message}
                                                type={message.profile_token === currentProfile.artist_id ? "right" : "left"}
                                                stacked={arr[idx - 1]?.profile_token === message.profile_token}
                                            />
                                        </div>
                                    </div>
                                )}
                                {isLoading && 
                                    <div className="loading-box">
                                        <LoaderBox backgroundColor={false} width="32" height="32" />
                                    </div>
                                }
                            </div>
                        ) : (
                            <div className="empty-box">
                            </div>
                        )
                    ) : (
                        <div className="empty-box">
                            <LoaderBox backgroundColor={false} />
                        </div>
                    )}
                    <div className="bottom-section">
                        <div className="sender">
                            <textarea
                                className="text-box"
                                placeholder="Message"
                                value={message}
                                rows="1"
                                maxLength="4096"
                                onChange={handleTyping}
                                onKeyDown={handleKeyDown}
                                onInput={autoGrow}
                                ref={textInputRef}
                            />
                            <SendButton onClick={handleSend} loading={isSending} />
                        </div>
                    </div>
                </div>
            }</>
    );
};