import PropTypes from "prop-types";
import React, { useState } from "react";
import "./Counter.css";
import { ArrowButton } from "../Buttons/ArrowButton/ArrowButton";

export const Counter = ({ type, limitValue, count, onChange, disableIncr, disableDecr }) => {
    const [limit, setLimit] = useState({
        bottomLimit: true,
        upperLimit: false,
    });

    const incrCounter = (value) => {
        if (count + value * 2 < 0) {
            setLimit(prev => ({ ...prev, bottomLimit: true }));
        } else {
            setLimit(prev => ({ ...prev, bottomLimit: false }));
        }
        if (count + value * 2 > limitValue) {
            setLimit(prev => ({ ...prev, upperLimit: true }));
        } else {
            setLimit(prev => ({ ...prev, upperLimit: false }));
        }
        onChange(value);
    };

    return (
        <div className={`counter ${type}`}>
            <input
                readOnly
                type="number"
                value={count}
                className="number-box counter-text-wrapper" />
            <div className="buttons">
                <ArrowButton type="up" onClick={() => incrCounter(1)} disabled={limit.upperLimit || disableIncr} />
                <ArrowButton type="down" onClick={() => incrCounter(-1)} disabled={limit.bottomLimit || disableDecr} />
            </div>
        </div>
    );
};

Counter.defaultProps = {
    type: "desktop",
    limitValue: 20,
    disableIncr: false,
    disableDecr: false,
};

Counter.propTypes = {
    type: PropTypes.oneOf(["desktop", "mobile"]),
    limitValue: PropTypes.number,
    disableIncr: PropTypes.bool,
    disableDecr: PropTypes.bool,
};