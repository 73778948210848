import React from "react";
import { ClipboardButton } from "../../components/Buttons/ClipboardButton/ClipboardButton";
import "./ReferralBox.css";
import { useReferralUrlQuery } from "../../hooks/QueryHooks/useReferralUrl";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { Tooltip } from "react-tooltip";

export const ReferralBox = () => {
    const { referralUrl } = useReferralUrlQuery();
    const url = referralUrl?.invitation_url ? referralUrl?.invitation_url : "You don't have a invitation link yet";

    return (
        <div className="referral-box">
            {referralUrl ? (
                <>
                    <div className="box-title">Invitation link</div>
                    <div className="url-section">
                        <div className="url-box">
                            <div className="url-wrapper">{url}</div>
                        </div>
                        <ClipboardButton onClick={() => navigator.clipboard.writeText(url)} />
                        <Tooltip
                            anchorSelect=".clipboard-button"
                            place="bottom-center"
                            className="tool-tip-style"
                            noArrow={true}
                            openOnClick={true}
                            closeEvents={{ mouseleave: true, click: true, blur: true }}
                            globalCloseEvents={{ scroll: true }}
                            delayHide={500}
                        >
                            <div className="tip-text-wrapper">Copied</div>
                        </Tooltip>
                    </div>
                </>
            ) : (
                <LoaderBox backgroundColor={false} />
            )}
        </div>
    );
};