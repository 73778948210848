import { useContext, useEffect, useState } from "react";
import { ValidateInvitationMutation, ValidateUsernameMutation } from "../../queries/sigmundApi";
import { absolutePaths } from "../../routes";
import { DropDown } from "../../components/DropDown/DropDown";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { Link, useSearchParams } from "react-router-dom";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { motion, AnimatePresence } from "framer-motion";
import { ClaimArtistCard } from "../../components/Cards/ClaimArtistCard/ClaimArtistCard";
import DefaultUserImg from "../../assets/Images/Anonymus.png";
import "./SignUpBox.css";


const usernameRegex = /^[a-zA-Z0-9-_]+$/;

export const FirstStage = ({ formData, setFormData, onComplete }) => {
    const [invalidInput, setInvalidInput] = useState({});
    const [validationAlert, setValidationAlert] = useState(null);
    const validateUsernameMutation = ValidateUsernameMutation();
    const validateInvitation = ValidateInvitationMutation();
    const { isExtremeSmallMobile } = useContext(ResponsiveContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const invitationToken = searchParams.get("invitationToken");
    const [invitation, setInvitation] = useState(formData.invitation);

    useEffect(() => {
        if (invitationToken) {
            validateInvitation.mutate(invitationToken, {
                onSuccess: async (data) => {
                    const response = await data.json();
                    setInvitation(response);
                },
                onError: () => {
                    setInvitation(null);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitationToken]);

    const validateUsername = (username) => {
        if (username) {
            if (!usernameRegex.test(username)) {
                setValidationAlert("The username can only contain upper and lower case latin characters, numbers, _ and -");
                setInvalidInput({ ...invalidInput, "username": true });
                return;
            } else {
                setValidationAlert(null);
                setInvalidInput({ ...invalidInput, "username": false });
            }

            validateUsernameMutation.mutate(username, {
                onSuccess: async (data) => {
                    const response = await data.json();
                    if (response === "claimed") {
                        setValidationAlert("This username is already taken");
                        setInvalidInput({ ...invalidInput, "username": true });
                    } else if (response === "available") {
                        setValidationAlert(null);
                        setInvalidInput({ ...invalidInput, "username": false });
                    }
                }
            });
        }
    };

    const handleChange = (item) => {
        setFormData({
            ...formData,
            [item.target.id]: item.target.value
        })
    };

    const handleDropdownSelect = (option) => {
        setInvalidInput({ ...invalidInput, role: false });
        setFormData({
            ...formData,
            role: option
        })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!formData.username) {
            setInvalidInput({ ...invalidInput, username: true });
        } else if (!formData.role) {
            setInvalidInput({ ...invalidInput, role: true });
        } else if (Object.values(invalidInput).every(item => item === false) && !validationAlert && !validateUsernameMutation.isPending) {
            if (invitation) {
                setFormData({
                    ...formData,
                    invitationToken: invitationToken,
                    invitation,
                });
            }
            onComplete();
        }
    }


    return (
        <form className="sign-up-box" onSubmit={handleSubmit}>
            {invitation &&
                <div className="div">
                    <div className="input-label">You were invited by</div>
                    <ClaimArtistCard
                        artistName={invitation.artist_name}
                        aritstImage={invitation.artist_image ? invitation.artist_image : DefaultUserImg}
                        approved={true}
                        disable_approve_icon={true}
                    />
                </div>
            }
            <div className="form">
                <div className="div">
                    <label htmlFor="username" className="input-label">Username</label>
                    <input
                        id="username"
                        type="text"
                        value={formData.username}
                        onChange={handleChange}
                        onBlur={() => validateUsername(formData.username)}
                        className={`text-box-wrapper ${invalidInput.username ? "invalid" : ""}`}
                        placeholder="Enter your username" />
                </div>
                <div className="div">
                    <label htmlFor="dropdown" className="input-label">Role</label>
                    <DropDown
                        options={["Artist", "Fan"]}
                        placeHolder="Choose a role"
                        onSelect={handleDropdownSelect}
                        defaultOption={formData.role}
                        invalid={invalidInput.role} />
                </div>
            </div>
            <PrimaryButton type="submit">Next</PrimaryButton>
            <div className="alternative">
                <AnimatePresence>
                    {validationAlert &&
                        <motion.div
                            style={{ overflow: "hidden" }}
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            exit={{ height: 0, opacity: 0 }}
                            className="alert"
                        >
                            {validationAlert}
                        </motion.div>
                    }
                </AnimatePresence>
                <div className="default">
                    Already have an account?{isExtremeSmallMobile ? <br /> : " "}<Link to={absolutePaths.singIn} className="link">Sign in here</Link>
                    {invitation &&
                        <>
                            <br />The artist didn't invite you?<br />
                            <div onClick={() => {
                                setInvitation(null);
                                searchParams.delete("invitationToken");
                                setSearchParams(searchParams);
                            }} className="link">
                                Decline invitation
                            </div>
                        </>
                    }
                </div>
            </div>
        </form>
    )
};