import "./ClaimBox.css";
import React, { useContext, useState } from "react";
import { ClaimArtistCard } from "../../components/Cards/ClaimArtistCard/ClaimArtistCard";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { useClaimStatusQuery } from "../../hooks/QueryHooks/useClaimStatusQuery";
import { useClaimArtistQuery } from "../../hooks/QueryHooks/useClaimArtistQuery";
import { useNavigate } from "react-router-dom";
import { absolutePaths } from "../../routes";
import { AnimatePresence } from "framer-motion";
import { SigmundContext } from "../../contexts/SigmundContext";

export const ClaimBox = ({ onBack = null }) => {
    const [artistLink, setArtistLink] = useState("");
    const [invalidInput, setInvalidInput] = useState(false);
    const { SigmundStatusCallback } = useContext(SigmundContext);
    const navigate = useNavigate();
    const { artistInfo, claimStatusQuery, queryMutation: statusQueryMutation } = useClaimStatusQuery();
    const { claimArtistQuery, queryMutation: artistQueryMutation } = useClaimArtistQuery();

    const handleChange = (item) => {
        setInvalidInput(false);
        setArtistLink(item.target.value);
        claimStatusQuery(item.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!artistLink) {
            setInvalidInput(true);
        } else if (!invalidInput) {
            claimArtistQuery(artistLink, () => {
                SigmundStatusCallback();
                navigate(absolutePaths.dashboard);
            });
        }
    };

    return (
        <div className="claim-box">
            <form className="form" onSubmit={handleSubmit}>
                <div className="div">
                    <label htmlFor="artistLink" className="input-label">Artist spotify link</label>
                    <input
                        id="artistLink"
                        type="text"
                        value={artistLink}
                        onChange={handleChange}
                        className={`text-box-wrapper ${invalidInput ? "invalid" : ""}`}
                        placeholder="Paste a spotify artist link" />
                </div>
                <div className="separate-section">
                    <AnimatePresence>
                        {artistInfo.artist_name &&
                            <ClaimArtistCard
                                key="claimArtistCard"
                                approved={artistInfo.claim_status === "available"}
                                artistName={artistInfo.artist_name}
                                aritstImage={artistInfo.artist_image} />
                        }
                    </AnimatePresence>
                </div>
                <PrimaryButton type="submit" disabled={artistInfo.claim_status !== "available" || artistQueryMutation.isPending}>
                    {artistInfo.claim_status === "available" ? "Claim artist" :
                        artistInfo.claim_status === "claimed" ? "Artist claimed" :
                            statusQueryMutation.isPending ? "Searching..." : "Not found"}
                </PrimaryButton>
            </form>
            {onBack && 
                <div className="default" onClick={onBack}>
                    Go back
                </div>
            }
        </div>
    );
};
