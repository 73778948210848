export const relativePaths = {
    singIn: "sing-in",
    singUp: "sign-up",
    claimArtist: "claim-artist",
    resetPassword: "password-reset",
    forgotPassword: "forgot-password",
    confirmEmail: "confirm-email",
    terms: "terms",
    policy: "policy",
    contactUs: "contact-us",
    dashboard: "dashboard",
    spotify: "spotify",
    tracks: "tracks",
    fans: "fans",
    generalStats: "general-stats",
    trackStats: "track-stats",
    messenger: "messenger",
    music: "music",
    friends: "friends",
    faq: "faq",
    adminConsole: "admin-console",
    updateUsername: "update-username",
};

export const absolutePaths = {
    singIn: "/",
    singUp: "/sign-up",
    claimArtist: "/claim-artist",
    confirmEmail: "/confirm-email",
    updateUsername: "/update-username",
    resetPassword: "/auth/password-reset",
    forgotPassword: "/auth/forgot-password",
    terms: "/terms",
    policy: "/policy",
    contactUs: "/contact-us",
    dashboard: "/app/dashboard",
    spotify: "/app/spotify",
    tracks: "/app/tracks",
    fans: "/app/fans",
    generalStats: "/app/general-stats",
    trackStats: "/app/track-stats",
    messenger: "/app/messenger",
    music: "/app/music",
    friends: "/app/friends",
    adminConsole: "/app/admin-console",
    faq: "/faq",
};