import PropTypes from "prop-types";
import React from "react";
import "./PrimaryButton.css";
import { Icon } from "../../Icons/Icon";

export const PrimaryButton = ({ onClick, children, disabled, type, icon, disableStyle, buttonStyle }) => {
  return (
    <button onClick={onClick} type={type} disabled={disabled} className={`primary-button ${buttonStyle} ${disableStyle}`}>
      <div className="button-text-wrapper">{children}</div>
      {icon && <Icon iconType={icon} width="32" height="32" />}
    </button>
  );
};

PrimaryButton.defaultProps = {
  onClick: () => { },
  icon: null,
  disableStyle: "outline",
  disabled: false,
  buttonStyle: "filled",
};

PrimaryButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  icon: PropTypes.any,
  disableStyle: PropTypes.oneOf(["fade", "outline", "info"]),
  buttonStyle: PropTypes.oneOf(["filled", "unfilled"]),
};