import PropTypes from "prop-types";
import React from "react";
import { ICONS, Icon } from "../../components/Icons/Icon";
import { useBroadcastingStatusQuery } from "../../hooks/QueryHooks/useBroadcastingStatus";
import "./BroadcastingBox.css";

export const BroadcastingBox = () => {
    const broadcastingStatus = useBroadcastingStatusQuery();

    return (
        <div className="broadcasting-box">
            <div className="frame">
                <div className="label-section">
                    <div className="label-text-wrapper">Broadcasting device</div>
                    {broadcastingStatus.device_name && <div className="device">{broadcastingStatus.device_name}</div>}
                </div>
                <div className="section">
                    {broadcastingStatus.status ? (
                        <>
                            <div className="left-section">
                                <div className="text-wrapper">On-Air</div>
                                <Icon iconType={ICONS.AirplaneTakeoff} width="32" height="32" />
                            </div>
                            {broadcastingStatus.last_hour_broadcasting > 0 &&
                                <div className="text-wrapper">{Math.round(broadcastingStatus.last_hour_broadcasting * 60)}m in the last hour</div>
                            }
                        </>
                    ) : (
                        <div className="left-section">
                            <div className="text-wrapper">On-Ground</div>
                            <Icon iconType={ICONS.AirplaneLanding} width="32" height="32" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

BroadcastingBox.propTypes = {
    deviceName: PropTypes.string,
    broadcastingTime: PropTypes.number,
};