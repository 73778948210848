import React, { useContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import { SideMenu } from "../../blocks/SideMenu/SideMenu";
import { Footer } from "../../blocks/Footer/Footer";
import { SideSecondaryMenu } from "../../blocks/SideSecondryMenu/SideSecondaryMenu";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import "./DesktopAppLayout.css";

export const DesktopAppLayout = ({ fullHeightPage = false}) => {
  const { isSmallMobile, isMobile, isSmallTablet, isTablet, isDesktop } = useContext(ResponsiveContext);
  const params = useParams();

  return (
    <div className={`desktop-app-layout ${fullHeightPage ? "full-height" : null}`}>
      <div className="content-block">
        <div className="content">
          {isDesktop &&
            <>
              <SideMenu />
              <Outlet />
              <SideSecondaryMenu />
            </>
          }

          {isTablet &&
            <>
              <div className="compose-section">
                <SideMenu />
              </div>
              <Outlet />
              <div className="compose-section">
                <SideSecondaryMenu />
              </div>
            </>
          }


          {isSmallTablet &&
            <>
              <div className="compose-section">
                <SideSecondaryMenu />
                <SideMenu />
              </div>
              <Outlet />
            </>
          }

          {isMobile &&
            <>
              <Outlet />
              <div className="compose-section">
                <SideSecondaryMenu />
              </div>
            </>
          }

          {isSmallMobile &&
            <>
              <div className="vertical-section">
                {!(params.chatToken && !isDesktop) && 
                  <div className="compose-section">
                    <SideSecondaryMenu />
                  </div>
                }
                <Outlet />
              </div>
            </>
          }

        </div>
        {(isDesktop || isTablet) &&
          <Footer />
        }
      </div>
    </div>
  );
};