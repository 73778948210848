import { ClaimArtistMutation } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";

export const useClaimArtistQuery = () => {
    const queryErrorHandler = useQueryErrorHandler();
    const queryMutation = ClaimArtistMutation();

    const claimArtistQuery = (artistUrl, onComplete) => {
        queryMutation.mutate({ artistUrl }, {
            onSuccess: () => {
                onComplete();
            },
            onError: (error) => {
                queryErrorHandler(error);
            }
        })
    };

    return { claimArtistQuery, queryMutation };
};