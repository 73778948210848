import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { ArtistTrackCard } from "../../components/Cards/ArtistTrackCard/ArtistTrackCard"
import { SearchBar } from "../../components/SearchBar/SearchBar";
import { SigmundContext } from "../../contexts/SigmundContext";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { ArtistTracksInfiniteQuery } from "../../queries/sigmundApi";
import { ICONS, Icon } from "../../components/Icons/Icon";
import { useSeacrhTracksQuery } from "../../hooks/QueryHooks/useSearchTracks";
import { useElapsedTime } from "../../hooks/useElapsedTime";
import { useQueryClient } from "@tanstack/react-query";
import { AnimatePresence } from "framer-motion";
import { Switch } from "../../components/Switch/Switch";
import "./ArtistTracksBox.css";
import "./ArtistTracksBoxComposite.css";
import { ArtistPromotionTrackCard } from "../../components/Cards/ArtistPromotionTrackCard/ArtistPromotionTrackCard";

export const ArtistTracksBoxComposite = () => {
    const { currentProfile, profileInfo } = useContext(SigmundContext);
    const promotionInfo = profileInfo.artist_promotions[currentProfile.artist_id];
    const infiniteIdleTrackQuery = ArtistTracksInfiniteQuery("idle", currentProfile.artist_id);
    const infinitePromotionTrackQuery = ArtistTracksInfiniteQuery("in_promotion", currentProfile.artist_id);
    const queryClient = useQueryClient();
    const { search, updateCatalog, tracks, isFetching } = useSeacrhTracksQuery();
    const { elapsedTime, running, startTimer, resetTimer } = useElapsedTime();
    const [searchEvent, setSearchEvent] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [promotionLayout, setPromotionLayout] = useState(false);
    const searchRef = useRef(null);

    const switchOptions = [
        { name: "Your tracks", onClick: () => setPromotionLayout(false) },
        { name: "In promotion", onClick: () => setPromotionLayout(true) },
    ];

    useEffect(() => {
        if (searchKeyword) {
            handleSearch(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promotionLayout]);

    useEffect(() => {
        if (elapsedTime >= 1000) {
            search(searchKeyword, false);
            resetTimer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elapsedTime]);

    const handleSearch = (value) => {
        if (value) {
            startTimer();
            setSearchEvent(true);
            setSearchKeyword(value);
        } else {
            resetTimer();
            setSearchKeyword("");
            setSearchEvent(false);
            queryClient.setQueryData(['artistTracks', "idle", currentProfile.artist_id], (data) => ({
                pages: data.pages.slice(0, 1),
                pageParams: data.pageParams.slice(0, 1),
            }))
        }
    };

    const handleUpdateCatalog = () => {
        if (!isFetching) {
            updateCatalog();
        }
    };

    return (
        <div className="artist-tracks-box composite">
            <div className="box-top-section">
                <Switch options={switchOptions} defaultOption={switchOptions[0].name} />
                <SearchBar onSearch={handleSearch} inputRef={searchRef} />
            </div>
            <div className="cards-section">
                <hr className="separator-line" />
                <div className="section">
                    {!promotionLayout &&
                        <div className="track-box" style={searchEvent ? { display: "none" } : null}>
                            {infiniteIdleTrackQuery.status === 'pending' ? (
                                <div className="empty">
                                    <LoaderBox backgroundColor={false} />
                                </div>
                            ) : (infiniteIdleTrackQuery?.data?.pages[0].tracks.length > 0) ? (
                                infiniteIdleTrackQuery.data.pages.map((group, i) => (
                                    <React.Fragment key={i}>
                                        <AnimatePresence>
                                            {group.tracks?.map((item) =>
                                                <ArtistTrackCard
                                                    key={item.track_id}
                                                    trackInfo={item}
                                                    artistId={currentProfile.artist_id}
                                                    disableHover={true}
                                                    type={item.duration > promotionInfo.duration_limit ? "warning" : (
                                                        item.duration > promotionInfo.free_window ? "prohibit" : "approved"
                                                    )}
                                                />
                                            )}
                                        </AnimatePresence>
                                    </React.Fragment>
                                ))
                            ) : (
                                <div className="empty">
                                    <Icon iconType={ICONS.VinylRecord} width="80" height="80" />
                                    {infinitePromotionTrackQuery?.data?.pages[0].tracks.length > 0 ? (
                                        <div className="note-box">
                                            <div className="subtitle">All your tracks are in promotion</div>
                                            <div className="note">Use the search box if you're looking for a specific track</div>
                                        </div>
                                    ) : (
                                        <div className="note-box">
                                            <div className="subtitle">We couldn't find your tracks</div>
                                            <div className="note">Use the search box if you're looking for a specific track</div>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className="end-card">
                                {!infiniteIdleTrackQuery.isFetchingNextPage &&
                                    !infiniteIdleTrackQuery.isFetching &&
                                    infiniteIdleTrackQuery.hasNextPage &&
                                    <PrimaryButton onClick={infiniteIdleTrackQuery.fetchNextPage}>Load More</PrimaryButton>
                                }

                                {!infiniteIdleTrackQuery.isFetchingNextPage &&
                                    !infiniteIdleTrackQuery.isFetching &&
                                    !infiniteIdleTrackQuery.hasNextPage &&
                                    infiniteIdleTrackQuery?.data?.pages[0].tracks.length > 0 &&
                                    <PrimaryButton onClick={() => searchRef.current.focus()}>Search</PrimaryButton>
                                }

                                {infiniteIdleTrackQuery.isFetchingNextPage &&
                                    <LoaderBox backgroundColor={false} box={false} width="32" height="32" />
                                }
                            </div>
                        </div>
                    }

                    {promotionLayout &&
                        <div className="track-box" style={searchEvent ? { display: "none" } : null}>
                            {infinitePromotionTrackQuery.status === 'pending' ? (
                                <div className="empty">
                                    <LoaderBox backgroundColor={false} />
                                </div>
                            ) : (infinitePromotionTrackQuery?.data?.pages[0].tracks.length > 0) ? (
                                infinitePromotionTrackQuery.data.pages.map((group, i) => (
                                    <React.Fragment key={i}>
                                        <AnimatePresence>
                                            {group.tracks.map((item) =>
                                                <ArtistPromotionTrackCard
                                                    key={item.track_id}
                                                    trackInfo={item}
                                                    artistId={currentProfile.artist_id}
                                                    disableHover={true}
                                                    prohibit_submit={item.duration > promotionInfo.free_window}
                                                />
                                            )}
                                        </AnimatePresence>
                                    </React.Fragment>
                                ))
                            ) : (
                                <div className="empty">
                                    <Icon iconType={ICONS.Mute} width="80" height="80" />
                                    <div className="subtitle">No tracks in promotion</div>
                                </div>
                            )}

                            <div className="end-card">
                                {!infinitePromotionTrackQuery.isFetchingNextPage &&
                                    !infinitePromotionTrackQuery.isFetching &&
                                    infinitePromotionTrackQuery.hasNextPage &&
                                    <PrimaryButton onClick={infinitePromotionTrackQuery.fetchNextPage}>Load More</PrimaryButton>
                                }

                                {infinitePromotionTrackQuery.isFetchingNextPage &&
                                    <LoaderBox backgroundColor={false} box={false} width="32" height="32" />
                                }
                            </div>
                        </div>
                    }

                    <div className="track-box" style={searchEvent ? null : { display: "none" }}>
                        {isFetching &&
                            <div className="empty">
                                <LoaderBox backgroundColor={false} />
                            </div>
                        }
                        {!isFetching && (
                            tracks.length ? (
                                tracks.map((item) =>
                                    <ArtistTrackCard
                                        key={item.track_id}
                                        trackInfo={item}
                                        artistId={currentProfile.artist_id}
                                        disableHover={true}
                                        type={(item.submitted || item.promoted) ? null :
                                            item.duration > promotionInfo.duration_limit ? "warning" : (
                                                item.duration > promotionInfo.free_window ? "prohibit" : "approved"
                                            )
                                        }
                                    />
                                )
                            ) : (
                                running ? (
                                    <div className="empty">
                                        <LoaderBox backgroundColor={false} />
                                    </div>
                                ) : (
                                    <div className="empty">
                                        <div className="subtitle">We couldn't find your tracks. New release?</div>
                                        <PrimaryButton onClick={handleUpdateCatalog}>Find a release</PrimaryButton>
                                    </div>
                                )
                            )
                        )}
                    </div>
                </div>
                <hr className="separator-line" />
            </div>
        </div>
    );
};