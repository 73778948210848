import PropTypes from "prop-types";
import React from "react";
import "./NotificationsButton.css";
import { Icon, ICONS } from "../../Icons/Icon";
import { useQueryClient } from "@tanstack/react-query";

export const NotificationsButton = ({ onClick, disabled }) => {
    const queryClient = useQueryClient();
    const notifications = queryClient.getQueryData(["userNotifications"]);

    return (
        <button 
            onClick={onClick} 
            disabled={disabled} 
            className={`notifications-button ${disabled ? "disable" : ""} ${notifications?.unread ? "ringing" : ""}`}
        >
            {notifications?.unread ? (
                <Icon iconType={ICONS.BellRinging} width="32" height="32"/>
            ) : (
                <Icon iconType={ICONS.Bell} width="32" height="32"/>
            )}
        </button>
    );
};

NotificationsButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

NotificationsButton.defaultProps = {
    onClick: () => {},
    disabled: false,
}

