import React, { useContext, useState } from "react";
import { Switch } from "../../components/Switch/Switch";
import { ArtistTrackStatCard } from "../../components/Cards/ArtistTrackStatCard/ArtistTrackStatCard";
import { TrackStatsInfiniteQuery } from "../../queries/sigmundApi";
import { SigmundContext } from "../../contexts/SigmundContext";
import { ICONS, Icon } from "../../components/Icons/Icon";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import useMeasure from "react-use-measure";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import "./TrackStatsBox.css";

export const TrackStatsBox = () => {
    const { currentProfile } = useContext(SigmundContext);
    const { isDesktop } = useContext(ResponsiveContext);
    const [currentDimension, setCurrentDimension] = useState("streams");
    const [ref, bounds] = useMeasure();
    const infiniteQuery = TrackStatsInfiniteQuery(currentProfile.artist_id);

    const options = [
        {
            name: "streams",
            onClick: () => setCurrentDimension("streams")
        },
        {
            name: "hours",
            onClick: () => setCurrentDimension("hours")
        },
    ]

    return (
        <>
            <div ref={ref} className="track-stats-box">
                <div className="top-section">
                    {bounds.width > 590 && 
                        <div className="title-section">
                            <div className="title">Lifetime track stats</div>
                        </div>
                    }
                    <Switch style={bounds.width <= 590 ? {maxWidth: "none"} : null} options={options} defaultOption="streams" />
                </div>
                <div className="cards-section">
                    <hr className="separator-line" />
                    <div className="section">
                        <div className="track-box">
                            {infiniteQuery.status === 'pending' ? (
                                <div className="empty">
                                    <LoaderBox backgroundColor={false} />
                                </div>
                            ) : infiniteQuery?.data?.pages?.length ? (
                                infiniteQuery.data.pages.map((group, i) => (
                                    <React.Fragment key={i}>
                                        {group.tracks?.map((item) =>
                                            <ArtistTrackStatCard
                                                key={item.track_name + item.album_name}
                                                trackInfo={item}
                                                type={currentDimension}
                                                hideStatus={bounds.width < 495}
                                                hideChange={bounds.width < 440}
                                                disableHover={!isDesktop}
                                            />
                                        )}
                                    </React.Fragment>
                                ))
                            ) : (
                                <div className="empty">
                                    <Icon iconType={ICONS.Armchair} width="80" height="80" />
                                    <div className="subtitle">Add tracks to the promotion to get the first stats</div>
                                </div>
                            )}

                            <div className="end-card">
                                {!infiniteQuery.isFetchingNextPage &&
                                    !infiniteQuery.isFetching &&
                                    infiniteQuery.hasNextPage &&
                                    <PrimaryButton onClick={infiniteQuery.fetchNextPage}>Load More</PrimaryButton>
                                }

                                {infiniteQuery.isFetchingNextPage &&
                                    <LoaderBox backgroundColor={false} box={false} width="32" height="32" />
                                }
                            </div>
                        </div>
                    </div>
                    <hr className="separator-line" />
                </div>
            </div>
        </>
    );
};