import "./UserVerificationBox.css";
import React from "react";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import useMeasure from "react-use-measure";
import { ICONS, Icon } from "../../components/Icons/Icon";
import { usePendingUsers } from "../../hooks/QueryHooks/userPendingUsers";
import { ApproveButton } from "../../components/Buttons/ApproveButton/ApproveButton";
import { Tooltip } from "react-tooltip";

export const UserVerificationBox = () => {
    const { users, ApproveUser, approvePendingUserMutation } = usePendingUsers();
    const [ref, bounds] = useMeasure();

    return (
        <div ref={ref} className="user-verification-box">
            {users ? (
                users.length > 0 ? (
                    <table className="top-box">
                        <thead>
                            <tr>
                                <th className="title">Users</th>
                                {bounds.width >= 355 &&
                                    <th className="column-label">Email</th>
                                }
                                {bounds.width >= 595 &&
                                    <th className="column-label">Spotify Email</th>
                                }
                                <th className="column-label">Approve</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={user.user_token}>
                                    <td className="value-wrapper">
                                        {user.username.length > 16 ? user.username.slice(0, 16) + "..." : user.username}
                                    </td>
                                    {bounds.width >= 355 &&
                                        <td className="value-wrapper" onClick={() => navigator.clipboard.writeText(user.email)}>
                                            {user.email.length > 16 ? user.email.slice(0, 16) + "..." : user.email}
                                        </td>
                                    }
                                    {bounds.width >= 595 &&
                                        <td className="value-wrapper" onClick={() => navigator.clipboard.writeText(user.spotify_email)}>
                                            {user.spotify_email.length > 16 ? user.spotify_email.slice(0, 16) + "..." : user.spotify_email}
                                        </td>
                                    }
                                    <td className="button-wrapper">
                                        {approvePendingUserMutation.isPending ?
                                            <LoaderBox backgroundColor={false} box={false} type="spinner" width="32" height="32" />
                                            : <ApproveButton onClick={() => ApproveUser(user.user_token)} />
                                        }
                                    </td>
                                    <Tooltip
                                        anchorSelect=".value-wrapper"
                                        place="bottom-center"
                                        className="tool-tip-style"
                                        noArrow={true}
                                        openOnClick={true}
                                        closeEvents={{ mouseleave: true, click: true, blur: true }}
                                        globalCloseEvents={{ scroll: true }}
                                        delayHide={500}
                                    >
                                        <div className="tip-text-wrapper">Copied</div>
                                    </Tooltip>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="empty-box">
                        <Icon iconType={ICONS.UsersFilled} width="80" height="80" />
                        <div className="subtitle">No users pending verification</div>
                    </div>
                )
            ) : (
                <LoaderBox backgroundColor={false} />
            )}
        </div>
    );
};