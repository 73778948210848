import { useState } from "react";
import { SignUpMutation } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";

export const useSignUpQuery = () => {
    const [status, setStatus] = useState("idle");
    const queryErrorHandler = useQueryErrorHandler();
    const signUpMutaion = SignUpMutation();

    const signUpQuery = (formData, onSuccess) => {
        setStatus("fetching");
        signUpMutaion.mutate(formData, {
            onSuccess: () => {
                onSuccess();
                setStatus('success');
            },
            onError: (error) => {
                setStatus("error");
                queryErrorHandler(error);
            },
        })
    };

    return { status, signUpQuery }
};