import React from "react";
import { Icon } from "../../components/Icons/Icon";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import "./Alert.css";

export const Alert = ({ title, subtitle, buttonText, onClick, iconType, backgroundColor = true }) => {
    return (
        <div className={`alert-frame ${backgroundColor ? "background" : ""}`}>
            <Icon iconType={iconType} height="80" width="80" />
            <div className="warning">
                <div className="title">{title}</div>
                <div className="subtitle">{subtitle}</div>
            </div>
            <PrimaryButton onClick={onClick}>{buttonText}</PrimaryButton>
        </div>
    );
};