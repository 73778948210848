import "./ConfirmEmail.css";
import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { absolutePaths } from "../../routes";
import { SigmundContext } from "../../contexts/SigmundContext";
import { AuthContext } from "../../contexts/AuthContext";
import { EmailConfirmationBox } from "../../blocks/EmailConfirmationBox/EmailConfirmationBox";

export const ConfirmEmail = () => {
    const { sigmundStatus } = useContext(SigmundContext);
    const { SignOut } = useContext(AuthContext);
    const navigate = useNavigate();

    const LogOut = () => {
        SignOut();
        navigate(absolutePaths.singIn, { replace: true });
    };

    return (
        <>
            {sigmundStatus === "email_confirmation" ? (
                <div className="email-confirmation-frame">
                    <div className="email-confirmation-section">
                        <div className="header">
                            <div className="title">Confirm your email</div>
                        </div>
                        <EmailConfirmationBox onSignOut={LogOut}/>
                    </div>
                </div>
            ) : (<Navigate to={absolutePaths.dashboard} />)}
        </>
    );
};