import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as SquaresLoader } from "../../assets/Animation/BlocksLoader.svg";
import { ReactComponent as SpinnerLoader } from "../../assets/Animation/SpinnerLoader.svg";
import "./LoaderBox.css";

export const LoaderBox = ({ backgroundColor, width, height, box, type }) => {
    return (
        <>
            {box ? (
                <div className={`loader-box${backgroundColor ? " background" : ""}`}>
                    {type === "squares" && <SquaresLoader className="loader-box-icon" height={height} width={width} />}
                    {type === "spinner" && <SpinnerLoader className="loader-box-icon" height={height} width={width} />}
                </div>
            ) : (
                <>
                    {type === "squares" && <SquaresLoader className="loader-box-icon" height={height} width={width} />}
                    {type === "spinner" && <SpinnerLoader className="loader-box-icon" height={height} width={width} />}
                </>
            )}
        </>
    );
};

LoaderBox.defaultProps = {
    backgroundColor: true,
    width: "80",
    height: "80",
    box: true,
    type: "squares"
};

LoaderBox.propTypes = {
    backgroundColor: PropTypes.bool,
    box: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    type: PropTypes.oneOf(["squares", "spinner"]),
};