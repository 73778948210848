import "./ChatCard.css";
import React from "react";
import DefaultUserImg from "../../../assets/Images/Anonymus.png";

export const ChatCard = ({ chatInfo, active = false, onClick }) => {
    return (
        <div className={`chat-card ${active ? "active" : ""}`} onClick={onClick}>
            <div className="left-section">
                <img
                    src={chatInfo.chat_image ? chatInfo.chat_image : DefaultUserImg}
                    className="chat-icon"
                    draggable="false"
                    alt="Chat Icon"
                />
                <div className="preview">
                    <div className="text-wrapper">{chatInfo.chat_name}</div>
                    <div className="message-preview">{chatInfo.last_message}</div>
                </div>
            </div>
            {chatInfo.unread_count > 0 && 
                <div className="new-message">
                    <div className="unread-count">{chatInfo.unread_count}</div>
                </div>
            }
        </div>
    );
};