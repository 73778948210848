import PropTypes from "prop-types";
import React from "react";
import "./StockCard.css";

export const StockCard = ({ label, value, warning }) => {
    return (
        <div className={`stock-card ${warning ? "warning" : ""}`}>
            <div className="value">{value.toFixed(1)}</div>
            <div className="label">{label}</div>
        </div>
    );
};

StockCard.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    warning: PropTypes.bool,
};

StockCard.defaultProps = {
    label: "",
    value: "",
    warning: false,
};