import React, { useState } from "react";
import { motion } from "framer-motion";
import "./NotificationCard.css";
import useMeasure from "react-use-measure";

export const NotificationCard = ({ notification }) => {
    const [active, setActive] = useState(false);
    const [ref, bounds] = useMeasure();
    const date = new Date(notification.date * 1000);
    const timeValue = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    });
    const month = date.toLocaleString('en', { month: 'short' });
    const day = date.getDate();

    return (
        <motion.div animate={{ height: bounds.height}}>
            <div ref={ref} className="notification-card" onClick={() => setActive(prev => !prev)}>
                <div className="left-section">
                    <p style={active ? { "WebkitLineClamp": "unset" } : null} className="title">{notification.title}</p>
                    <p style={active ? { "WebkitLineClamp": "unset" } : null} className="subtitle">{notification.subtitle}</p>
                </div>
                <div className="date-section">
                    <div className="date-text">{`${month} ${day}`}</div>
                    <div className="date-text">{timeValue}</div>
                </div>
            </div>
        </motion.div>
    );
};