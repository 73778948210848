import React from "react";
import { PopUpMenuButton } from "../../components/Buttons/PopUpMenuButton/PopUpMenuButton";
import { useTopFansInsightsQuery } from "../../hooks/QueryHooks/useTopFansInsights";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { formatHours } from "../../tools";
import useMeasure from "react-use-measure";
import "./TopFansBox.css";
import { ICONS, Icon } from "../../components/Icons/Icon";

export const TopFansBox = () => {
    const { insights } = useTopFansInsightsQuery();
    const [ref, bounds] = useMeasure();

    return (
        <div ref={ref} className="top-fans-box">
            {insights ? (
                insights.length > 0 ? (
                    <table className="top-box">
                        <thead>
                            <tr>
                                <th className="title">Top fans</th>
                                <th className="column-label">Total{bounds.width >= 660 ? " broadcasting" : ""}</th>
                                {bounds.width >= 315 &&
                                    <th className="column-label">Referral{bounds.width >= 660 ? " broadcasting" : ""}</th>
                                }
                                {bounds.width >= 530 &&
                                    <th className="column-label">Contribution</th>
                                }
                                <th className="column-label"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {insights.map((fan, index) => (
                                <tr key={fan.username}>
                                    <td className="value-wrapper"><span className="index">{index + 1}</span>&nbsp;<span>{fan.username}</span></td>
                                    <td className="value-wrapper">{formatHours(fan.total_hours, bounds.width < 530)}</td>
                                    {bounds.width >= 315 &&
                                        <td className="value-wrapper">{formatHours(fan.referral_hours, bounds.width < 530)}</td>
                                    }
                                    {bounds.width >= 530 &&
                                        <td className="value-wrapper">{Math.round(fan.contribution_rate * 100) + "%"}</td>
                                    }
                                    <td className="button-wrapper"><PopUpMenuButton height="24" width="24" /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="empty-box">
                        <Icon iconType={ICONS.UsersFilled} width="80" height="80" />
                        <div className="subtitle">You don't have any top fans yet. Invite them via a referral link</div>
                    </div>
                )
            ) : (
                <LoaderBox backgroundColor={false} />
            )}
        </div>
    );
};