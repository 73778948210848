import { useState } from "react";
import { ManageTrackMutation } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";

export const useManageTrackQuery = () => {
    const queryErrorHandler = useQueryErrorHandler();
    const queryMutation = ManageTrackMutation();
    const [isFetching, setIsFetching] = useState(false); 

    const manageTrackQuery = (artistId, trackId, action, onComplete) => {
        setIsFetching(true);
        queryMutation.mutate({ artistId, trackId, action }, {
            onSuccess: () => {
                onComplete();
                setIsFetching(false);
            },
            onError: (error) => {
                queryErrorHandler(error);
                setIsFetching(false);
            }
        })
    };

    return { manageTrackQuery, isFetching };
};