import PropTypes from "prop-types";
import React from "react";
import "./BackButton.css";
import { Icon, ICONS } from "../../Icons/Icon";
import { LoaderBox } from "../../../blocks/LoaderBox/LoaderBox";

export const BackButton = ({ onClick, loading }) => {
    return (
        <button onClick={onClick} className="back-button">
            {loading ? (
                <LoaderBox backgroundColor={false} box={false}  height="32" width="32" type="spinner"/>
            ) : (
                <Icon iconType={ICONS.ArrowLeft} width="32" height="32"/>
            )}
        </button>
    );
};

BackButton.propTypes = {
    onClick: PropTypes.func,
    loading: PropTypes.bool,
};

BackButton.defaultProps = {
    onClick: () => {},
    loading: false
}

