import { useState } from "react";
import { ICONS, Icon } from "../Icons/Icon";
import "./CheckBox.css";

export const CheckBox = ({ onCheck, styleType = "square" }) => {
    const [check, setCheck] = useState(false);

    const handleCheck = () => {
        onCheck(!check);
        setCheck(prev => !prev);
    };

    return (
        <button className="check-box" onClick={handleCheck}>
            {check ? (
                <>
                    {styleType === "square" && <Icon iconType={ICONS.CheckSquare} width="32" height="32" />}
                    {styleType === "circle" && <Icon iconType={ICONS.ApproveCircleFilled} width="32" height="32" />}
                </>
            ) :
                (
                    <>
                        {styleType === "square" && <Icon iconType={ICONS.Square} width="32" height="32" />}
                        {styleType === "circle" && <Icon iconType={ICONS.Circle} width="32" height="32" />}
                    </>
                )}
        </button>
    );
};