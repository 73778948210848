import { createContext, useState, useEffect, useRef } from "react";
import { AlertOverlay } from "../components/Overlays/AlertOverlay/AlertOverlay";
import { AnimatePresence, motion } from "framer-motion";
import { CuratorRequestOverlay } from "../components/Overlays/CuratorRequestOverlay/CuratorRequestOverlay";

const overlayStyle = {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "hsla(239, 32%, 25%, 0.4)"
};

export const OverlayContext = createContext(null);

export const OverlayProvider = ({ children }) => {
    const [displayModal, setDisplayModal] = useState(false);
    const [modal, setModal] = useState(null);
    const [displayAlert, setDisplayAlert] = useState(false);
    const [info, setInfo] = useState({
        curatorRequest: {},
        details: "",
    });
    const timerRef = useRef(null);

    const DisplayAlertCallback = (newDetails) => {
        setInfo({...info, details: newDetails});
        setDisplayAlert(true);
    };

    const DisplayModalCallback = (modalName, modalInfo = {}) => {
        setInfo({...info, [modalName]: modalInfo})
        setModal(modalName);
        setDisplayModal(true);
    };

    useEffect(() => {
        if (displayAlert) {
            timerRef.current = setTimeout(() => {
                setDisplayAlert(false);
                setInfo({...info, details: ""});
            }, 5000);
        }
        return () => clearTimeout(timerRef.current);
    }, [displayAlert, info]);

    const handleAlertClose = () => {
        setDisplayAlert(false);
        setInfo({...info, details: ""});
        clearTimeout(timerRef.current);
    };

    const handleModalClose = (modalName) => {
        setModal(null);
        setDisplayModal(false);
        setInfo({...info, [modalName]: {}})
    };

    return (
        <OverlayContext.Provider value={{ DisplayAlertCallback, DisplayModalCallback }}>
            <AnimatePresence>
                {children}
                {displayModal &&
                    <motion.div
                        key="overlayBackground"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.45 }}
                        style={overlayStyle}
                    />}
                {modal === "curatorRequest" && displayModal && <CuratorRequestOverlay key="curatorRequestOverlay" requestInfo={info.curatorRequest} onClose={() => handleModalClose("curatorRequest")} />}
                {displayAlert && <AlertOverlay key="alertOverlay" onClose={handleAlertClose}>{info.details}</AlertOverlay>}
            </AnimatePresence>
        </OverlayContext.Provider>
    );
};