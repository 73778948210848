import { useContext, useEffect } from "react";
import { Navigate, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { absolutePaths } from "../routes";

export const RequireAuth = () => {
    const { isAuth, CheckAuthCallback } = useContext(AuthContext);
    const location = useLocation();
    const [searchParams,] = useSearchParams();
    const streamingStatus = searchParams.get("streamingStatus");

    useEffect(() => {
        CheckAuthCallback();
        if (streamingStatus) {
            localStorage.setItem("streamingStatus", streamingStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isAuth ?
                <Outlet  /> :
                <Navigate to={absolutePaths.singIn} state={{ from: location }} replace />}
        </>
    );
};