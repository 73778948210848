import { useState, useEffect } from 'react';

export function useElapsedTime() {
    const [startTime, setStartTime] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [running, setRunning] = useState(false);

    useEffect(() => {
        let interval;
        if (running) {
            interval = setInterval(() => {
                setElapsedTime(Date.now() - startTime);
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [running, startTime]);

    const startTimer = () => {
        setStartTime(Date.now());
        setElapsedTime(0);
        setRunning(true);
    };

    const resetTimer = () => {
        setStartTime(null);
        setElapsedTime(0);
        setRunning(false);
    };

    return { elapsedTime, running, startTimer, resetTimer };
}