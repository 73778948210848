import { useContext } from "react";
import { SignOutMutation } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";
import { AuthContext } from "../../contexts/AuthContext";

export const useSignOut = () => {
    const queryErrorHandler = useQueryErrorHandler();
    const queryMutation = SignOutMutation();
    const { SignOut } = useContext(AuthContext);

    const signOut = () => {
        SignOut();
        queryMutation.mutate({}, {
            onSuccess: async () => {
                SignOut();
            },
            onError: (error) => {
                queryErrorHandler(error);
            }
        })
    };

    return signOut;
};