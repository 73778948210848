import React from "react";
import DefaultUserImg from "../../../assets/Images/Anonymus.png";
import "./ChatContactCard.css";

export const ChatContactCard = ({ contactInfo, onClick }) => {
    return (
        <div className="chat-contact-card" onClick={onClick} >
            <div className="left-section">
                <img
                    src={contactInfo.contact_image ? contactInfo.contact_image : DefaultUserImg}
                    className="contact-icon"
                    draggable="false"
                    alt="Contact Icon"
                />
                <div className="text-wrapper">{contactInfo.contact_name}</div>
            </div>
            <div className="contact-type">
                <div className="div">{contactInfo.contact_type}</div>
            </div>
        </div>
    );
};
