import { useContext, useEffect, useState } from "react";
import { ContactsQuery } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";
import { SigmundContext } from "../../contexts/SigmundContext";

export const useContactsQuery = () => {
    const [contacts, setcontacts] = useState(null);
    const { currentProfile } = useContext(SigmundContext);
    const queryErrorHandler = useQueryErrorHandler();
    const contactsQuery = ContactsQuery(currentProfile.artist_id);

    useEffect(() => {   
        if (contactsQuery.isFetching) {
            setcontacts(null);
        } else if (contactsQuery.isSuccess) {
            setcontacts(contactsQuery.data);
        } else if (contactsQuery.isError) {
            queryErrorHandler(contactsQuery.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactsQuery.isSuccess, contactsQuery.isError, currentProfile.artist_id]);

    return { contacts, contactsQuery };
};