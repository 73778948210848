import React from "react";
import "./InfoDoubleCard.css";

export const InfoDoubleCard = ({ topInfo, bottomInfo }) => {
    return (
        <div className="info-double-card">
            <div className="top-card">
                <div className="top-title">{topInfo.title}</div>
                <div className="top-subtitle">{topInfo.subtitle}</div>
            </div>
            <div className="bottom-card">
                <div className="bottom-title">{bottomInfo.title}</div>
                <div className="bottom-subtitle">{bottomInfo.subtitle}</div>
            </div>
        </div>
    );
};