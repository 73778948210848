import PropTypes from "prop-types";
import React from "react";
import { Icon, ICONS } from "../../Icons/Icon";
import "./ArrowButton.css";

export const ArrowButton = ({ type, onClick, disabled }) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className="arrow-button">
            {type === "up" && <Icon iconType={ICONS.CaretBoldUp} width="16" height="16" />}
            {type === "down" && <Icon iconType={ICONS.CaretBoldDown} width="16" height="16" />}
        </button>
    );
};

ArrowButton.propTypes = {
    type: PropTypes.oneOf(["up", "down"]),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

ArrowButton.defaultProps = {
    type: "up",
    onClick: () => { },
    disabled: false,
};