import React from "react";
import "./InDevelopment.css";
import { ICONS, Icon } from "../../components/Icons/Icon";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { absolutePaths } from "../../routes";

export const InDevelopment = ({ backgroundColor = true }) => {
    const navigate = useNavigate();

    return (
        <div className={`in-development ${backgroundColor ? "background" : ""}`}>
            <Icon iconType={ICONS.Gear} height="80" width="80" />
            <div className="warning">
                <div className="title">In development</div>
                <div className="subtitle">New features are just around the corner</div>
            </div>
            <PrimaryButton onClick={() => navigate(absolutePaths.dashboard)}>Home page</PrimaryButton>
        </div>
    );
};