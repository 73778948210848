import PropTypes from "prop-types";
import React from "react";
import "./SideMenuButton.css";
import { ICONS, Icon } from "../../Icons/Icon";
import { NavLink } from "react-router-dom";

export const VARIANTS = {
    home: {
        defaultIcon: ICONS.House,
        activeIcon: ICONS.HouseFilled,
        name: "Home"
    },
    console: {
        defaultIcon: ICONS.Armchair,
        activeIcon: ICONS.Armchair,
        name: "Console"
    },
    spotify: {
        defaultIcon: ICONS.SpotifyLogo,
        activeIcon: ICONS.SpotifyLogoFilled,
        name: "Spotify"
    },
    fans: {
        defaultIcon: ICONS.UsersThree,
        activeIcon: ICONS.UsersThreeFilled,
        name: "Fans"
    },
    statistics: {
        defaultIcon: ICONS.ChartBar,
        activeIcon: ICONS.ChartBarFilled,
        name: "Statistics"
    },
    generalStatsSubButton: {
        defaultIcon: ICONS.Dot,
        activeIcon: ICONS.DotFilled,
        name: "General"
    },
    trackStatsSubButton: {
        defaultIcon: ICONS.Dot,
        activeIcon: ICONS.DotFilled,
        name: "Tracks"
    },
    messages: {
        defaultIcon: ICONS.ChatCircle,
        activeIcon: ICONS.ChatCircleFilled,
        name: "Messages"
    },
    music: {
        defaultIcon: ICONS.MusicNotes,
        activeIcon: ICONS.MusicNotesFilled,
        name: "Music"
    },
    tracks: {
        defaultIcon: ICONS.MusicNotes,
        activeIcon: ICONS.MusicNotesFilled,
        name: "Tracks"
    },
    friends: {
        defaultIcon: ICONS.Users,
        activeIcon: ICONS.UsersFilled,
        name: "Friends"
    },
    signOut: {
        defaultIcon: ICONS.SignOut,
        activeIcon: ICONS.SignOut,
        name: "Sign Out"
    },
    myArtists: {
        defaultIcon: ICONS.Users,
        activeIcon: ICONS.UsersFilled,
        name: "My Artists"
    },
    claimArtist: {
        defaultIcon: ICONS.Dot,
        activeIcon: ICONS.DotFilled,
        name: "Claim An Artist"
    },
    profile: {
        defaultIcon: ICONS.VinylRecord,
        activeIcon: ICONS.VinylRecord,
        name: "My Profile"
    },
    updateUsername: {
        defaultIcon: ICONS.Dot,
        activeIcon: ICONS.DotFilled,
        name: "Change Username"
    },
};

export const SideMenuButton = ({ to, disabled, variant, onClick, style, slim }) => {
    return (
        <NavLink
            to={to}
            onClick={onClick}
            disabled={disabled}
            style={style}
            className={({ isActive }) => `side-menu-button ${isActive ? "active" : "default"}`}>
            {({ isActive }) => (
                <>
                    <Icon iconType={isActive ? variant.activeIcon : variant.defaultIcon} width="32" height="32" />
                    {!slim && 
                        <div className="text-wrapper">{variant.name}</div>
                    }
                </>
            )}
        </NavLink>
    );
};

SideMenuButton.defaultProps = {
    to: "/",
    disabled: false,
    active: false,
    variant: VARIANTS.home,
    onClick: () => { },
    style: null,
    slim: false,
};

SideMenuButton.propTypes = {
    to: PropTypes.string,
    children: PropTypes.string,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    variant: PropTypes.object,
    onClick: PropTypes.func,
    style: PropTypes.object,
    slim: PropTypes.bool,
};