import { useState } from "react";
import { formatDuration } from "../../../tools";
import { CircleArrowButton } from "../../Buttons/CircleArrowButton/CircleArrowButton";
import { ICONS, Icon } from "../../Icons/Icon";
import { useManageTrackQuery } from "../../../hooks/QueryHooks/useManageTrack";
import { useManageTracks } from "../../../hooks/useManageTracks";
import { LoaderBox } from "../../../blocks/LoaderBox/LoaderBox";
import { motion } from "framer-motion";
import "./ArtistTrackCard.css";

export const ArtistTrackCard = ({ trackInfo, artistId, type, disableHover = false }) => {
    const [hover, setHover] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const { manageTrackQuery, isFetching } = useManageTrackQuery();
    const { submitTrack, updateSubmitStatus } = useManageTracks();

    const handleSubmit = () => {
        manageTrackQuery(artistId, trackInfo.track_id, "submit", () => {
            submitTrack(artistId, trackInfo);
            setSubmitted(true);
            updateSubmitStatus(artistId, trackInfo, "idle", true);
        })
    };

    return (
        <motion.div
            style={{ overflow: "hidden" }}
            key={trackInfo.track_id}
            // initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.65 }}
        >
            <div
                className="artist-track-card"
                onMouseEnter={() => disableHover ? () => {} : setHover(true)}
                onMouseLeave={() => disableHover ? () => {} : setHover(false)}
                >
                <div className="left-card-section">
                    <img className="track-image" alt="Track" src={trackInfo.image} />
                    <div className="main-info">
                        <div className="track-name">{trackInfo.track_name}</div>
                        <div className="track-album">{trackInfo.album_name}</div>
                    </div>
                </div>
                <div className="right-card-section">
                    <div className="track-duration">{formatDuration(trackInfo.duration)}</div>
                    {(type === "approved" && !submitted) && (
                        isFetching ? (
                            <LoaderBox backgroundColor={false} box={false} height="32" width="32" type="spinner" />
                        ) : (
                            <CircleArrowButton active={hover} type="right" onClick={handleSubmit} />
                        )
                    )}
                    {type === "warning" &&
                        <div className="warning-track-icon">
                            {hover ? (
                                <Icon iconType={ICONS.WarningCircleFilled} width="32" height="32" />
                            ) : (
                                <Icon iconType={ICONS.WarningCircle} width="32" height="32" />
                            )}
                        </div>
                    }
                    {type === "prohibit" &&
                        <div className="prohibit-track-icon">
                            <Icon iconType={ICONS.Prohibit} width="32" height="32" />
                        </div>
                    }
                    {(!trackInfo.promoted && (trackInfo.submitted || submitted)) && (
                        hover ? (
                            <Icon iconType={ICONS.CalendarCheckFilled} width="32" height="32" />
                        ) : (
                            <Icon iconType={ICONS.CalendarCheck} width="32" height="32" />
                        ))
                    }
                    {trackInfo.promoted &&
                        <Icon iconType={ICONS.AirplaneTakeoff} width="32" height="32" />
                    }
                </div>
            </div>
        </motion.div>
    );
};