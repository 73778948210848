import React from "react";
import { CloseButton } from "../../Buttons/CloseButton/CloseButton";
import { motion } from "framer-motion";
import "./AlertOverlay.css";

export const AlertOverlay = ({ onClose, children }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className="alert-card">
        <div className="warning-text">
          <div className="text-wrapper">Oops. Something went wrong</div>
          <div className="div">{children}</div>
        </div>
        <div className="close-box">
          <CloseButton onClick={onClose} />
        </div>
      </div>
    </motion.div>
  );
};