import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "./endpoins";

const callAPI = async (endpoint, params) => {
    const response = await fetch(endpoint, params);
    if (!response.ok) {
        let error = new Error("Fetch error");
        error.response = response;
        error.status = response.status;
        throw error
    }
    return response;
};

export const UpdateUsernameMutation = () => {
    const mutation = useMutation({
        mutationFn: (username) => {
            const params = {
                method: 'POST',
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
                body: JSON.stringify({
                    username
                })
            };
            return callAPI(endpoints.accountUpdateUsername, params);
        }
    });

    return mutation;
};

export const ContactsQuery = (profileToken) => {
    const query = useQuery({
        queryKey: ["contacts", profileToken],
        queryFn: async () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };

            const payload = new URLSearchParams({
                "profile_token": profileToken,
            });

            const response = await callAPI(`${endpoints.getContacts}?${payload}`, params);
            const data = await response.json();
            return data;
        },
    });

    return query;
};

export const CreatePrivateChatMutation = () => {
    const mutation = useMutation({
        mutationFn: ({ profileToken, memberToken }) => {
            const params = {
                method: 'POST',
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
                body: JSON.stringify({
                    "member_token": memberToken,
                }),
            };
            const payload = new URLSearchParams({
                "profile_token": profileToken,
            });
            return callAPI(`${endpoints.createPrivateChat}?${payload}`, params);
        },
    });

    return mutation;
};

export const ChatsQuery = (profileToken) => {
    const query = useQuery({
        queryKey: ["chats", profileToken],
        queryFn: async () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };

            const payload = new URLSearchParams({
                "profile_token": profileToken,
            });

            const response = await callAPI(`${endpoints.getChats}?${payload}`, params);
            const data = await response.json();
            return data;
        },
    });

    return query;
};

export const TrackStatsInfiniteQuery = (artist_id) => {
    const fetchData = async ({ pageParam = 0 }) => {
        const payload = new URLSearchParams({
            "offset": pageParam,
            artist_id,
        });
    
        const params = {
            method: 'GET',
            credentials: "include",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            }
        };
        
        const data = (await callAPI(`${endpoints.trackInsights}?${payload}`, params)).json();
        return data;
    };

    const infiniteQuery = useInfiniteQuery({
        queryKey: ['trackInsights', artist_id],
        queryFn: fetchData,
        getNextPageParam: (lastPage, pages) => lastPage.next_offset,
    });

    return infiniteQuery;
};

export const ManageTrackMutation = () => {
    const mutation = useMutation({
        mutationFn: ({ artistId, trackId, action }) => {
            const params = {
                method: 'POST',
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({
                "artist_id": artistId,
                "track_id": trackId,
            });
            const endpoint = action === "submit" ? endpoints.submitTrack : endpoints.revokeTrack;
            return callAPI(`${endpoint}?${payload}`, params);
        }
    });

    return mutation;
};

export const SearchTracksMutation = () => {
    const mutation = useMutation({
        mutationFn: ({artistId, trackName }) => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({
                "artist_id": artistId,
                "track_name": trackName,
            });
            return callAPI(`${endpoints.searchTracks}?${payload}`, params);
        }
    }
    );

    return mutation;
};

export const UpdateCatalogMutation = () => {
    const mutation = useMutation({
        mutationFn: ({artistId}) => {
            const params = {
                method: 'POST',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({
                "artist_id": artistId,
            });
            return callAPI(`${endpoints.updateCatalog}?${payload}`, params);
        }
    }
    );

    return mutation;
};

export const PendingUsersQuery = () => {
    const query = useQuery({
        queryKey: ["pendingUsers"],
        queryFn: async () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const response = await callAPI(`${endpoints.getPendingUsers}`, params);
            const data = await response.json();
            return data;
        },
    });

    return query;
};

export const ApprovePendingUserMutation = () => {
    const mutation = useMutation({
        mutationFn: (userToken) => {
            const params = {
                method: 'POST',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({
                user_token: userToken
            });
            return callAPI(`${endpoints.approveUser}?${payload}`, params);
        }
    });

    return mutation;
};

export const ArtistTracksInfiniteQuery = (track_filter, artist_id) => {
    const fetchData = async ({ pageParam = 0 }) => {
        const payload = new URLSearchParams({
            "offset": pageParam,
            track_filter,
            artist_id,
        });
    
        const params = {
            method: 'GET',
            credentials: "include",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            }
        };
        
        const data = (await callAPI(`${endpoints.getTracks}?${payload}`, params)).json();
        return data;
    };

    const infiniteQuery = useInfiniteQuery({
        queryKey: ['artistTracks', track_filter, artist_id],
        queryFn: fetchData,
        getNextPageParam: (lastPage, pages) => lastPage.next_offset,
    });

    return infiniteQuery;
};

export const AnnouncementsMutation = () => {
    const mutation = useMutation({
        mutationFn: () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            return callAPI(endpoints.announcements, params);
        }
    }
    );

    return mutation;
};

export const SendCuratorRequestMutation = () => {
    const mutation = useMutation({
        mutationFn: ({ request_count, request_template, save_template, artist_id }) => {
            const params = {
                method: 'POST',
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
                body: JSON.stringify({
                    request_count,
                    request_template,
                    save_template,
                })
            };
            const payload = new URLSearchParams({
                artist_id
            });
            return callAPI(`${endpoints.curatorSendRequests}?${payload}`, params);
        }
    });

    return mutation;
};

export const PickGenresMutation = () => {
    const mutation = useMutation({
        mutationFn: ({ genres, artistId }) => {
            const params = {
                method: 'POST',
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
                body: JSON.stringify({
                    genres
                })
            };
            const payload = new URLSearchParams({
                artist_id: artistId
            });
            return callAPI(`${endpoints.genrePick}?${payload}`, params);
        }
    });

    return mutation;
};

export const CuratorRequestStatusQuery = (artistId) => {
    const query = useQuery({
        queryKey: ["curatorRequestStatus", artistId],
        queryFn: async () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({
                artist_id: artistId
            });
            const response = await callAPI(`${endpoints.curatorRequests}?${payload}`, params);
            const data = await response.json();
            return data;
        },
    });

    return query;
};

export const NotificationsQuery = () => {
    const query = useQuery({
        queryKey: ["userNotifications"],
        queryFn: async () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const response = await callAPI(endpoints.notifications, params);
            const data = await response.json();
            return data;
        },
        refetchInterval: 120 * 1000,
    });

    return query;
};

export const ViewNotificationsMutation = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: () => {
            const params = {
                method: 'POST',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                },
            };
            return callAPI(endpoints.viewNotifications, params);
        },
        onSuccess: () => {
            queryClient.setQueryData(["userNotifications"], (data) => ({
                ...data,
                unread: false,
            }));
        },
    });

    return mutation;
};

export const CuratorRequestStatusMutation = (artistId) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({
                artist_id: artistId
            });
            const response = await callAPI(`${endpoints.curatorRequests}?${payload}`, params);
            const data = await response.json();
            return data;
        },
        onSuccess: (data) => {
            queryClient.setQueryData(["curatorRequestStatus", artistId], data);
        },
    }
    );

    return mutation;
};

export const BroadcastingStatusQuery = () => {
    const query = useQuery({
        queryKey: ["broadcastingStatus"],
        queryFn: async () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const response = await callAPI(endpoints.broadcastingStatus, params);
            const data = await response.json();
            return data;
        },
    });

    return query;
};

export const FansInsightsQuery = (artistId) => {
    const query = useQuery({
        queryKey: ["fansInsights", artistId],
        queryFn: async () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({
                "artist_id": artistId,
            });
            
            const response = await callAPI(`${endpoints.fansInsights}?${payload}`, params);
            const data = await response.json();
            return data;
        },
    });

    return query;
};

export const TopFansInsightsQuery = (artistId) => {
    const query = useQuery({
        queryKey: ["topFansInsights", artistId],
        queryFn: async () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({
                "artist_id": artistId,
            });
            
            const response = await callAPI(`${endpoints.topFansInsights}?${payload}`, params);
            const data = await response.json();
            return data;
        },
    });

    return query;
};

export const ReferralUrlQuery = (artistId) => {
    const query = useQuery({
        queryKey: ["referralUrl", artistId],
        queryFn: async () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({
                "artist_id": artistId,
            });
            
            const response = await callAPI(`${endpoints.referralUrl}?${payload}`, params);
            const data = await response.json();
            return data;
        },
    });

    return query;
};

export const GeneralInsightsQuery = ({ artistId, period, dimension }) => {
    const query = useQuery({
        queryKey: ["generalInsights", artistId, period, dimension],
        queryFn: async () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({
                "artist_id": artistId,
                "dimension_type": dimension,
                "period": period,
            });
            
            const response = await callAPI(`${endpoints.generalInsights}?${payload}`, params);
            const data = await response.json();
            return data;
        },
    });

    return query;
};

export const GeneralInsightsMutation = () => {
    const mutation = useMutation({
        mutationFn: async ({ artistId, period, dimension }) => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({
                "artist_id": artistId,
                "dimension_type": dimension,
                "period": period,
            });
            
            const response = await callAPI(`${endpoints.generalInsights}?${payload}`, params);
            const data = await response.json();
            return data;
        }
    });

    return mutation;
};

export const BriefInsightsQuery = (artistId) => {
    const query = useQuery({
        queryKey: ["briefInsights", artistId],
        queryFn: async () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({ "artist_id": artistId });
            const response = await callAPI(`${endpoints.briefInsights}?${payload}`, params);
            const data = await response.json();
            return data;
        },
    });

    return query;
};

export const SpotifyConnectMutation = () => {
    const mutation = useMutation({
        mutationFn: () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            return callAPI(endpoints.spotifyConnect, params);
        }
    }
    );

    return mutation;
};

export const StreamingConnectionStatusMutation = () => {
    const mutation = useMutation({
        mutationFn: () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            return callAPI(endpoints.streamingConnectionStatus, params);
        }
    }
    );

    return mutation;
};

export const SfscoopRequestEmailConfirmationMutation = () => {
    const mutation = useMutation({
        mutationFn: (reset = false) => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({ reset });
            return callAPI(`${endpoints.sfscoopRequestEmailConfirmation}?${payload}`, params);
        }
    }
    );

    return mutation;
};

export const SfscoopConfirmEmailMutation = () => {
    const mutation = useMutation({
        mutationFn: (code) => {
            const params = {
                method: 'POST',
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
                body: JSON.stringify({
                    secret_code: code
                })
            };
            return callAPI(endpoints.sfscoopConfirmEmail, params);
        }
    });

    return mutation;
};

export const SpotifyInitialConfirmEmailMutation = () => {
    const mutation = useMutation({
        mutationFn: () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            return callAPI(endpoints.spotifyInitialConfirmEmail, params);
        }
    }
    );

    return mutation;
};

export const SpotifyConfirmEmailMutation = () => {
    const mutation = useMutation({
        mutationFn: (email) => {
            const params = {
                method: 'POST',
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
                body: JSON.stringify({
                    email
                })
            };
            return callAPI(endpoints.spotifyConfirmEmail, params);
        }
    });

    return mutation;
};

export const ClaimArtistMutation = () => {
    const mutation = useMutation({
        mutationFn: ({ artistUrl }) => {
            const params = {
                method: 'POST',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({ "artist_spotify_url": artistUrl });
            return callAPI(`${endpoints.claimArtist}?${payload}`, params);
        }
    }
    );

    return mutation;
};

export const ClaimStatusMutation = () => {
    const mutation = useMutation({
        mutationFn: ({ artistUrl }) => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            const payload = new URLSearchParams({ "artist_spotify_url": artistUrl });
            return callAPI(`${endpoints.claimStatus}?${payload}`, params);
        }
    }
    );

    return mutation;
};

export const SigmundMeMutation = () => {
    const mutation = useMutation({
        mutationFn: () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            return callAPI(endpoints.sigmundMe, params);
        }
    }
    );

    return mutation;
};

export const SignOutMutation = () => {
    const mutation = useMutation({
        mutationFn: () => {
            const params = {
                method: 'GET',
                credentials: "include",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            };
            return callAPI(endpoints.signOut, params);
        }
    }
    );

    return mutation;
};

export const SignUpMutation = () => {
    const mutation = useMutation({
        mutationFn: ({ username, email, password, role, invitationToken }) => {
            const params = {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username,
                    email,
                    password,
                    user_role: role.toLowerCase(),
                    referral_id: invitationToken,
                })
            };
            return callAPI(endpoints.signUp, params);
        }
    });

    return mutation;
};

export const AuthTokenMutation = () => {
    const mutation = useMutation({
        mutationFn: ({ username, password }) => {
            const params = {
                method: 'POST',
                credentials: "include",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": "XMLHttpRequest",
                },
                body: new URLSearchParams({
                    "grant_type": "password",
                    username,
                    password,
                }).toString(),
            };
            return callAPI(endpoints.token, params);
        }
    });

    return mutation;
};

export const ValidateUsernameMutation = () => {
    const params = {
        method: 'GET',
    };

    const mutation = useMutation({
        mutationFn: (username) => {
            const payload = new URLSearchParams({ username });
            return callAPI(`${endpoints.usernameValidation}?${payload}`, params);
        }
    });

    return mutation;
};

export const ValidateEmailMutation = () => {
    const params = {
        method: "GET",
    };

    const mutation = useMutation({
        mutationFn: (email) => {
            const payload = new URLSearchParams({ email });
            return callAPI(`${endpoints.emailValidation}?${payload}`, params);
        }
    });

    return mutation;
};

export const ValidateInvitationMutation = () => {
    const params = {
        method: "GET",
    };

    const mutation = useMutation({
        mutationFn: (invitationToken) => {
            const payload = new URLSearchParams({ "invitation_token": invitationToken });
            return callAPI(`${endpoints.inviteValidation}?${payload}`, params);
        }
    });

    return mutation;
};

export const RequestPasswordRecoveryMutation = () => {
    const params = {
        method: "GET",
    };

    const mutation = useMutation({
        mutationFn: (email) => {
            const payload = new URLSearchParams({ email });
            return callAPI(`${endpoints.requestPasswordRecovery}?${payload}`, params);
        }
    });

    return mutation;
};

export const ResetPasswordMutation = () => {
    const mutation = useMutation({
        mutationFn: ({ recoveryToken, password }) => {
            const params = {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "secret_token": recoveryToken,
                    password,
                }),
            };
            return callAPI(endpoints.resetPassword, params);
        }
    });

    return mutation;
};