import { useContext } from "react";
import { PickGenresMutation } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";
import { SigmundContext } from "../../contexts/SigmundContext";

export const usePickGenresQuery = () => {
    const queryErrorHandler = useQueryErrorHandler();
    const { currentProfile } = useContext(SigmundContext);
    const queryMutation = PickGenresMutation();

    const pickGenresQuery = (genres, onComplete) => {
        queryMutation.mutate({ genres, artistId: currentProfile.artist_id }, {
            onSuccess: () => {
                onComplete();
            },
            onError: (error) => {
                queryErrorHandler(error);
            }
        })
    };

    return pickGenresQuery;
};