import React from "react";
import { AnnouncementCard } from "../../components/Cards/AnnouncementCard/AnnouncementCard";
import "./AnnouncementBox.css";
import { ICONS, Icon } from "../../components/Icons/Icon";
import { useAnnouncements } from "../../hooks/QueryHooks/useAnnouncements";
import { LoaderBox } from "../LoaderBox/LoaderBox";

export const AnnouncementBox = () => {
    const { announcements, fetched } = useAnnouncements();

    return (
        <div className="announcement-box">
            <div className="top-section">
                <div className="title">Announcements</div>
            </div>
            <div className="cards-section">
                {!fetched && <LoaderBox backgroundColor={false}/>}
                {fetched && (announcements.length ? (
                    <>
                        <div className="cards">
                            <div className="cards-box">
                                {announcements.map((item) => 
                                    <AnnouncementCard 
                                    key={item.brief + item.title} 
                                    cardInfo={item} 
                                    showCover={true} />
                                )}
                            </div>
                        </div>
                        <hr className="separator-line" />
                    </>
                ) : (
                    <div className="empty">
                        <Icon iconType={ICONS.Armchair} width="80" height="80" />
                        <div className="subtitle">Nothing new these days</div>
                    </div>
                ))}
            </div>
        </div>
    );
};