import React from "react";
import "./MessageCard.css";
import { ICONS, Icon } from "../../Icons/Icon";
import { formatMessageTime, handleTextURLs } from "../../../tools";

export const MessageCard = ({ messageInfo, type, stacked = false, selected = false }) => {
    return (
        <div className={`message-card ${type} ${stacked ? "stacked" : ""}`}>
            <div className={`message-frame ${selected ? "selected" : ""}`}>
                <div className="message">
                    <div className="message-text">{handleTextURLs(messageInfo.content)}</div>
                </div>
                <div className="indicators">
                    {messageInfo.edited &&
                        <div className="indication-text">edited</div>
                    }
                    <div className="indication-text">{formatMessageTime(Date.parse(messageInfo.creation_timestamp))}</div>
                    {type === "right" && (messageInfo.read ? (
                        <Icon iconType={ICONS.CheckDouble} width="16" height="16" />
                    ) : (
                        <Icon iconType={ICONS.Check} width="16" height="16" />
                    ))}
                </div>
            </div>
        </div>
    );
};