import PropTypes from "prop-types";
import React, { useContext } from "react";
import { SigmundContext } from "../../../contexts/SigmundContext";
import { ArtistSelectionCard } from "../../Cards/ArtistSelectionCard/ArtistSelectionCard";
import { LoaderBox } from "../../../blocks/LoaderBox/LoaderBox";
import "./ArtistSelectionOverlay.css";

export const ArtistSelectionOverlay = ({ onSelect }) => {
    const { profileInfo, currentProfile, SwitchCurrentProfile } = useContext(SigmundContext);

    const handleClick = (artist) => {
        SwitchCurrentProfile(artist.artist_id);
        onSelect();
    };

    return (
        <div className="artist-selection">
            <div className="card-box">
                {profileInfo.claimed_artists ? (
                    profileInfo.claimed_artists.map((artist) =>
                        <ArtistSelectionCard
                            key={artist.artist_id}
                            active={currentProfile.artist_id === artist.artist_id}
                            artistName={(profileInfo.user_role === "artist" || profileInfo.user_role === "manager") ? artist.artist_name : profileInfo.username}
                            artistImage={artist.artist_image}
                            onClick={() => handleClick(artist)} />)
                ) : (
                    <LoaderBox backgroundColor={false} />
                )}
            </div>
        </div>
    );
};


ArtistSelectionOverlay.defaultProps = {
    onSelect: () => { },
};

ArtistSelectionOverlay.propTypes = {
    onSelect: PropTypes.func,
};