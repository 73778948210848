import { Icon } from "../../Icons/Icon";

export const SideMenuPlugButton = ({ isActive, variant, onClick, style, slim = false }) => {
    return (
        <div
            onClick={onClick}
            style={style}
            className={`side-menu-button ${isActive ? "active" : "default"}`}>
            <Icon iconType={isActive ? variant.activeIcon : variant.defaultIcon} width="32" height="32" />
            {!slim && 
                <div className="text-wrapper">{variant.name}</div>
            }
        </div>
    );
};