import { useContext, useState } from "react";
import { ICONS, Icon } from "../../Icons/Icon";
import { CircleArrowButton } from "../../Buttons/CircleArrowButton/CircleArrowButton";
import { MinusButton } from "../../Buttons/MinusButton/MinusButton";
import { PlusButton } from "../../Buttons/PlusButton/PlusButton";
import { AnimatePresence, motion } from "framer-motion";
import { useManageTrackQuery } from "../../../hooks/QueryHooks/useManageTrack";
import { LoaderBox } from "../../../blocks/LoaderBox/LoaderBox";
import { useManageTracks } from "../../../hooks/useManageTracks";
import { SigmundContext } from "../../../contexts/SigmundContext";
import "./ArtistPromotionTrackCard.css";

export const ArtistPromotionTrackCard = ({ trackInfo, artistId, prohibit_submit, disableHover = false }) => {
    const [hover, setHover] = useState(false);
    const [submitted, setSubmitted] = useState(trackInfo.submitted);
    const { manageTrackQuery, isFetching } = useManageTrackQuery();
    const { revokeTrack, updateSubmitStatus } = useManageTracks();
    const { CurrentProfileManageTrackSubmition } = useContext(SigmundContext);

    const revokeScheduledTrack = () => {
        manageTrackQuery(artistId, trackInfo.track_id, "revoke", () => {
            revokeTrack(artistId, trackInfo);
        });
    };

    const revokePromotingTrack = () => {
        manageTrackQuery(artistId, trackInfo.track_id, "revoke", () => {
            CurrentProfileManageTrackSubmition("revokePromoted", trackInfo.duration);
            setSubmitted(false);
            updateSubmitStatus(artistId, trackInfo, "in_promotion", false);
        });
    };

    const reSubmitPromotingTrack = () => {
        manageTrackQuery(artistId, trackInfo.track_id, "submit", () => {
            CurrentProfileManageTrackSubmition("reSubmit", trackInfo.duration);
            setSubmitted(true);
            updateSubmitStatus(artistId, trackInfo, "in_promotion", true);
        });
    };

    return (
        <motion.div
            style={{ overflow: "hidden" }}
            key={trackInfo.track_id}    
            // initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.65 }}
        >
            <div
                className="artist-promotion-track-card"
                onMouseEnter={() => disableHover ? () => {} : setHover(true)}
                onMouseLeave={() => disableHover ? () => {} : setHover(false)}
                >
                <AnimatePresence>
                    {(hover || disableHover) &&
                        <motion.div
                            style={{ overflow: "hidden" }}
                            initial={{ width: 0 }}
                            animate={{ width: "auto" }}
                            exit={{ width: 0 }}
                            key="left-circle-button"
                        >
                            {isFetching ? (
                                <LoaderBox backgroundColor={false} box={false} height="32" width="32" type="spinner" />
                            ) : (
                                <>
                                    {(submitted && trackInfo.scheduled) && <CircleArrowButton active={false} type="left" onClick={revokeScheduledTrack} />}
                                    {(submitted && trackInfo.promoted) && <MinusButton onClick={revokePromotingTrack} />}
                                    {(!submitted && trackInfo.promoted && !prohibit_submit) && <PlusButton onClick={reSubmitPromotingTrack} type="circle" />}
                                    {(!submitted && trackInfo.promoted && prohibit_submit) && <Icon iconType={ICONS.Prohibit} width="32" height="32" color="hsl(0, 0%, 60%)" />}
                                </>
                            )}
                        </motion.div>
                    }
                </AnimatePresence>
                <div className="left-card-section">
                    <img className="track-image" alt="Track" src={trackInfo.image} />
                    <div className="main-info">
                        <div className="track-name">{trackInfo.track_name}</div>
                        <div className="track-album">{trackInfo.album_name}</div>
                    </div>
                </div>
                <div className="right-card-section">
                    {trackInfo.promoted &&
                        <>
                            {/* <AnimatePresence>
                                {hover && <motion.a
                                    style={{ overflow: "hidden" }}
                                    initial={{ width: 0 }}
                                    animate={{ width: "auto" }}
                                    exit={{ width: 0 }}
                                    key={trackInfo.track_id + "on-air-label"}
                                    className="playlist-link"
                                    href={trackInfo.promoted.playlist_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {trackInfo.promoted.playlist_name}
                                </motion.a>}
                            </AnimatePresence> */}
                            {submitted ? (
                                <Icon iconType={ICONS.AirplaneTakeoff} width="32" height="32" />
                            ) : (
                                <Icon iconType={ICONS.AirplaneLanding} width="32" height="32" color="hsl(0, 0%, 60%)" />
                            )}
                        </>
                    }
                    {!trackInfo.promoted && (
                        hover ? (
                            <Icon iconType={ICONS.CalendarCheckFilled} width="32" height="32" />
                        ) : (
                            <Icon iconType={ICONS.CalendarCheck} width="32" height="32" />
                        )
                    )
                    }
                </div>
            </div>
        </motion.div>
    );
};