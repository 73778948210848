import React, { useContext } from "react";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { OverlayContext } from "../../contexts/OverlayContext";
import { ProgressBar } from "../../components/ProgressBar/ProgressBar";
import { useCuratorRequestStatusQuery } from "../../hooks/QueryHooks/useCuratorRequestStatus";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { PickGenresBox } from "../PickGenresBox/PickGenresBox";
import { useNavigate } from "react-router-dom";
import { absolutePaths } from "../../routes";
import { usePickGenresQuery } from "../../hooks/QueryHooks/usePickGenresQuery";
import { SigmundContext } from "../../contexts/SigmundContext";
import "./CuratorRequestBox.css";
import { useSendCuratorRequest } from "../../hooks/QueryHooks/useSendCuratorRequest";

export const CuratorRequestBox = () => {
    const { DisplayModalCallback } = useContext(OverlayContext);
    const { profileInfo, SigmundStatusCallback, currentProfile } = useContext(SigmundContext);
    const { requestInfo, setRequestInfo, requestStatusQuery } = useCuratorRequestStatusQuery();
    const curatorRequestHook = useSendCuratorRequest();
    const pickGenresQuery = usePickGenresQuery();
    const navigate = useNavigate();
    
    const handleGenrePick = (genres) => {
        pickGenresQuery(genres, () => requestStatusQuery());
        setRequestInfo(null);
    };

    return (
        <>
            {requestInfo ? (
                requestInfo.status === "initiation" ? (
                    <PickGenresBox onPick={handleGenrePick} />
                ) : (
                    <div className="curator-request-box">
                        <div className="upper-section">
                            <div className="title">Available {requestInfo.requests_available} requests</div>
                            <div className="request-bar">
                                <p className="sub-title">You&#39;ve sent {requestInfo.requests_sent} requests to curators</p>
                                <ProgressBar progress={requestInfo.requests_sent / requestInfo.requests_month_limit} />
                            </div>
                        </div>
                        <PrimaryButton
                            disabled={requestInfo.status === "pending"}
                            disableStyle="info"
                            onClick={
                                requestInfo.status === "available" ?
                                    () => DisplayModalCallback("curatorRequest", {
                                        ...requestInfo, 
                                        currentProfile,
                                        profileInfo,
                                        curatorRequestHook,
                                        onSend: () => {
                                            requestStatusQuery();
                                            SigmundStatusCallback();
                                        }
                                    }) :
                                    requestInfo.status === "exhausted" ? () => navigate(absolutePaths.messenger, { replace: true }) :
                                        () => { }
                            }
                        >
                            {requestInfo.status === "available" ? "Send Request" : null}
                            {requestInfo.status === "exhausted" ? "View Messages" : null}
                            {requestInfo.status === "pending" ? "Sigmund searches for curators" : null}

                        </PrimaryButton>
                    </div>
                )
            ) : (
                <div className="curator-request-box">
                    <LoaderBox backgroundColor={false} />
                </div>
            )}
        </>
    );
};