import React, { useContext, useEffect, useRef, useState } from "react";
import "./EmailConfirmationBox.css";
import { SfscoopConfirmEmailMutation, SfscoopRequestEmailConfirmationMutation } from "../../queries/sigmundApi";
import { useNavigate } from "react-router-dom";
import { absolutePaths } from "../../routes";
import { AnimatePresence, motion } from "framer-motion";
import { useCountdownTimer } from "../../hooks/QueryHooks/useCountDown";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { ICONS, Icon } from "../../components/Icons/Icon";
import { SigmundContext } from "../../contexts/SigmundContext";

export const EmailConfirmationBox = ({ onSignOut = null }) => {
    const { SigmundStatusCallback } = useContext(SigmundContext);
    const [confirmationCode, setConfirmationCode] = useState([]);
    const [validationAlert, setValidationAlert] = useState(null);
    const [initialRequest, setInitialRequest] = useState(true);
    const inputRefs = useRef([]);
    const navigate = useNavigate();
    const confirmEmailMutation = SfscoopConfirmEmailMutation();
    const requestConfirmationMutation = SfscoopRequestEmailConfirmationMutation();

    const { timeLeft, isActive, startTimer, resetTimer } = useCountdownTimer();

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    const formattedTime = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

    useEffect(() => {
        requestConfirmationMutation.mutate(false, {
            onSuccess: async (data) => {
                const response = await data.json();
                if (response.reset_after) {
                    resetTimer();
                    startTimer(response.reset_after);
                }
                setInitialRequest(false);
            },
            onError: () => {
                setInitialRequest(false);
            }
        });
        inputRefs.current[0].focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        confirmCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationCode]);

    const confirmCode = () => {
        const code = confirmationCode.join('');
        if (code.length === 6) {
            confirmEmailMutation.mutate(code, {
                onSuccess: () => {
                    SigmundStatusCallback();
                    navigate(absolutePaths.dashboard, { replace: true });
                },
                onError: () => {
                    setValidationAlert("Invalid code");
                }
            })
        }
    };

    const resendCode = () => {
        setInitialRequest(false);
        requestConfirmationMutation.mutate(true, {
            onSuccess: async (data) => {
                const response = await data.json();
                if (response.reset_after) {
                    resetTimer();
                    startTimer(response.reset_after);
                }
            }
        });
    };

    const handleChange = (index, e) => {
        const value = e.target.value;
        setValidationAlert(null);
        setConfirmationCode(prevCodes => {
            const newCodes = [...prevCodes];
            newCodes[index] = value;
            return newCodes;
        });

        if (value && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !confirmationCode[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <div className="email-confirmation-box">
            <div className="form">
                <div className="arist-link">
                    <div className="code-input">
                        {[...Array(6)].map((_, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength={1}
                                disabled={confirmEmailMutation.isPending}
                                value={confirmationCode[index] || ""}
                                onChange={(e) => handleChange(index, e)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                ref={el => inputRefs.current[index] = el}
                                className={`text-box-wrapper ${validationAlert ? "invalid" : ""}`}
                            />
                        ))}
                        <PrimaryButton
                            onClick={confirmCode}
                            disabled={(confirmEmailMutation.isPending || requestConfirmationMutation.isPending) && !initialRequest} >
                            {((confirmEmailMutation.isPending || requestConfirmationMutation.isPending) && !initialRequest) ? (
                                <LoaderBox type="spinner" width="24" height="24" backgroundColor={false} box={false} />
                            ) : (
                                <Icon iconType={ICONS.ApproveCircle} width="32" height="32" />
                            )
                            }
                        </PrimaryButton>
                    </div>
                    <div className="info-section">
                        <p className="p">Enter the code from the email we sent you</p>
                        <AnimatePresence>
                            {validationAlert &&
                                <motion.div
                                    style={{ overflow: "hidden" }}
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height: "auto", opacity: 1 }}
                                    transition={{ duration: 0.3 }}
                                    exit={{ height: 0, opacity: 0 }}
                                    className="alert"
                                >
                                    {validationAlert}
                                </motion.div>
                            }
                        </AnimatePresence>
                        <div className="options">
                            {onSignOut &&
                                <div className="text-button" onClick={onSignOut}>
                                    Sign Out
                                </div>
                            }
                            {isActive ? (
                                <div className="note">
                                    {formattedTime} to resend
                                </div>
                            ) : (
                                requestConfirmationMutation.isPending ? (
                                    <LoaderBox type="spinner" width="14" height="14" backgroundColor={false} box={false} />
                                ) : (
                                    <div className="text-button" onClick={resendCode}>
                                        Resend code
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};