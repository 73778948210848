import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { BlankLayout } from './layouts/BlankLayout';
import { SignIn } from './pages/SignIn/SignIn';
import { AuthProvider } from './contexts/AuthContext';
import { OverlayProvider } from './contexts/OverlayContext';
import { ResponsiveProvider } from './contexts/ResponsiveContext';
import { SignUp } from './pages/SingUp/SignUp';
import { absolutePaths, relativePaths } from './routes';
import { SigmundProvider } from './contexts/SigmundContext';
import { DesktopAppLayout } from './layouts/DesktopAppLayout/DesktopAppLayout';
import { ClaimArtist } from './pages/ClaimArtist/ClaimArtist';
import { RequireLogout } from './layouts/RequireLogout';
import { RequireAuth } from './layouts/RequireAuth';
import { RequireStreamingConnect } from './layouts/RequireStreamingConnect';
import { InDevelopment } from './blocks/InDevelopment/InDevelopment';
import { StreamingProvider } from './contexts/StreamingContext';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { TrackManagement } from './pages/TrackManagement/TrackManagement';
import { RequireSigmund } from './layouts/RequireSigmund';
import { Stats } from './pages/Stats/Stats';
import { PasswordResetBox } from './blocks/PasswordResetBox/PasswordResetBox';
import { PasswordRecoveryBox } from './blocks/PasswordRecoveryBox/PasswordRecoveryBox';
import { Fans } from './pages/Fans/Fans';
import { ConfirmEmail } from './pages/ConfirmEmail/ConfirmEmail';
import { AdminConsole } from './pages/AdminConsole/AdminConsole';
import { Messenger } from './pages/Messenger/Messenger';
import { UpdateUsername } from './pages/UpdateUsername/UpdateUsername';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 600 * 1000,
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ResponsiveProvider>
          <OverlayProvider>
            <AuthProvider>
              <SigmundProvider>
                <StreamingProvider>
                  <Routes>
                      <Route path="/" element={<BlankLayout />}>
                        <Route element={<RequireLogout />}>
                          <Route index element={<SignIn />} />
                          <Route path={relativePaths.singUp} element={<SignUp />} />
                          <Route path={absolutePaths.forgotPassword} element={<PasswordRecoveryBox />} />
                          <Route path={absolutePaths.resetPassword} element={<PasswordResetBox />} />
                        </Route>

                        <Route element={<RequireAuth />}>
                          <Route element={<RequireSigmund loaderBackground={false}/>}>
                            <Route path={relativePaths.claimArtist} element={<ClaimArtist />} />
                            <Route path={relativePaths.confirmEmail} element={<ConfirmEmail />} />
                            <Route path={relativePaths.updateUsername} element={<UpdateUsername />} />
                          </Route>
                        </Route>

                        <Route path={relativePaths.terms} element={<InDevelopment backgroundColor={false} />} />
                        <Route path={relativePaths.policy} element={<InDevelopment backgroundColor={false} />} />
                        <Route path={relativePaths.contactUs} element={<InDevelopment backgroundColor={false} />} />
                        <Route path={relativePaths.faq} element={<InDevelopment backgroundColor={false} />} />
                      </Route>

                      <Route element={<RequireAuth />}>
                        <Route path="/app" element={<DesktopAppLayout fullHeightPage={true} />}>
                          <Route element={<RequireSigmund />}>
                            <Route element={<RequireStreamingConnect />}>
                              <Route path={relativePaths.tracks} element={<TrackManagement />} />
                            </Route>
                            <Route path={relativePaths.generalStats} element={<Stats type="general" />} />
                            <Route path={relativePaths.trackStats} element={<Stats type="track" />} />
                            <Route path={relativePaths.messenger + '/:chatToken?'} element={<Messenger />} />
                          </Route>
                        </Route>

                        <Route path="/app" element={<DesktopAppLayout />}>
                          <Route element={<RequireSigmund />}>
                            <Route element={<RequireStreamingConnect />}>
                              <Route path={relativePaths.dashboard} element={<Dashboard />} />
                            </Route>
                            <Route path={relativePaths.adminConsole} element={<AdminConsole />} />
                            <Route path={relativePaths.fans} element={<Fans />} />
                          </Route>

                          <Route path={relativePaths.music} element={<InDevelopment />} />
                          <Route path={relativePaths.friends} element={<InDevelopment />} />
                        </Route>
                      </Route>
                  </Routes>
                </StreamingProvider>
              </SigmundProvider>
            </AuthProvider>
          </OverlayProvider>
        </ResponsiveProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
