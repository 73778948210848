import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Tooltip } from 'react-tooltip';
import "./SigmundBucketCard.css";

export const SigmundBucketCard = ({ promotionInfo }) => {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    const promotionDuration = Math.round(promotionInfo.promotion_window / 60)
    const onAirDuration = Math.round(promotionInfo.on_air_window / 60)
    const scheduledDuration = Math.round(promotionInfo.scheduled_window / 60)
    const revokedDuration = Math.round(promotionInfo.revoked_window / 60)
    const freeDuration = Math.round(promotionInfo.free_window / 60)

    const onAirWidth = (width * (promotionInfo.on_air_window / promotionInfo.promotion_window).toFixed(1));
    const scheduledWidth = (width * (promotionInfo.scheduled_window / promotionInfo.promotion_window).toFixed(1));
    const revokeWidth = (width * (promotionInfo.revoked_window / promotionInfo.promotion_window).toFixed(1));
    const freeWidth = (width * (promotionInfo.free_window / promotionInfo.promotion_window).toFixed(1));

    useEffect(() => {
        if (width === 0) {
            setWidth(ref.current.offsetWidth - 12);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);

    return (
        <div ref={ref} style={{ maxWidth: width ? width + 12 : null }} className="sigmund-bucket-card">
            <motion.div
                key="on-air-bucket"
                animate={{ width: onAirWidth }}
                transition={{ duration: 0.6 }}
                className="bucket on-air"
            >
                <div className="text-wrapper">{(onAirDuration >= 1 && onAirWidth >= 44) ? `${onAirDuration}m${onAirWidth >= 100 ? " On-Air" : ""}` : null}</div>
            </motion.div>

            {/* {promotionInfo.scheduled_window > 0 &&  */}
            <motion.div
                key="schedule-bucket"
                animate={{ width: scheduledWidth }}
                transition={{ duration: 0.6 }}
                className="bucket scheduled"
            >
                <div className="text-wrapper">{(scheduledDuration >= 1 && scheduledWidth >= 44) ? `${scheduledDuration}m${scheduledWidth >= 120 ? " scheduled" : ""}` : null}</div>
            </motion.div>
            {/* } */}

            {/* {promotionInfo.revoked_window > 0 &&  */}
            <motion.div
                key="revoked-bucket"
                animate={{ width: revokeWidth }}
                transition={{ duration: 0.6 }}
                className="bucket revoked"
            >
                <div className="text-wrapper">{(revokedDuration >= 1 && revokeWidth >= 44) ? `${revokedDuration}m${revokeWidth >= 100 ? " revoked" : ""}` : null}</div>
            </motion.div>
            {/* } */}

            <motion.div
                key="free-bucket"
                animate={{ width: freeWidth }}
                transition={{ duration: 0.6 }}
                className="bucket free"
            >
                <div className="text-wrapper light">{(freeDuration >= 1 && freeWidth >= 44) ? `${freeDuration}m${freeWidth >= 100 ? " free" : ""}` : null}</div>
            </motion.div>

            <Tooltip anchorSelect=".bucket.on-air" place="bottom-end" className="tool-tip-style">
                <div className="tip-text-wrapper">On-Air window: <span className="tip-value-on-air">{onAirDuration}</span> minutes / <span className="tip-value-on-air">{((onAirDuration / promotionDuration) * 100).toFixed(1)}%</span></div>
                <br />
                <div className="tip-text-wrapper">Total duration of tracks<br />that are being promoted right now<br />in the On-Air playlist</div>
            </Tooltip>

            <Tooltip anchorSelect=".bucket.scheduled" place="bottom-end" className="tool-tip-style">
                <div className="tip-text-wrapper">Scheduled window: <span className="tip-value-scheduled">{scheduledDuration}</span> minutes / <span className="tip-value-scheduled">{((scheduledDuration / promotionDuration) * 100).toFixed(1)}%</span></div>
                <br />
                <div className="tip-text-wrapper">Total duration of tracks<br />that are in the promotion queue.<br />Soon they will appear in On-Air playlists!</div>
            </Tooltip>

            <Tooltip anchorSelect=".bucket.revoked" place="bottom-end" className="tool-tip-style">
                <div className="tip-text-wrapper">Revoked window: <span className="tip-value-revoked">{revokedDuration}</span> minutes / <span className="tip-value-revoked">{((revokedDuration / promotionDuration) * 100).toFixed(1)}%</span></div>
                <br />
                <div className="tip-text-wrapper">Total duration of tracks<br />that are revoked from promotion,<br />but are still in some On-Air playlist.<br />These tracks will be revoked<br />at the upcoming playlist recompilation</div>
            </Tooltip>

            <Tooltip anchorSelect=".bucket.free" place="bottom-end" className="tool-tip-style">
                <div className="tip-text-wrapper">Free window: <span className="tip-value-free">{freeDuration}</span> minutes / <span className="tip-value-free">{((freeDuration / promotionDuration) * 100).toFixed(1)}%</span></div>
                <br />
                <div className="tip-text-wrapper">The remaining window time<br />you can fill with tracks to promote</div>
            </Tooltip>
        </div>
    );
};