import { useState } from "react";
import PropTypes from "prop-types";
import { PlusButton } from "../../components/Buttons/PlusButton/PlusButton";
import { GenreCard } from "../../components/Cards/GenreCard/GenreCard";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { AnimatePresence } from "framer-motion";
import "./PickGenresBox.css";

const genreMaxLenght = 20;
const genreMaxCount = 3;

export const PickGenresBox = ({ onPick }) => {
    const [genre, setGenre] = useState("");
    const [genreList, setGenreList] = useState([]);
    const [loading, setLoading] = useState(false);

    const handlePickerChange = (e) => {
        setGenre(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            addGenre();
        }
    };

    const addGenre = () => {
        if (genre && !genreList.includes(genre) && genreList.length < genreMaxCount) {
            setGenreList([...genreList, genre]);
            setGenre("");
        }
    };

    const removeGenre = (genre) => {
        setGenreList(genreList.filter(item => item !== genre));
    };

    const handlePick = () => {
        if (genreList.length > 0) {
            setLoading(true);
            onPick(genreList);
        }
    }

    return (
        <div className="pick-genres-box">
            <div className="upper-section">
                <div className="title">Pick your {genreMaxCount} genres</div>
                <div className="selector">
                    <input
                        className="text-box-wrapper"
                        placeholder="Techno"
                        value={genre}
                        disabled={genreList.length === genreMaxCount}
                        maxLength={genreMaxLenght}
                        onChange={handlePickerChange}
                        onKeyDown={handleKeyDown}
                    />
                    <PlusButton onClick={addGenre} disabled={genreList.length === genreMaxCount} />
                </div>
            </div>
            <div className="selected-genres">
                <AnimatePresence mode="popLayout">
                    {genreList.map(genre =>
                        <GenreCard key={genre} genreName={genre} onClose={() => removeGenre(genre)} />)}
                </AnimatePresence>
            </div>
            <div className="padding-container">
                <PrimaryButton onClick={handlePick} disableStyle="fade" disabled={!genreList.length || loading}>Pick genres</PrimaryButton>
            </div>
        </div>
    );
};

PickGenresBox.propTypes = {
    onPick: PropTypes.func,
};

PickGenresBox.defaultProps = {
    onPick: () => { },
};