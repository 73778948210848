import React, { useContext } from "react";
import { SigmundContext } from "../../contexts/SigmundContext";
import "./OnAirPlaylists.css";

export const OnAirPlalists = () => {
    const { profileInfo } = useContext(SigmundContext);

    return (
        <>
            {profileInfo.promotion_playlists.length ?
                <div className="on-air-playlists">
                    <div className="text-wrapper">Today On-Air</div>
                    {profileInfo.promotion_playlists?.map((playlist) => {
                        return (<iframe style={{borderRadius: "12px"}}
                            key={playlist.playlist_name}
                            title={playlist.playlist_name}
                            src={playlist.playlist_url}
                            width="100%"
                            height="160px"
                            frameBorder="0"
                            allowFullScreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            loading="lazy" />);
                    })}
                </div> : null
            }
        </>
    );
};