import React, { useContext, useState } from "react";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { absolutePaths } from "../../routes";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { AnimatePresence, motion } from "framer-motion";
import { RequestPasswordRecoveryMutation } from "../../queries/sigmundApi";
import { Alert } from "../Alert/Alert";
import { ICONS } from "../../components/Icons/Icon";
import "./PasswordRecoveryBox.css";

export const PasswordRecoveryBox = () => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const [status, setStatus] = useState("idle");
    const [invalidInput, setInvalidInput] = useState({});
    const recoveryMutation = RequestPasswordRecoveryMutation();
    const { isExtremeSmallMobile } = useContext(ResponsiveContext);

    const handleEmail = (item) => {
        setInvalidInput({ ...invalidInput, email: false });
        setEmail(item.target.value);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        if (!email) {
            setInvalidInput({ ...invalidInput, email: true });
        } else {
            recoveryMutation.mutate(email, {
                onSuccess: () => {
                    setStatus("success");
                },
                onError: () => {
                    setStatus("failure");
                },
            });
        }
    }

    return (
        <>
            {status === "success" ? (
                <Alert
                    title="Password Reset Initiated"
                    subtitle="We have sent you an email with password reset instructions"
                    buttonText="Sign In"
                    onClick={() => navigate(absolutePaths.singIn)}
                    iconType={ICONS.ApproveCircle}
                    backgroundColor={false}
                />
            ) : (
                <>
                    <div className="forgot-password-frame">
                        <div className="forgot-password-section">
                            <header className="header">
                                <div className="title">Password Reset</div>
                            </header>
                            <form className="password-recovery-box" onSubmit={handleSubmit}>
                                <div className="form">
                                    <div className="div">
                                        <label htmlFor="email" className="input-label">Email</label>
                                        <input
                                            id="email"
                                            type="email"
                                            value={email}
                                            onChange={handleEmail}
                                            className={`text-box-wrapper ${invalidInput.email ? "invalid" : ""}`}
                                            placeholder="Enter your email address" />
                                    </div>
                                </div>
                                <PrimaryButton type="submit" disabled={recoveryMutation.isPending ? true : false}>Reset Password</PrimaryButton>
                                <div className="alternative">
                                    <AnimatePresence>
                                        {status === "failure" &&
                                            <motion.div
                                                style={{ overflow: "hidden" }}
                                                initial={{ height: 0, opacity: 0 }}
                                                animate={{ height: "auto", opacity: 1 }}
                                                transition={{ duration: 0.3 }}
                                                exit={{ height: 0, opacity: 0 }}
                                                className="alert"
                                            >
                                                We couldn't find your profile
                                            </motion.div>
                                        }
                                    </AnimatePresence>
                                    <div className="default">
                                        Remember the password?{isExtremeSmallMobile ? <br /> : " "}<Link to={absolutePaths.singIn} className="link">Sign in here</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};