import { createContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import useMeasure from 'react-use-measure';

export const ResponsiveContext = createContext(null);

export const ResponsiveProvider = ({ children }) => {
    const isExtremeSmallMobile = useMediaQuery({ maxWidth: 380 });
    const isSmallMobile = useMediaQuery({ maxWidth: 460 });
    const isMobile = useMediaQuery({ minWidth: 461, maxWidth: 616 });
    const isSmallTablet = useMediaQuery({ minWidth: 617, maxWidth: 692 });
    const isTablet = useMediaQuery({ minWidth: 693, maxWidth: 1049 });
    const isDesktop = useMediaQuery({ minWidth: 1050 });
    const [standardBlockRef, standardBlockBounds] = useMeasure();
    const [notificationWidth, setNotificationWidth] = useState(null);

    useEffect(() => {
        if (standardBlockBounds.width) {
            setNotificationWidth(standardBlockBounds.width);
            localStorage.setItem("standardBlockWidth", standardBlockBounds.width);
        } else {
            const width = localStorage.getItem("standardBlockWidth");
            if (width) {
                setNotificationWidth(Number(width));
            }
        }
    }, [standardBlockBounds.width]);

    return (
        <ResponsiveContext.Provider value={{
            isExtremeSmallMobile,
            isSmallMobile,
            isMobile,
            isSmallTablet,
            isTablet,
            isDesktop,
            standardBlockRef,
            standardBlockBounds,
            notificationWidth,
        }}>
            {children}
        </ResponsiveContext.Provider>
    );
};
