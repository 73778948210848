import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { absolutePaths } from "../../routes";
import { SigmundContext } from "../../contexts/SigmundContext";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { SigmundRateCardBucket } from "../../components/Cards/SigmundRateCardBucket/SigmundRateCardBucket";
import "./SigmundRateBox.css";

export const SigmundRateBox = ({ manageButton }) => {
    const { currentProfile, profileInfo } = useContext(SigmundContext);
    const promotionInfo = currentProfile.artist_id ? profileInfo.artist_promotions[currentProfile.artist_id] : {};
    const navigate = useNavigate();

    return (
        <div className="sigmund-rate-box">
            <div className="section">
                {promotionInfo ? (
                    <SigmundRateCardBucket
                        rate={Math.round(currentProfile.artist_sigmund_rate * 100)} 
                        promotionInfo={promotionInfo} />
                ) : (
                    <LoaderBox backgroundColor={false} />
                )}
            </div>
            {manageButton && <PrimaryButton onClick={() => navigate(absolutePaths.tracks, { replace: true })}>Manage tracks</PrimaryButton>}
        </div>
    );
};

SigmundRateBox.defaultProps = {
    manageButton: true,
};

SigmundRateBox.propTypes = {
    manageButton: PropTypes.bool,
};
