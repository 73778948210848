import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { pluralize } from '../../../tools';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
);


export const LineChart = ({ data, period }) => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            filler: {
                propagate: false,
            },
            legend: false,
            tooltip: {
                backgroundColor: "#101223",
                borderColor: "#080911",
                borderWidth: 2,
                cornerRadius: 8,
                titleFont: {
                    family: 'Helvetica',
                  },
                  bodyFont: {
                    family: 'Helvetica',
                  },
                intersect: false,
                mode: "index",
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        let value = context.parsed.y;
                        if (label === "hour" && value < 1) {
                            label = "minute";
                            value = Math.round(value * 60)
                        }
                        return `${value.toLocaleString()} ${label ? pluralize(value, label) : ""}`;
                    },
                    title: function(context) {
                        const date = new Date(context[0].label * 1000);
                        const timeValue = date.toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true
                        });
                        const month = date.toLocaleString('en', { month: 'short' });
                        const day = date.getDate();
                        if (period === "week" || period === "day") {
                            return `${month} ${day} ${timeValue}`;
                        } else {
                            return `${month} ${day}`;
                        }
                    },
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: "#A3A3A3",
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkipPadding: 24,
                    callback: function(value, index, values) {
                        const date = new Date(this.getLabelForValue(value) * 1000);
                        if (period === "day") {
                            return date.toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true
                            });
                        } else {
                            const month = date.toLocaleString('en', { month: 'short' });
                            const day = date.getDate();
                            return `${month} ${day}`;
                        }
                      }
                },
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: "#A3A3A3",
                },
                grid: {
                    color: "#38397B",
                },
                border: {
                    display: false,
                },
            },
        },
    };
    return <Line options={options} data={data} />;
}
