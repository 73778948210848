import { createContext, useState } from 'react';
import { AuthTokenMutation } from '../queries/sigmundApi';
import Cookies from "js-cookie";
import { useQueryClient } from '@tanstack/react-query';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [status, setStatus] = useState("unauthorized");
    const [isAuth, setIsAuth] = useState(false);
    const [authSign, setAuthSign] = useState(true);
    const [authPayload, setAuthPayload] = useState(null);
    const queryClient = useQueryClient();
    const authQuery = AuthTokenMutation();

    const CheckAuthCallback = () => {
        if (!authPayload) {
            const payload = Cookies.get("auth_payload");
            if (payload && authSign) {
                setAuthPayload(payload);
                setStatus("authorized");
                setIsAuth(true);
                return isAuth;
            }
            setAuthPayload(null);
            setStatus("unauthorized");
            setIsAuth(false);
            return isAuth;
        }

        if (!authSign) {
            setAuthPayload(null);
            setStatus("unauthorized");
            setIsAuth(false);
            return isAuth;
        }

        setStatus("authorized");
        setIsAuth(true);
        return isAuth;
    };

    const SignOut = () => {
        queryClient.clear();
        setAuthPayload(null);
        setAuthSign(false);
        setStatus("unauthorized");
        setIsAuth(false);
    };

    const SignInCallback = (username, password) => {
        if (status !== "fetching" && status !== "authorized") {
            authQuery.mutate({ username, password }, {
                onSuccess: async () => {
                    const payload = Cookies.get("auth_payload");
                    setAuthPayload(payload);
                    setAuthSign(true);
                    setStatus("authorized");
                    setIsAuth(true);
                },
                onError: (error) => {
                    if (error.status === 401) {
                        setStatus("failure");
                    } else {
                        setStatus("error");
                    }
                },
            });
            setStatus("fetching");
        }
    };

    return (
        <AuthContext.Provider value={{
            status,
            isAuth,
            SignInCallback,
            CheckAuthCallback,
            SignOut,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

