const host = process.env.REACT_APP_API_HOST;
const wsHost = process.env.REACT_APP_WS_API_HOST;

export const endpoints = {
    token: `${host}/public/auth/token`,
    signUp: `${host}/public/auth/sign-up`,
    signOut: `${host}/public/auth/sign-out`,
    requestPasswordRecovery: `${host}/public/auth/password_recovery/request_recovery`,
    resetPassword: `${host}/public/auth/password_recovery/reset`,
    emailValidation: `${host}/public/client/validation/validate_email`,
    usernameValidation: `${host}/public/client/validation/validate_username`,
    inviteValidation: `${host}/public/client/validation/validate_invitation`,
    sigmundMe: `${host}/sigmund/me`,
    claimStatus: `${host}/sigmund/artists/claim_status`,
    claimArtist: `${host}/sigmund/artists/claim`,
    streamingConnectionStatus: `${host}/core/spotify/connection_status`,
    spotifyConnect: `${host}/core/spotify/connect`,
    spotifyInitialConfirmEmail: `${host}/core/spotify/confirmation/email`,
    spotifyConfirmEmail: `${host}/core/spotify/confirmation/confirm_email`,
    sfscoopConfirmEmail: `${host}/core/user/confirmation/confirm_email`,
    sfscoopRequestEmailConfirmation: `${host}/core/user/confirmation/request_email_confirmation`,
    briefInsights: `${host}/insights/brief`,
    generalInsights: `${host}/insights/general`,
    trackInsights: `${host}/insights/tracks`,
    fansInsights: `${host}/insights/fans`,
    topFansInsights: `${host}/insights/top_fans`,
    broadcastingStatus: `${host}/insights/broadcasting_status`,
    curatorRequests: `${host}/sigmund/curators/requests`,
    curatorSendRequests: `${host}/sigmund/curators/send_requests`,
    genrePick: `${host}/sigmund/genres/pick`,
    announcements: `${host}/sigmund/announcements`,
    notifications: `${host}/sigmund/notifications/get_notifications`,
    viewNotifications: `${host}/sigmund/notifications/notifications_viewed`,
    getTracks: `${host}/sigmund/tracks/get_tracks`,
    searchTracks: `${host}/sigmund/tracks/search_tracks`,
    updateCatalog: `${host}/sigmund/tracks/update_catalog`,
    submitTrack: `${host}/sigmund/tracks/submit_track`,
    revokeTrack: `${host}/sigmund/tracks/revoke_track`,
    referralUrl: `${host}/sigmund/invitations/get_invitation_url`,
    approveUser: `${host}/management/users/approve_user`,
    getPendingUsers: `${host}/management/users/get_pending_users`,
    getChats: `${host}/messenger/chats/get_chats`,
    createPrivateChat: `${host}/messenger/chats/create_private_chat`,
    getContacts: `${host}/messenger/contacts/get_contacts`,
    accountUpdateUsername: `${host}/core/user/account/update_username`,
    wsMessengerActions: `${wsHost}/messenger/ws/actions/gateway`,
    wsMessengerMessages: `${wsHost}/messenger/ws/messages`,
};