import { SendCuratorRequestMutation } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";

export const useSendCuratorRequest = () => {
    const queryErrorHandler = useQueryErrorHandler();
    const queryMutation = SendCuratorRequestMutation();

    const SendCuratorRequestQuery = (
        request_count, 
        request_template, 
        save_template,
        artist_id,
        onComplete = () => { }, 
        onErrorEvent = () => { },
    ) => {
        queryMutation.mutate({ request_count, request_template, save_template, artist_id }, {
            onSuccess: () => {
                onComplete();
            },
            onError: (error) => {
                onErrorEvent();
                queryErrorHandler(error);
            }
        })
    };

    return { SendCuratorRequestQuery, queryMutation };
};