import React from "react";
import "./InfoCard.css";

export const InfoCard = ({ title, subtitle }) => {
    return (
        <div className="info-card">
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
        </div>
    );
};