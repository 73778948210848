import { useContext } from "react";
import { GeneralStatsBox } from "../../blocks/GeneralStatsBox/GeneralStatsBox";
import { SigmundContext } from "../../contexts/SigmundContext";
import { BroadcastingStatsBox } from "../../blocks/GeneralStatsBox/BroadcastingStatsBox";
import { TrackStatsBox } from "../../blocks/TrackStatsBox/TrackStatsBox";
import "./Stats.css";

export const Stats = ({ type }) => {
    const { profileInfo } = useContext(SigmundContext);

    return (
        <>
            <div className="stats-section">
                {type === "general" &&
                    (profileInfo.user_role === "artist" || profileInfo.user_role === "manager") ? (
                    <GeneralStatsBox />
                ) : profileInfo.user_role === "fan" ? (
                    <BroadcastingStatsBox />
                ) : null
                }
                {(type === "track" && (profileInfo.user_role === "artist" || profileInfo.user_role === "manager")) && 
                    <TrackStatsBox />
                }
            </div>
        </>
    );
};