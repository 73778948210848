import "./UpdateUsername.css";
import React from "react";
import { UpdateUsernameBox } from "../../blocks/UpdateUsernameBox/UpdateUsernameBox";

export const UpdateUsername = () => {
    return (
        <div className="update-username-frame">
            <div className="update-username-section">
                <div className="header">
                    <div className="title">New username</div>
                </div>
                <UpdateUsernameBox />
            </div>
        </div>
    );
};