import { useContext, useEffect, useState } from "react";
import { ReferralUrlQuery } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";
import { SigmundContext } from "../../contexts/SigmundContext";

export const useReferralUrlQuery = () => {
    const [referralUrl, setReferralUrl] = useState(null);
    const { currentProfile } = useContext(SigmundContext);
    const queryErrorHandler = useQueryErrorHandler();
    const query = ReferralUrlQuery(currentProfile.artist_id);

    useEffect(() => {   
        if (query.isFetching) {
            setReferralUrl(null);
        } else if (query.isSuccess) {
            setReferralUrl(query.data);
        } else if (query.isError) {
            queryErrorHandler(query.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isSuccess, query.isError, currentProfile.artist_id]);

    return { referralUrl };
};