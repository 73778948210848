import { useEffect, useState } from "react";
import { BroadcastingStatusQuery } from "../../queries/sigmundApi";
import { useQueryErrorHandler } from "../useQueryErrorHandler";

export const useBroadcastingStatusQuery = () => {
    const queryErrorHandler = useQueryErrorHandler();
    const [broadcastingStatus, setBroadcastingStatus] = useState({});
    const query = BroadcastingStatusQuery();

    useEffect(() => {
        if (query.isSuccess) {
            setBroadcastingStatus(query.data);
        } else if (query.isError) {
            queryErrorHandler(query.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isSuccess, query.isError]);

    return broadcastingStatus;
};