import PropTypes from "prop-types";
import React from "react";
import "./SignOutButton.css";
import { Icon, ICONS } from "../../Icons/Icon";

export const SignOutButton = ({ onClick }) => {
    return (
        <button onClick={onClick} className="sign-out-button">
            <Icon iconType={ICONS.SignOut} width="32" height="32"/>
        </button>
    );
};

SignOutButton.propTypes = {
    onClick: PropTypes.func
};

SignOutButton.defaultProps = {
    onClick: () => {}
}

