import React from "react";
import { InfoCard } from "../../components/Cards/InfoCard/InfoCard";
import { InfoDoubleCard } from "../../components/Cards/InfoDoubleCard/InfoDoubleCard";
import { useFansInsightsQuery } from "../../hooks/QueryHooks/useFansInsights";
import { LoaderBox } from "../LoaderBox/LoaderBox";
import { formatHours, pluralize } from "../../tools";
import useMeasure from "react-use-measure";
import "./FansInfoBox.css";

export const FansInfoBox = () => {
    const { insights } = useFansInsightsQuery();
    const [ref, bounds] = useMeasure();

    return (
        <div ref={ref} className="fans-info-box">
            {insights ? (
                <>
                    {bounds.width >= 794 &&
                        <div className="total-sections">
                            <InfoCard title="Total" subtitle={`${insights.fans} ${pluralize(insights.fans, "fan")}`} />
                            <InfoCard title="Referral" subtitle={`${insights.referral_fans} ${pluralize(insights.referral_fans, "fan")}`} />
                            <InfoCard title="On-Air" subtitle={`${insights.on_air_fans} ${pluralize(insights.on_air_fans, "fan")}`} />
                            <InfoCard title="Idle" subtitle={`${insights.idle_fans} ${pluralize(insights.idle_fans, "fan")}`} />
                        </div>
                    }

                    {bounds.width < 590 &&
                        <>
                            <div className="total-sections left-compact">
                                <InfoCard title="Total" subtitle={`${insights.fans} ${pluralize(insights.fans, "fan")}`} />
                                <InfoCard title="Referral" subtitle={`${insights.referral_fans} ${pluralize(insights.referral_fans, "fan")}`} />
                            </div>
                            <div className="total-sections left-compact">
                                <InfoCard title="On-Air" subtitle={`${insights.on_air_fans} ${pluralize(insights.on_air_fans, "fan")}`} />
                                <InfoCard title="Idle" subtitle={`${insights.idle_fans} ${pluralize(insights.idle_fans, "fan")}`} />
                            </div>
                        </>
                    }

                    {(bounds.width >= 590 && bounds.width < 794) &&
                        <>
                            <div className="stats">
                                <div className="total-sections-wrapper">
                                    <div className="total-sections compact">
                                        <InfoCard title="Total" subtitle={`${insights.fans} ${pluralize(insights.fans, "fan")}`} />
                                        <InfoCard title="Referral" subtitle={`${insights.referral_fans} ${pluralize(insights.referral_fans, "fan")}`} />
                                    </div>
                                    <div className="total-sections compact">
                                        <InfoCard title="On-Air" subtitle={`${insights.on_air_fans} ${pluralize(insights.on_air_fans, "fan")}`} />
                                        <InfoCard title="Idle" subtitle={`${insights.idle_fans} ${pluralize(insights.idle_fans, "fan")}`} />
                                    </div>
                                </div>
                                <div className="double-card-section">
                                    <div className="double-card-title">Referral tokens</div>
                                    <InfoDoubleCard
                                        topInfo={{
                                            title: "Total",
                                            subtitle: `${insights.total_earned_tokens} ${pluralize(insights.total_earned_tokens, "token")}`,
                                        }}
                                        bottomInfo={{
                                            title: "Referral rate",
                                            subtitle: `${Math.round(insights.referral_rate * 100)}%`,
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    }

                    <div className={`stats ${bounds.width < 590 ? "compact" : ""}`}>
                        <div className={`double-card-section ${bounds.width < 590 ? "full-width" : ""}`}>
                            <div className="double-card-title">Lifetime broadcasting</div>
                            <InfoDoubleCard
                                topInfo={{
                                    title: "Total",
                                    subtitle: formatHours(insights.total_lifetime_hours),
                                }}
                                bottomInfo={{
                                    title: "For you personally",
                                    subtitle: formatHours(insights.referral_lifetime_hours),
                                }}
                            />
                        </div>
                        <div className={`double-card-section ${bounds.width < 590 ? "full-width" : ""}`}>
                            <div className="double-card-title">Last week broadcasting</div>
                            <InfoDoubleCard
                                topInfo={{
                                    title: "Total",
                                    subtitle: formatHours(insights.total_last_week_hours),
                                }}
                                bottomInfo={{
                                    title: "For you personally",
                                    subtitle: formatHours(insights.referral_last_week_hours),
                                }}
                            />
                        </div>
                        {(bounds.width < 590 || bounds.width >= 794) &&
                            <div className={`double-card-section ${bounds.width < 590 ? "full-width" : ""}`}>
                                <div className="double-card-title">Referral tokens</div>
                                <InfoDoubleCard
                                    topInfo={{
                                        title: "Total",
                                        subtitle: `${insights.total_earned_tokens} ${pluralize(insights.total_earned_tokens, "token")}`,
                                    }}
                                    bottomInfo={{
                                        title: "Referral rate",
                                        subtitle: `${Math.round(insights.referral_rate * 100)}%`,
                                    }}
                                />
                            </div>
                        }
                    </div>
                </>
            ) : (
                <LoaderBox backgroundColor={false} box={false} />
            )}
        </div>
    );
};